import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChatRoutingModule } from './chat-routing.module';
import { SinglechatComponent } from './componentes/singlechat/singlechat.component';
import { MultichatComponent } from './componentes/multichat/multichat.component';
import { ChatsComponent } from './chats/chats.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import {ClipboardModule} from '@angular/cdk/clipboard';
import { PopPedidoComponent } from './componentes/pop-pedido/pop-pedido.component';
import { SendpedidowppComponent } from './componentes/sendpedidowpp/sendpedidowpp.component';
import { ComponentesModule } from '../common/componentes/componentes.module';
import { PipesModule } from '../pipes/pipes.module';
import { CrudfastModule } from '../widgets/crudfast/crudfast.module';
import { CKEditorModule } from 'ckeditor4-angular';


@NgModule({
  declarations: [
    SinglechatComponent, 
    MultichatComponent, 
    ChatsComponent, 
    PopPedidoComponent, 
    SendpedidowppComponent],
  imports: [
    CommonModule,
    ChatRoutingModule,
    IonicModule,
    FormsModule,
    ClipboardModule,
    ComponentesModule,
    PipesModule,
    CrudfastModule,
    CKEditorModule
  ]
})
export class ChatModule { }
