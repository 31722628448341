import { Injectable } from '@angular/core';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';
import { AlertInput } from '@ionic/core';

@Injectable({
  providedIn: 'root'
})
export class InteraccionService {

  loading: HTMLIonLoadingElement;
  present = false;

  constructor(public toastCtrl: ToastController,
              public loadingController: LoadingController,
              public alertController: AlertController) { }

  async showToast(message: string, duration: number = 2000) {
    const toast = await this.toastCtrl.create({
      message,
      duration,
      position: 'middle',
      cssClass: 'aviso',
    });
    toast.present();
  }

  async presentLoading(message?: string) {
    if (message === undefined) {
      message = 'Procesando';
    }
    if (this.present) { return; }
    this.present = true;
    this.loading = await this.loadingController.create({
      cssClass: 'aviso',
      message: message,
      backdropDismiss: true
    });
    await this.loading.present();
    this.loading.onWillDismiss().then(() => {
      this.present = false;
    })
  }

  async dismissLoading() {
      if (this.present) {
          this.loading.dismiss();
          this.present = false;
      }
  }

  preguntaAlert(header: string, message: string, question: boolean = true): Promise<boolean> {

    return new Promise(  async  (resolve) => {
        let buttons = [];
        if (question) {
          buttons = [{
            text: 'Cancelar',
            role: 'cancel',
            cssClass: 'aviso',
            handler: _ => {
                  resolve(false);
            }
          }, {
            text: 'SI',
            cssClass: 'aviso',
            handler: async () => {
              resolve(true);
            }
          }

          ]
        } else {
          buttons = [{
                text: 'OK',
                cssClass: 'aviso',
                handler: async () => {
                  resolve(true);
                }
            }
          ]
        }
        const alert = await this.alertController.create({
          header,
          message,
          cssClass: 'aviso',
          buttons,
        });
        await alert.present();
    });
  }

  async presentToastWithOptions(header: string, message: string, 
                                position: "bottom" | "top" | "middle",
                                buttons: any[],
                                duration: number,
                                color: string = 'dark') {
    const toast = await this.toastCtrl.create({
      header,
      message,
      position,
      buttons,
      duration,
      color,
      cssClass: 'aviso',
    });
    toast.present();
  }

  async presentAlertWithOptions(header: string, message: string, 
    inputs: AlertInput[],): Promise<any> {

    return new Promise(  async  (resolve) => {
        const alert = await this.alertController.create({
          cssClass: 'aviso',
          header,
          message,
          inputs,
          buttons: [
            {
              text: 'Cancelar',
              role: 'cancel',
              cssClass: 'aviso',
              handler: () => {
                  resolve(false);
                  return;
              }
            }, {
              text: 'Aceptar',
              cssClass: 'aviso',
              handler: (res) => {
                resolve(res);
                return;
              }
            }
          ]
        });
        await alert.present();
    });
  }

}


