import { environment } from '../../../environments/environment';
import { Campo, ImageItem, PdfEstructura } from '../../widgets/crudfast/models-crud';
import { InputPhone } from '../../widgets/crudfast/componentes/inputphone/inputphone.component';

export const InfoLocalEstructura: Campo[] = [
    { name: 'nombre', type: 'string', label: 'Nombre', value: '', editable: true },
    { name: 'descripcion', type: 'textarea',  label: 'Descripción', value: '', editable: true },
    { name: 'email', type: 'string', label: 'Email', value: '', editable: true },
    { name: 'celular', type: 'phone', label: 'Celular', value: {}, editable: true },
    { name: 'celulares', type: 'array', label: 'Celulares adicionales', value: [], editable: true,
      subCampos: [
         { name: 'celular', type: 'phone', label: 'Celular', value: {}, editable: true },
    ]},
    { name: 'telefonos', type: 'array', label: 'Teléfonos', value: [], editable: true,
      subCampos: [
        { name: 'telefono', type: 'string', label: 'Número', value: '', editable: true },
    ]},
    { name: 'uid', type: 'string', label: 'Id', value: environment.uidAdmin, editable: false },
    { name: 'id', type: 'string', label: 'Id', value: environment.uidAdmin, editable: false },
    { name: 'domain', type: 'string', label: 'Nombre de dominio', value: 'https://', editable: true },
    { name: 'direccion', type: 'string', label: 'Dirección', value: '', editable: true },
    { name: 'photoURL', type: 'img', label: 'Foto de perfil', value: '', editable: true },
    { name: 'logo', type: 'img', label: 'Logo', value: '', editable: !environment.production }, 
    { name: 'logoDark', type: 'img', label: 'Logo (dark mode)', value: '', editable: !environment.production }, 
    { name: 'logofooter', type: 'img', label: 'Logo Footer', value: '', editable: true }, 
    { name: 'opcionesAdicionales', type: 'array', label: 'Enlaces / Pdfs adicionales', value: [], editable: true,
      subCampos: [
        { name: 'pdf', type: 'Objeto', label: 'Pdf', value: null, subCampos: PdfEstructura, editable: true },
        { name: 'enlace', type: 'Objeto', label: 'Enlace o Texto', value: {}, editable: true, 
           subCampos: [
            { name: 'url', type: 'string', label: 'Url', value: '', editable: true },
            { name: 'nombre', type: 'html', label: 'Texto', value: '', editable: true },
           ] 
        },
      ]},
    { name: 'facebook', type: 'string', label: 'Facebook', value: '', editable: true },
    { name: 'instagram', type: 'string', label: 'Instagram', value: '', editable: true },
    { name: 'linkedin', type: 'string', label: 'Linkedin', value: '', editable: true },
    { name: 'twitter', type: 'string', label: 'Twitter', value: '', editable: true },
    { name: 'tiktok', type: 'string', label: 'Tiktok', value: '', editable: true },
    { name: 'youtube', type: 'string', label: 'Youtube', value: '', editable: true },
    // { name: 'playstore', type: 'string', label: 'Play Store', value: 'https://playstore.com/', editable: true },
    // { name: 'appstore', type: 'string', label: 'App Store', value: 'https://appstore.com/', editable: true },
  ];

export const  CrudDatos = {
    icon: 'person',
    label: 'Ajustes de datos',
    url: 'info',
    colorIcon: 'white',
    colorBackgroud: 'linear-gradient(90deg, #ffc107 0%, #ffe687 100%)',
    config: {
        title: 'Ajustes de datos',
        labelAll: '',
        setVar: {},
        estructura: InfoLocalEstructura,
        path: 'Usuarios/' + environment.uidAdmin + '/info/',
        multiple: false,
        id: environment.uidAdmin,
    }
}

export interface DatosEmpresaI {
    uid: string;
    id: string;
    nombre: string;
    descripcion: string;
    email: string;
    rnt: string;
    opcionesAdicionales: {
        pdf: {url:string, nombre: string, path: string};
        enlace: {url: string, nombre: string}}[];
    celular: InputPhone;
    celulares: {celular: InputPhone}[];
    telefonos: {telefono: string} [];
    direccion?: string;
    photoURL: string;
    logo: string;
    logoDark: string;
    logofooter: string;
    domain: string;
    facebook: string;
    instagram: string;
    youtube: string;
    tiktok?:string; 
    twitter?:string; 
    linkedin?: string;
    playstore?: string;
    appstore?: string;

 }




 const SubMenuHeaderEstructura: Campo[] = [
    { name: 'nombre', type: 'string', label: 'Titulo', value: '', editable: true },
    { name: 'description', type: 'string', label: 'Descripción', value: '', editable: false },
    { name: 'icono', type: 'string', label: 'Icono', value: '', editable: false },
    { name: 'enlace', type: 'string', label: 'Enlace', value: '/home', editable: true },
    { name: 'image', type: 'img', label: 'Imagen', value: '', editable: false },
];

const MenuHeaderEstructura: Campo[] = [
    { name: 'nombre', type: 'string', label: 'Titulo', value: '', editable: true },
    { name: 'description', type: 'string', label: 'Descripción', value: '', editable: false },
    { name: 'icono', type: 'string', label: 'Icono', value: '', editable: false },
    { name: 'enlace', type: 'string', label: 'Enlace', value: '/', editable: true },
    { name: 'image', type: 'img', label: 'Imagen', value: '', editable: false },
    { name: 'opciones', type: 'array', label: 'Añadir submenú', value: [], subCampos: SubMenuHeaderEstructura, editable: true },
  ];

export const MenusHeaderEstructura: Campo[] = [
    { name: 'menus', type: 'array', label: 'Añadir menú', value: [], subCampos: MenuHeaderEstructura, editable: true }
];

export const  CrudMenuHeader = {
    icon: 'menu',
    label: 'Ajustes del Menú',
    url: 'menu',
    colorIcon: 'white',
    colorBackgroud: 'linear-gradient( 90deg, #0d6efd 0%, #87bbf3 100%)',
    config: {
        title: 'Ajustes del Menú',
        labelAll: 'Todas los menús',
        setVar: {},
        estructura: MenusHeaderEstructura,
        path: 'Usuarios/' + environment.uidAdmin + '/menuheader/',
        multiple: false,
        id: environment.uidAdmin,
    }
}

export interface MenuHeader {
    nombre: string;
    description?: string;
    enlace: string;
    icono: string;
    image?: ImageItem;
    opciones: MenuHeader[];
 }


export interface DashBoardStyles {
    fontFamily: string;
    colores: {
        primario: string;
        secundario: string;
        terciario: string;
    }
}
