import { Component, OnInit, Input, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { Observable, Subscription } from 'rxjs';
import { Chat, UserChat } from '../../models-chat';
import { ChatService } from '../../services/chat.service';
import { FirestoreService } from '../../services/firestore.service';
import { InteraccionService } from '../../../services/interaccion.service';
import { PopPedidoComponent } from '../pop-pedido/pop-pedido.component';
import { SendpedidowppComponent } from '../sendpedidowpp/sendpedidowpp.component';
import { NotificationsService } from '../../../notifications/services/notifications.service';
import { environment } from '../../../../environments/environment';
import { NotificationApp } from '../../../notifications/models-notifications';
import { InputPhone } from '../../../widgets/crudfast/componentes/inputphone/inputphone.component';
import { AutenticationService } from '../../../services/autentication.service';

declare var $: any;

@Component({
  selector: 'app-multichat',
  templateUrl: './multichat.component.html',
  styleUrls: ['./multichat.component.scss']
})
export class MultichatComponent implements OnInit, OnDestroy {

  @Input() obsChat: Observable<Chat> ;
  @Input() modal: boolean = false;
  load = false;
  chat: Chat;
  chats: Chat[] = [];
  chatsCopy: any = [];
  suscriberChats: Subscription;
  suscriberObsChat: Subscription;

  suscriberLastConexion: Subscription;

  mensajeText = '';
  autoGrow = true;
  enable = false;

  loadMore = false;
  ultConexion = '';
  phoneNumberReceptor: InputPhone = null;
  nombreChat = '';

  enableWhatsapp = environment.enableWhatsapp;
  config = {
    editorplaceholder: 'Escribe un mensaje...',
    extraPlugins: ['divarea', 'autogrow', 'sourcedialog'],
    removePlugins: 'sourcearea',
    tabSpaces: 4,
    sourceAreaTabSize: 8,
    allowedContent: true,
    autoGrow_minHeight: 250,
    // toolbar: [
    //   { name: 'document', items: [ 'Source', '-', 'Save', 'NewPage', 'ExportPdf', 'Preview', 'Print', '-', 'Templates' ] },
    //   { name: 'clipboard', items: [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
    //   { name: 'editing', items: [ 'Find', 'Replace', '-', 'SelectAll', '-', 'Scayt' ] },
    //   { name: 'forms', items: [ 'Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField' ] },
    //   '/',
    //   { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat' ] },
    //   { name: 'paragraph', items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl', 'Language' ] },
    //   { name: 'links', items: [ 'Link', 'Unlink', 'Anchor' ] },
    //   { name: 'insert', items: [ 'Image', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe' ] },
    //   '/',
    //   { name: 'styles', items: [ 'Styles', 'Format', 'Font', 'FontSize' ] },
    //   { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
    // ]
  };
  html = '';


  @HostListener('window:popstate', ['$event'])
    onPopState() {
      if (this.modal) {
        this.modalController.dismiss();
      }
  }



  constructor(private chatService: ChatService,
              private firestoreService: FirestoreService,
              public modalController: ModalController,
              private popoverController: PopoverController,
              private interaccionService: InteraccionService,
              private notificationsService: NotificationsService,
              private autenticationService: AutenticationService) { }

  ngOnInit(): void {
       if (this.obsChat) {
         if (this.suscriberObsChat) {
            this.suscriberObsChat.unsubscribe();
         }
         this.suscriberObsChat = this.obsChat.subscribe( res => {
             this.chat = res;
            // console.log('multichat -> ', this.chat); 
             this.loadChatsMulti();
         });
       }
       if (this.modal) {
          history.pushState(null, null, '/chats');
       }
  }

  ngOnDestroy() {
      console.log('ngOnDestroy() -> modal Multichat');
      if (this.suscriberChats) {
         this.suscriberChats.unsubscribe();
      }
      if (this.suscriberObsChat) {
        this.suscriberObsChat.unsubscribe();
      }
      if (this.suscriberLastConexion) {
        this.suscriberLastConexion.unsubscribe();
      }
  }

  loadChatsMulti() {
      this.load = true;
      this.getNombreChat();
      this.getUltimaConexion();
      this.chatService.clearChatsMulti();
      this.leerMoreChatMulti();
      if (this.suscriberChats) {
        this.suscriberChats.unsubscribe();
      }
      this.gotoBottom();
      this.suscriberChats = this.chatService.getChatsMultiChanges().subscribe( res => {
          if (res) {
              this.loadMore = false;
              this.chats = res;
              this.setDayOfChat();
          }
      });

  }

  leerMoreChatMulti() {
    this.loadMore = true;
    let chatId: string;
    if (this.chat.resend) {
      chatId = this.chat.emisor.uid;
    } else {
      chatId = this.chat.receptor.uid;   
    }
    this.chatService.leerMoreChatsMulti(chatId);
  }

  viewMensaje(chat, $event){}

  sendMsgByWpp(chat: Chat) {
    console.log('sendMsgByWpp');
    if (chat.receptor.celular) {
      let url = '';
      if (chat.receptor.celular) {
        if (chat.receptor.celular.e164Number) {
          const cellnumber = chat.receptor.celular.e164Number.replace('+', '');
          url = 'https://wa.me/' + cellnumber;
        }
      }
      url = url + '?text=';
      // const mensaje = chat.mensaje.replace(/ /g, '%20');
      const mensaje = $(chat.mensaje).text();;
      url = url + mensaje;
      window.open(url);
    }
  }

  async sendByWpp(chat: Chat){
      await this.interaccionService.presentLoading('cargando...');
      const popover = await this.popoverController.create({
        component: SendpedidowppComponent,
        cssClass: 'popoverSearch',
        mode: 'ios',
        componentProps: {chat, pedido: chat.mensaje}
      });
      this.interaccionService.dismissLoading();
      await popover.present();
  }

  setDayOfChat() {
    this.chats.sort((n1, n2) => {
      if (n1.time > n2.time) {
          return 1;
      }
      if (n1.time < n2.time) {
          return -1;
      }
      return 0;
    });
    this.chatsCopy = [];
    this.chatsCopy = JSON.parse(JSON.stringify(this.chats));
    let day0 = new Date();
    this.chatsCopy.forEach( (chat, index) => {
        chat['timeString'] = this.getTimeChat(chat.time.seconds);
        const day = new Date (chat.time.seconds * 1000);
        this.chatsCopy[index].time = 0;
        if ( !this.isEqualDay(day0, day)) {
          const now = new Date();
          let infoDay = '';
          if ( now.getFullYear() === day.getFullYear() &&
               now.getMonth() === day.getMonth() ) {
               const difDate = now.getDate() - day.getDate();
               if ( difDate === 0) {
                infoDay = 'hoy';
               } else if (difDate === 1) {
                  infoDay = 'ayer';
               }
          }
          if (infoDay === '') {
              const month  = day.getMonth() + 1;
              let monthString: string;
              if (month < 10 ) {
                  monthString = '0' + month;
              } else  {
                monthString = '' + month;
              }
              const date  = day.getDate();
              let dayString: string;
              if (date < 10 ) {
                  dayString = '0' + date;
              } else  {
                dayString = '' + date;
              }
              infoDay = day.getFullYear() + '-' + monthString + '-'
                              + dayString;
          }
          this.chatsCopy[index].time = infoDay;
        }
        day0 = day;
    });
  }

  isEqualDay(day1: Date, day2: Date): boolean {
     const stringDay1 = day1.getFullYear() + '-' + day1.getMonth() + '-' + day1.getDate();
     const stringDay2 = day2.getFullYear() + '-' + day2.getMonth() + '-' + day2.getDate();
     if (stringDay1 === stringDay2) {
        return true;
     } else {
        return false;
     }
  }
   
  private getTimeChat(timeStampSecods: number): string {
      let time: string;
      const date = new Date (timeStampSecods * 1000);
      if (date.getMinutes() < 10) {
        time = date.getHours() + ':0' + date.getMinutes();
      } else {
        time = date.getHours() + ':' + date.getMinutes();
      }
      return time;
  }

  changeText() {}

  updatePhoneNumberReceptor() {
    if (this.phoneNumberReceptor) {
      if (this.chat.resend) {
        this.chat.emisor.celular = this.phoneNumberReceptor;
      } else {
        this.chat.receptor.celular = this.phoneNumberReceptor;
      }
      this.chatsCopy.forEach( (chat: Chat) => {
            if (chat.resend) {
              chat.emisor.celular = this.phoneNumberReceptor;
            } else {
              chat.receptor.celular = this.phoneNumberReceptor;
            }
      });
    }
  }


  async sendChat() {
    
      const mensaje = this.mensajeText; 
      let receptor: UserChat;  
      let emisor: UserChat; 
      if (this.chat.resend) {
          receptor = this.chat.emisor;
          emisor = this.chat.receptor;
      } else {
        receptor = this.chat.receptor;
        emisor = this.chat.emisor;
      }
      if (this.phoneNumberReceptor) {
        receptor.celular = this.phoneNumberReceptor;
      }
      emisor.photo = await this.autenticationService.getPhoteUrlProfile();
      emisor.nombre = await this.autenticationService.getProfileNombre();
      this.chatService.sendChat(mensaje, 'string', receptor, emisor).then( res => {
            this.mensajeText = '';
            const notification: NotificationApp = {
              type: 'chat',
              titulo: emisor.nombre,
              descripcion: mensaje,
              enlace: '/chats/' + emisor.uid
            }
            this.notificationsService.sendNewNotification(notification, receptor.uid); 
         
      });
  
  }

  gotoBottom() {
      let bottom = document.getElementById('bottom');
      if (bottom !== null) {
          bottom.scrollIntoView();
          bottom = null;
      }
  }

  getNombreChat() {
    let nombre: string; 
    if (this.chat.resend) {
       nombre = this.chat.emisor.nombre;
    } else {
       nombre = this.chat.receptor.nombre;
    }
    this.nombreChat = nombre;
  }

  getUltimaConexion() {
    let uid: string; 
    console.log('this.chat -> ',this.chat);
    this.ultConexion = '';
    if (this.chat.resend) {
       uid = this.chat.emisor.uid;
    } else {
       uid = this.chat.receptor.uid;
    }
    const path = 'Usuarios/' + uid;
    if (this.suscriberLastConexion) {
      this.suscriberLastConexion.unsubscribe();
    }
    this.suscriberLastConexion = this.firestoreService.getDocumentChanges<any>(path).subscribe( res => {
          if (res) {
              // console.log('getUltimaConexion() -> ', res);
              if (res.celular) {
                this.phoneNumberReceptor = res.celular;
                this.updatePhoneNumberReceptor();
              }
              if (res.lastConexion) {
                const lastConexion = res.lastConexion;
                this.ultConexion = this.getStringUltimaConexion(lastConexion.seconds);
              }
          }
    });

  }

  getStringUltimaConexion(timeStampSecods: number): string {
    const ultConeccion = new Date (timeStampSecods * 1000);
    const now = new Date();
    if ( now.getFullYear() === ultConeccion.getFullYear() &&
         now.getMonth() === ultConeccion.getMonth() ) {

         const difDate = now.getDate() - ultConeccion.getDate();
         if ( difDate === 0) {
             if (now.getHours() === ultConeccion.getHours()) {
                const dif = now.getMinutes() - ultConeccion.getMinutes();
                if ( dif < 1 && dif > -1 ) {
                    return 'en linea';
                }
             }
             return 'ult vez: hoy a las ' + this.getTimeChat(timeStampSecods);
         } else if (difDate === 1) {
           return 'ult vez: ayer a las ' + this.getTimeChat(timeStampSecods);
         }
    }
    return 'ult vez: ' +  ultConeccion.getDate() + '/' +
                  (ultConeccion.getMonth() + 1) + '/' + ultConeccion.getFullYear() +
                   ' a las ' + this.getTimeChat(timeStampSecods);
  }

  copyMsg() {
    // this.interaccionService.showToast('mensaje copiado');
  }


  async openPedido(chat: Chat) {
    await this.interaccionService.presentLoading('cargando...');
    const popover = await this.popoverController.create({
      component: PopPedidoComponent,
      cssClass: 'popoverSearch',
      mode: 'ios',
      componentProps: {chat, pedido: chat.mensaje}
    });
    this.interaccionService.dismissLoading();
    await popover.present();
  }

  loadHtml(event?: any) { }



}
