import { Injectable } from '@angular/core';
import { Subject, Observable, Subscription } from 'rxjs';
import { FirestoreService } from 'src/app/services/firestore.service';
import { MenuHeader } from '../common/backend/models-common-crud';
import { Banner } from '../common/models-common';
import { CrudCategoriasPlan } from '../travel/backend/models-travel-cruds';
import { CategoriasItem } from '../widgets/crudfast/models-crud';
import { NotificationsService } from '../notifications/services/notifications.service';
import { RolesUsers } from '../users/models-users';
import { CrudServicios, Servicio } from '../servicios/models-servicios';
import { WindowService } from './window.service';

@Injectable({
  providedIn: 'root'
})
export class FrontendService {

  private banners: Banner[] = [];
  subscriberBanner: Subscription;
  banners$ = new Subject<Banner[]>();
  bannerEnable$ = new Subject<boolean>();
  bannerEnable = true;

  // menu
  private menu: MenuHeader[] = [];
  menu$ = new Subject<MenuHeader[]>();

  // sideMenu
  private sideMenu: Menu[] = [];
  sideMenu$ = new Subject<Menu[]>();

  constructor(private firestoreService: FirestoreService,
              private notificationsService: NotificationsService,
              private windowService: WindowService) {
        if (!this.windowService.isServer()) {
          this.loadMenuHeader();
        }
  }

  getBannerChanges(): Observable<Banner[]> {
    this.banners$.next(this.banners);
    return this.banners$.asObservable();
  }

  getBanner(): Banner[] {
    return this.banners;
  }

  setBanner(banners: Banner[]) {
      this.banners = banners;
      this.banners$.next(this.banners);
  }

  clearBanner() {
    this.banners = [];
    this.banners$.next(this.banners);
  }

  getBannerEnableChanges(): Observable<boolean> {
    this.bannerEnable$.next(this.bannerEnable);
    return this.bannerEnable$.asObservable();
  }

  setBannerEnable(enable: boolean) {
    this.bannerEnable = enable;
    this.bannerEnable$.next(this.bannerEnable);
  }

  // ------- menu ------- //
  getMenu(): MenuHeader[] {
    return this.menu;
  }

  getMenuChanges(): Observable<MenuHeader[]> {
    return this.menu$.asObservable();
  }

  loadMenuHeader() {
    this.menu = Menu;
    this.menu$.next(this.menu);
    this.loadSideMenu();
    this.loadCategoriasPlanes();
    this.loadServicios();
  }

  loadCategoriasPlanes() {
    const path = CrudCategoriasPlan.config.path + CrudCategoriasPlan.config.id;
    this.firestoreService.getDocument<CategoriasItem>(path).then( res => {
        if (res.exists) {
          const categorias = res.data();
          const opcionesPlanes: MenuHeader[] = [];
            opcionesPlanes.push({nombre: 'Categorias', enlace: '/travel/planes/', icono: null, opciones: [] })
            categorias.categorias.forEach( categoria => {
                  const opc: MenuHeader =
                        { nombre: categoria.nombre, enlace: '/travel/categoria/' + categoria.nombre + '/' + categoria.id, icono: null, opciones: [] };
                  opcionesPlanes.push(opc);
            });
            const menu = this.menu.filter( opc => {
              return opc.nombre === 'Planes y Tours'
            })
            menu[0].opciones = opcionesPlanes;
            this.menu$.next(this.menu);
            this.loadSideMenu();
        }
    });
  }

  loadServicios() {
    const path = CrudServicios.config.path;
    this.firestoreService.getCollectionChanges<Servicio>(path).subscribe( res => {
        if (res) {
            const servicios = res;
            const opcionesServicios: MenuHeader[] = [];
            servicios.forEach( servicio => {
                  if (servicio.nombre) {
                    const opc: MenuHeader =
                          { nombre: servicio.nombre, enlace: '/servicios/servicio/' + servicio.enlacePermanente, icono: null, opciones: [] };
                    opcionesServicios.push(opc);
                  }
            });
            const menu = this.menu.filter( opc => {
              return opc.nombre === 'Servicios'
            })
            menu[0].opciones = opcionesServicios;
            // console.log('menu servicios ->', menu[0]);
            this.menu$.next(this.menu);
            this.loadSideMenu();
        }
    });
  }

  getSideMenu(): Menu[] {
    return this.sideMenu;
  }

  getSideMenuChanges(): Observable<Menu[]> {
    return this.sideMenu$.asObservable();
  }

  loadSideMenu() {
    this.sideMenu = [];
    const MenuNavegacion: Menu = {
        nombre: 'Navegación',
        icono: 'map-outline',
        opciones: [],
        rol: [null],
    };
    let opciones: MenuOpc[] = []
    this.menu.forEach( menu => {
        if (menu.nombre !== 'Planes y Tours'
         && menu.nombre !== 'Servicios') {
            const opc: MenuOpc = {
                nombre: menu.nombre,
                enlace: menu.enlace
            }
            opciones.push(opc);
        }
    });
    MenuNavegacion.opciones = opciones;
    this.sideMenu.push(MenuNavegacion);
    const menuPlan = this.menu.filter( opc => {
      return opc.nombre === 'Planes y Tours'
    });
    if (menuPlan.length) {
        const MenuPlanes: Menu = {
          nombre: 'Planes y Tours',
          icono: 'calendar-outline',
          opciones: [],
          rol: [null],
        };
        opciones = []
        menuPlan[0].opciones.forEach( menu => {
              const opc: MenuOpc = {
                  nombre: menu.nombre,
                  enlace: menu.enlace
              }
              opciones.push(opc);
        });
        MenuPlanes.opciones = opciones;
        this.sideMenu.push(MenuPlanes);
    }
    const menuServicios = this.menu.filter( opc => {
      return opc.nombre === 'Servicios'
    });
    if (menuServicios.length) {
        const MenuServicios: Menu = {
          nombre: 'Servicios',
          icono: 'server-outline',
          opciones: [],
          rol: [null],
        };
        opciones = []
        menuServicios[0].opciones.forEach( menu => {
              const opc: MenuOpc = {
                  nombre: menu.nombre,
                  enlace: menu.enlace
              }
              opciones.push(opc);
        });
        MenuServicios.opciones = opciones;
        this.sideMenu.push(MenuServicios);
    }
    this.sideMenu.push(perfil);
    perfil.opciones[0].accion = () => {
          this.notificationsService.inicializar(true);
    }
    this.sideMenu.push(gestorAdmin);
    this.sideMenu.push(gestorAsesor);
    this.sideMenu.push(ajustes);
    this.sideMenu.push(ajustesAsesor);
    this.sideMenu.forEach( menu => {
        menu.access = (rol: RolesUsers) => {
          return menu.rol.find( (rolSet ) => { return rol === rolSet }) ||
                 menu.rol[0] === null ? true: false;
        }
    });
    this.sideMenu$.next(this.sideMenu);
  }

}


const Menu: MenuHeader[] = [
    { nombre: 'Inicio', enlace: '/', icono: null, opciones: [] },
    { nombre: 'Conócenos', enlace: '/pages/conocenos', icono: null, opciones: [] },
    { nombre: 'Planes y Tours', enlace: null, icono: null, opciones: [] },
    { nombre: 'Blog', enlace: '/posts/blog', icono: null, opciones: [] },
    { nombre: 'Contáctanos', enlace: '/pages/contactanos', icono: null, opciones: [] },
    { nombre: 'Servicios', enlace: null, icono: null, opciones: [] },
]

interface MenuOpc {
  nombre: string;
  enlace: string;
  accion?: any;
}

export interface Menu {
   nombre: string;
   icono: string;
   opciones: MenuOpc[];
   rol: RolesUsers [];
   access?: any;
}


const perfil: Menu = {
    nombre: 'Perfil',
    icono: 'person-circle-outline',
    rol: ['usuario'],
    opciones: [
      {  nombre: 'Activar notificaciones', enlace: '', accion: null },
      {  nombre: 'Mis Reservas', enlace: 'travel/misreservas', },
    ],
};

const  gestorAdmin: Menu = {
  nombre: 'Gestor',
  icono: 'file-tray-full',
  rol: ['admin'],
  opciones: [
    {  nombre: 'Reservas', enlace: '/travel/reservas/gestor' },
    { nombre: 'Usuarios', enlace: '/user/gestor' },
    { nombre: 'Comentarios', enlace: '/comentarios/gestor' },
    { nombre: 'Chat', enlace: '/chats' },
  ],
};

const  gestorAsesor: Menu = {
  nombre: 'Gestor',
  icono: 'file-tray-full',
  rol: ['asesor'],
  opciones: [
    {  nombre: 'Reservas', enlace: '/travel/reservas/gestor' },
    { nombre: 'Chat', enlace: '/chats' },
  ],
};

const  ajustes: Menu = {
  nombre: 'Ajustes',
  icono: 'cog',
  rol: ['admin', 'editor'],
  opciones: [
    {  nombre: 'Datos', enlace: '/ajustes', },
    {  nombre: 'Planes | Tours', enlace: '/travel/set', },
    {  nombre: 'Páginas', enlace: '/pages/set', },
    {  nombre: 'Blog', enlace: 'posts/set', },
    {  nombre: 'Multimedia', enlace: 'multimedia', },
    {  nombre: 'Chatbot', enlace: 'chatbot/set', },
  ]
};

const  ajustesAsesor: Menu = {
  nombre: 'Ajustes',
  icono: 'cog',
  rol: ['asesor'],
  opciones: [
    {  nombre: 'Planes | Tours', enlace: '/travel/set', },
  ]
};
