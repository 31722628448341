import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ConfigCrudFast, ConfigSearchCrud, SearchDoc } from '../../models-crud';
import { FirestoreService } from '../../../../services/firestore.service';
import { InteraccionService } from '../../../../services/interaccion.service';
import { Subscription, Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { SeoService } from '../../../../services/seo.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { WindowService } from '../../../../services/window.service';


@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements OnInit, OnDestroy {

  @Input() config: ConfigCrudFast;
  @Input() enlace: string;

  subscriber: Subscription;
  emitSave = new Subject<any>();
  emitDelete = new Subject<void>();

  configSearch: ConfigSearchCrud;
  editNewMultiple = false;
  loadDoc = false;
  validator = null;
  validatorAsync = null;
  suscriberRoute: Subscription;

  constructor(private firebaseService: FirestoreService,
              private interaccionService: InteraccionService,
              private seoService: SeoService,
              private router: Router,
              private clipboard: Clipboard,
              private activateRoute: ActivatedRoute,
              private windowService: WindowService) { 

                // agregado | 23/01/2022 - enlace crud
                this.suscriberRoute = this.activateRoute.params.subscribe( async params => {
                  // console.log('params -> editor', params)
                  if (params.enlace !== undefined && this.config) {
                        this.enlace = params.enlace;
                        this.ngOnInit();
                  }
                });
  }

  ngOnInit(): void {
        // console.log('ngOnInit() editor ->', this.config);     
        this.validator = null
        this.validatorAsync = null;
        if (this.config.functionValidator) {
            // console.log('it has functionValidator');     
            this.validator = this.config.functionValidator;
        }
        if (this.config.functionAsyncValidator) {
          this.validatorAsync = this.config.functionAsyncValidator;
        }
        this.config = JSON.parse(JSON.stringify(this.config));
        if (this.config.multiple) {
            if (this.config.categoriasPath !== undefined) {
              this.loadCategorias();
            }
            if(this.enlace !== undefined) {
                this.editNewMultiple = true;
                // console.log('load single multple')
                this.loadSingleMultiple(this.enlace)
            } 
            this.configSearch = {
              path: this.config.path,
              searchPath: this.config.searchPath ? this.config.searchPath : this.config.path,
              editPath: this.config.editPath,
              viewPath: this.config.viewPath,
              title: this.config.title,   
            }
            // console.log('this.config editor -> ', this.config);
        } else {
            this.loadSingle();
        }

        document.addEventListener('paste', (event: any) => {
          const window = this.windowService.getWindow();
          let paste = (event.clipboardData || window.clipboardData).getData('text');
          this.pasteConfigVar(paste);
          // event.preventDefault();
        });
        
  }
  
  ngOnDestroy(): void {
      if (this.subscriber) {
         this.subscriber.unsubscribe();
      } 
  }

  loadSingle() {
    const path = this.config.path + this.config.id;
    this.firebaseService.getDocument<any>(path).then( res => {
          this.loadDoc = false;
          if (res.exists) {
              this.config.setVar = res.data();
              this.loadDoc = true;
              // console.log('loadSingle() editor', this.config.setVar);
          }
    });
  }

  loadSingleMultiple(enlace: string) {
    const path = this.config.path;
    this.subscriber = this.firebaseService.getCollectionQuery<any>(path, 'enlacePermanente', enlace).subscribe( res => {
            // console.log('loadSingleMultiple ->', res);
            if (res.length && !this.loadDoc) {
                this.config.setVar = res[0];
                this.editNewMultiple = true;
                this.loadDoc = true;
            }
    });
  }

  async save() {
      console.log('save() -> ', this.config.setVar);
      if (this.validator) {
        console.log('go validator');
        const res = this.validator(this.config.setVar);
        if (res === 'invalid') {
            return;
        }
      }
      if (this.validatorAsync) {
        console.log('go validatorAsync');
        const res = await this.validatorAsync(this.config.setVar);
        if (res === 'invalid') {
            return;
        }
      }
      const path = this.config.path;
      let id: string;
      this.setEnlacePermanente();
      if (this.config.multiple) { 
        id = this.config.setVar.id;
      } else { 
        id = this.config.id; 
      }
      await this.interaccionService.presentLoading();
      this.firebaseService.createDocumentID(this.config.setVar, path, id).then( _ => {
          this.interaccionService.showToast('Guardado con éxito');
          this.interaccionService.dismissLoading();
          // console.log('save() -> emitSave.next - ', this.config.setVar);
          this.emitSave.next(this.config.setVar);
          if (this.config.multiple && this.editNewMultiple) {
            this.saveInSearch(this.config.setVar) 
          }
          if (this.config.multiple) {
            this.router.navigate([this.config.editPath + this.config.setVar.enlacePermanente])
          }
      }).catch( error => {
          this.interaccionService.showToast('No se pudo guardar');
          console.log('error -> ', error);
          this.interaccionService.dismissLoading();
      });
  }

  addNew(){
      const init = {};
      const newItem = init as any;
      newItem.id = this.firebaseService.createIdDoc();
      this.config.setVar = newItem;
      this.editNewMultiple = true;
      this.loadDoc = true;
  }

  loadCategorias() {
    const path = this.config.categoriasPath;
    this.firebaseService.getDocumentChanges<any>(path).subscribe( res => {
        if (res !== undefined) {
            const campo = this.config.estructura.find( campo => {
                return campo.name === 'categoria'
            });
            campo.categorias = res.categorias;
        }
    });
  }

  clonar(id: string) {
    // console.log('clonar ->', id);
    const path = this.config.path + id;
    this.interaccionService.presentLoading();
    this.firebaseService.getDocument<any>(path).then( res => {
            this.interaccionService.dismissLoading();
            if (res.exists) {
                this.interaccionService.showToast('Clonado con exito');
                this.config.setVar = res.data();
                this.config.setVar.enlacePermanente = '';
                this.config.setVar.id = this.firebaseService.createIdDoc();
                this.editNewMultiple = true;
                this.loadDoc = true;
            }
      }).catch( error => {
          this.interaccionService.showToast('No se pudo clonar');
          console.log('error -> ', error);
          this.interaccionService.dismissLoading();
    });

  }

  backTodos() {
      this.loadDoc = false;
      this.editNewMultiple = false;
  }

  saveInSearch(item: any) {
      const path = this.config.searchPath ? this.config.searchPath: this.config.path;
      // console.log('saveInSearch -> ', this.config.searchPath, this.config.path)  
      const id = 'search';
      let ItemtoSave = {} as SearchDoc;
      ItemtoSave.nombre = item.nombre ? item.nombre : item.titulo;
      ItemtoSave.descripcion = item.descripcion ? item.descripcion : '';
      ItemtoSave.enlacePermanente = item.enlacePermanente ? item.enlacePermanente : this.seoService.generateSlug(ItemtoSave.nombre);
      ItemtoSave.id = item.id;
      if (item.no_visible) {
        ItemtoSave.no_visible = true;
      }
      const updateDoc = {} as any;
      updateDoc[ItemtoSave.id] = ItemtoSave;
      // console.log('saveInSearch -', updateDoc, path)
      this.firebaseService.updateDocumentID(updateDoc, path, id).catch( () => {
            this.firebaseService.createDocumentID(updateDoc, path, id)
      });
  }

  copyConfigVar() {
    this.clipboard.copy(JSON.stringify(this.config.setVar));
    this.interaccionService.showToast('copiado')
  }

  // cambio | agregado 27/01/2021 | enlacepermanente
  setEnlacePermanente() {
      if (this.config.multiple || this.config.estructura.find( campo => { return (campo.name === 'enlacePermanente') })) {
        const enlace = this.config.estructura.find( 
                            campo => { return (campo.name === 'enlacePermanente' && campo.value) });
        this.config.setVar.enlacePermanente = enlace ? enlace.value :
                                              this.config.setVar.nombre ? this.seoService.generateSlug(this.config.setVar.nombre) 
                                              : this.seoService.generateSlug(this.config.setVar.titulo)      
        console.log('setEnlacePermanente() -> ', this.config.setVar.enlacePermanente);
      }
  }

  pasteConfigVar(paste: any) {
    try {
      const variable = JSON.parse(paste);
        this.config.estructura.forEach( campo => {
          if (variable[campo.name]) {
            this.config.setVar[campo.name] = variable[campo.name]; 
          }
      });   
    } catch (error) { }

}



}
