import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Campo } from '../../models-crud';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit {

  @Input() camposEstructura: Campo[] = [];
  @Input() setVar: any[] = [];
  @Input() selectCampo: string;
  @Input() setVarUp: string;

  constructor(private popoverController: PopoverController) { }

  ngOnInit() {}

  select(opc: any) {
      this.popoverController.dismiss(opc);

  }

}
