import { Component, OnInit, OnDestroy } from '@angular/core';
import { CategoriasItem } from 'src/app/widgets/crudfast/models-crud';
import { seccionRecommender } from '../../modelstravel';
import { CrudCategoriasPlan, CrudBannerTravel } from '../../backend/models-travel-cruds';
import { FirestoreService } from 'src/app/services/firestore.service';
import { WindowService } from '../../../services/window.service';
import { Apariencia, AppearanceService } from '../../../services/appearance.service';
import { CrudDatos, DatosEmpresaI } from '../../../common/backend/models-common-crud';
import { Subscription } from 'rxjs';
import { Banner } from 'src/app/common/models-common';


@Component({
  selector: 'app-hometravel',
  templateUrl: './hometravel.component.html',
  styleUrls: ['./hometravel.component.scss'],
})
export class HometravelComponent implements OnInit, OnDestroy {

  categorias: CategoriasItem = {
    categorias: []
  };
  seccionRecommender: seccionRecommender = 'Destacados';
  suscriberInfo: Subscription;
  suscriberBanners: Subscription;
  apariencia: Apariencia;   // ---- templates
  info: DatosEmpresaI = null;
  banners: Banner[] = [];
  dark = false;
  imageLogoHeader: string = null;

  constructor(private firestoreService: FirestoreService,
              private windowService: WindowService,
              private appearanceService: AppearanceService // ---- templates
              ) { 

          !this.windowService.isServer() ? this.loadCategorias() : null;
          this.loadAppearance();
          this.loadInfo();
          this.loadBannersTravel();
          
  }

  ngOnInit() {
      this.dark = this.appearanceService.getDarkMode();
      this.appearanceService.getDarkModeChanges().subscribe( res => {
          this.dark = res;
          this.setImageLogoHeader();
      });
  }

  ngOnDestroy() {
    if (this.suscriberInfo) {
      this.suscriberInfo.unsubscribe();
    }
    if (this.suscriberBanners) {
      this.suscriberBanners.unsubscribe();
    }
  }

  loadCategorias() {
    const path = CrudCategoriasPlan.config.path + CrudCategoriasPlan.config.id;
    this.firestoreService.getDocument<CategoriasItem>(path).then( res => {
        if (res.exists) {
            const categorias = res.data();
            categorias.categorias.forEach( categoria => {
              categoria.path = '/travel/categoria/' + categoria.nombre + '/' + categoria.id;
              categoria.subCategorias.forEach( subcategoria => {
                subcategoria.path = 'travel/categoria/' + subcategoria.nombre + '/' + subcategoria.id;       
              });
            });
            categorias.categorias.unshift({nombre: 'Todas', descripcion: '', id: null, imagen: null, subCategorias: [], path: '/travel/planes'})      
            this.categorias = categorias;
        }
    });
  }

  loadInfo() {
    const path = CrudDatos.config.path + CrudDatos.config.id;
    this.suscriberInfo = this.firestoreService.getDocumentChanges<DatosEmpresaI>(path).subscribe( res => {
      this.info = res;    
      this.setImageLogoHeader();  
    });
  }

  loadBannersTravel() {
    const path = CrudBannerTravel.config.path + CrudDatos.config.id;
    this.suscriberInfo = this.firestoreService.getDocumentChanges<{banners:Banner[]}>(path).subscribe( res => {
        if (res) {
          if (res.banners) {
            this.banners = [];          
            res.banners.forEach( banner => {
              if (banner.enable) {
                  this.banners.push(banner);
              }
            })
          }
        }      
    });
  }

  loadAppearance() {   // ---- templates
    this.apariencia = this.appearanceService.getApariencia();
    this.appearanceService.getAparienciaChanges().subscribe( apariencia => {
      this.apariencia = apariencia;  
    });
  }

  setImageLogoHeader() {
    if (this.info) {
         if (!this.dark) {
           this.imageLogoHeader = this.info.logo ? this.info.logo : null;
         } else {
           this.imageLogoHeader = this.info.logoDark ? this.info.logoDark : (this.info.logo ? this.info.logo : null);
         }
    }
 }


}
