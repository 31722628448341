import { Injectable } from '@angular/core';
import { PreguntaChatBot, RespuestaChatbot } from '../models-chatbot';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserChat, Chat } from '../../chat/models-chat';
import { ChatService } from '../../chat/services/chat.service';
import { Subject, Subscription } from 'rxjs';
import { AutenticationService } from '../../services/autentication.service';
import { WindowService } from '../../services/window.service';
import { environment } from '../../../environments/environment.prod';
import { FirestoreService } from 'src/app/services/firestore.service';
import { Parametros } from '../../../environments/parametros';
import { ParametrosChatbotI, CrudParametrosChatbot } from '../backend/models-chatbot-crud';
import { NotificationsService } from '../../notifications/services/notifications.service';
import { NotificationApp, UserInfo } from '../../models';
import { Email } from '../../notifications/models-notifications';
import { SendemailService } from '../../notifications/services/sendemail.service';



@Injectable({
  providedIn: 'root'
})
export class ChatbotService {

  receptorUser: UserChat;
  emisorBot: UserChat;
  chatBot = new Subject<Chat[]>();
  uidBot: string = environment.uidAdmin;
  suscriberLeerMoreChatsMulti: Subscription;

  private chats: Chat[] = [];
  private chats$ = new Subject<Chat[]>();
  private chatsMulti: Chat[] = [];
  private chatsMulti$ = new Subject<Chat[]>();
  private parametrosChatbot: ParametrosChatbotI;
  private parametrosChatbot$ = new Subject<ParametrosChatbotI>();
  

  uid: string;
  suscriberUid: Subscription;
  timerUltimaConexion: any;
  timerSetEntregado: any;
  private initChatbotAlready: boolean = false;
  private sendTextNeedLogin: boolean = false;
  private sendTextBienvenidaWithName: boolean = false;
  private sendTextNeedCelular: boolean = false;
  private alreadyRequestAsesorToChat: boolean = false;
  enableGetCelular: boolean = false;

  usersToSendNotifications : {email: string, uid: string}[] = [];
  thinking: boolean = false;
  disabledChatbot: boolean = false;

  constructor(private http: HttpClient,
              private chatService: ChatService,
              private autenticationService: AutenticationService,
              private windowService: WindowService,
              private firestoreService: FirestoreService,
              private notificationsService: NotificationsService,
              private sendemailService: SendemailService) {

        this.initParametrosChatbot();
        if (this.windowService.isServer()) {return;}
        this.loadParametrosChatbot();
        this.autenticationService.authState().subscribe(res => {
            if (res) {
                this.uid = res.uid;
                this.loadChats();
                this.initUltimaConexion();
            }
        });
  }

  initUltimaConexion() {
      this.setUltimaConexion();
      this.timerUltimaConexion = setTimeout(() => {
        this.initUltimaConexion();
      }, 60000);
  }

  setUltimaConexion() {
        const path = 'Usuarios/'
        const updateDoc = {
            lastConexion: this.windowService.getTime()
        }
        this.firestoreService.updateDocumentID(updateDoc, path, this.uid).catch( () => {
            this.firestoreService.createDocumentID(updateDoc, path, this.uid)
        })
        console.log('setUltimaConexion()');
  }

  setChat(receptor: UserChat, emisor: UserChat) {
    
    this.receptorUser = receptor;
    this.emisorBot = emisor;
    // console.log('setChat -> ',  this.emisorBot);
  }

  initChatBot() {
    console.log('initChatBot()');
    if (!this.initChatbotAlready) {
      this.initChatbotAlready = true;
      this.putRespuestaChatbot(this.parametrosChatbot.mensajeBienvenida);
    }
  }


  loadChats() {
    this.clearChats();
    this.leerNewChatsMulti(this.uidBot);
  }

  clearChats() {
    this.chats = [];
  }

  sendQuestion(pregunta: PreguntaChatBot) {
      this.thinking = true;
      const data = {
        question: pregunta.question
      }
      console.log('sendQuestion chatbot -> ', data);
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type' : 'application/json; charset=utf-8',
          'Accept'       : 'application/json',
        }),
      };
      const url = 'https://us-central1-' + environment.firebaseConfig.projectId + '.cloudfunctions.net/chatbotRequest';    
      return this.http.post<RespuestaChatbot>(url, data, httpOptions).subscribe( res => {
          console.log('recibo respuesta chatbot -> ', res);
          if (res.result.answer) {
            // this.putRespuestaChatbot(res.result.answer)
            // console.log('el emisor es -> ', this.emisorBot);        
            this.saveRespuestaChat(res, this.receptorUser, this.emisorBot);
          } else {
            this.putRespuestaDefault();
            // this.thinking = false;
          }
      });
  }

  saveRespuestaChat(respuesta: RespuestaChatbot, receptor: UserChat, emisor: UserChat) {
      this.chatService.sendChat(respuesta.result.answer, 'string', receptor, emisor).then( res => {
            console.log('putRespuesta -> ', res);
            // this.thinking = false;
      });
  }

  getChatsMultiChanges() {
    this.windowService.setTimeOut(100).then( () => {
        this.chatsMulti$.next(this.chatsMulti);
    });
    return this.chatsMulti$.asObservable();
  }

  leerNewChatsMulti(chatId: string) {
    if (!this.uid) {
       this.chatsMulti$.next(this.chatsMulti);
    }
    const path = 'Usuarios/' + this.uid + '/chat/' + chatId + '/chats'; ;
    let STARTAT = null;
    this.suscriberLeerMoreChatsMulti = this.firestoreService.getCollectionOrderLimit<Chat>(path, 6, 'time', 'desc', STARTAT).subscribe( res => {
        if (res !== undefined) {
              res.forEach( chatLoad => {
                    this.setVistoChat(chatLoad); 
                    let exist = false;
                    this.chatsMulti.every( (chatExist, index) => {
                            if (chatExist.id === chatLoad.id) {
                              exist = true;
                              this.chatsMulti[index] = chatLoad;
                              return false;
                            }
                            return true;
                    });
                    if (!exist) {
                        this.chatsMulti.push(chatLoad);
                    }
              });
              this.chatsMulti$.next(this.chatsMulti);
          }
    });


  }

  leerMoreChatsMulti(chatId: string) {
    if (!this.uid) {
       this.chatsMulti$.next(this.chatsMulti);
    }
    const path = 'Usuarios/' + this.uid + '/chat/' + chatId + '/chats'; ;
    let STARTAT = null;
    if (this.chatsMulti.length) {
        STARTAT = this.chatsMulti[0].time;
    }
    this.suscriberLeerMoreChatsMulti = this.firestoreService.getCollectionOrderLimit<Chat>(path, 6, 'time', 'desc', STARTAT).subscribe( res => {
        if (res !== undefined) {
              res.forEach( chatLoad => {
                    this.setVistoChat(chatLoad);
                    let exist = false;
                    this.chatsMulti.every( (chatExist, index) => {
                            if (chatExist.id === chatLoad.id) {
                              exist = true;
                              this.chatsMulti[index] = chatLoad;
                              return false;
                            }
                            return true;
                    });
                    if (!exist) {
                        this.chatsMulti.push(chatLoad);
                    }
              });
              this.chatsMulti$.next(this.chatsMulti);
          }
    });
  }

  setVistoChat(chat: Chat) {  
    if (chat.resend && chat.chatState !== 'visto') {
       console.log('setVistoChat -> ', chat);
       const updateDoc = {
           chatState: 'visto'
       }
       let path = 'Usuarios/' + chat.receptor.uid + '/chat/' + chat.emisor.uid + '/chats';
       this.firestoreService.updateDocumentID(updateDoc, path, chat.id);
       path = 'Usuarios/' + chat.receptor.uid + '/chat/';
       this.firestoreService.updateDocumentID(updateDoc, path, chat.emisor.uid);
       path = 'Usuarios/' + chat.emisor.uid + '/chat/' + chat.receptor.uid + '/chats';
       this.firestoreService.updateDocumentID(updateDoc, path, chat.id);
       path = 'Usuarios/' + chat.emisor.uid + '/chat/';
       this.firestoreService.updateDocumentID(updateDoc, path, chat.receptor.uid);
    }
 }

  putRespuestaChatbot(mensaje: string) {
      const chat: Chat = {
          id: this.firestoreService.createIdDoc(),
          chatId: this.uidBot,
          emisor: this.emisorBot,
          receptor: this.receptorUser,
          chatType:'string',
          mensaje,
          chatState: 'enviado',
          resend: true,
          time: new Date(),
          timevalue: ''
      }
      chat.time.setSeconds( chat.time.getSeconds() + 5 )
      this.chatsMulti.push(chat);
      this.chatsMulti$.next(this.chatsMulti);
  }

  putRespuestaWithOptionsChatbot(mensaje: string, opciones: { texto: string; action: any }[]) {
    const chat: Chat = {
        id: this.firestoreService.createIdDoc(),
        chatId: this.uidBot,
        emisor: this.emisorBot,
        receptor: this.receptorUser,
        chatType: 'opciones',
        mensaje,
        chatState: 'enviado',
        resend: true,
        time: new Date(),
        timevalue: '',
        opciones
    }
    chat.time.setSeconds( chat.time.getSeconds() + 5 )
    this.chatsMulti.push(chat);
    this.chatsMulti$.next(this.chatsMulti);
}

  putRespuestaDefault() {
      let mensaje = this.parametrosChatbot.respuestaDefualt;
      this.putRespuestaChatbot(mensaje);
      this.requestAsesorToChat();
  }

  loadParametrosChatbot() {
    const path = CrudParametrosChatbot.config.path + CrudParametrosChatbot.config.id;
    this.firestoreService.getDocumentChanges<ParametrosChatbotI>(path).subscribe( res => {
          if (res) {
              res.imagenBot.thumbnail ? this.parametrosChatbot.imagenBot = res.imagenBot : null;
              res.fondoChatBot.thumbnail ? this.parametrosChatbot.fondoChatBot = res.fondoChatBot : null;
              res.nombreBot ? this.parametrosChatbot.nombreBot = res.nombreBot : null;
              res.descripcionBot ? this.parametrosChatbot.descripcionBot = res.descripcionBot  : null;
              res.colorMarco ? this.parametrosChatbot.colorMarco = res.colorMarco  : null;
              res.colorContraste ? this.parametrosChatbot.colorContraste = res.colorContraste  : null;
              res.iconoBot ? this.parametrosChatbot.iconoBot = res.iconoBot  : null;        
              res.mensajeBienvenida ? this.parametrosChatbot.mensajeBienvenida = res.mensajeBienvenida  : null;
              res.mensajeFlotante ? this.parametrosChatbot.mensajeFlotante = res.mensajeFlotante  : null;
              res.respuestaDefualt ? this.parametrosChatbot.respuestaDefualt = res.respuestaDefualt  : null;
              this.parametrosChatbot.iconoBot = paramsChatBot.iconos[this.parametrosChatbot.iconoBot];
              this.parametrosChatbot$.next(this.parametrosChatbot);
          }
          // this.initChatBot();
    });
  }

  initParametrosChatbot() {
      this.parametrosChatbot = {
          imagenBot: {
            url: paramsChatBot.imagenBot,
            thumbnail: paramsChatBot.imagenBot,
          },
          nombreBot: paramsChatBot.nombreBot,
          descripcionBot: paramsChatBot.descripcionBot,
          colorMarco: Parametros.colores.primario,
          colorContraste: Parametros.colores.primario_contraste,
          iconoBot: 'segundo',
          mensajeBienvenida: paramsChatBot.mensajeBienvenida,
          mensajeFlotante: paramsChatBot.mensajeFlotante,
          fondoChatBot: {
            url: paramsChatBot.fondoChatBot,
            thumbnail: paramsChatBot.fondoChatBot     
          },
          respuestaDefualt: paramsChatBot.respuestaDefualt
      }
      this.parametrosChatbot.iconoBot = paramsChatBot.iconos[this.parametrosChatbot.iconoBot];
  }

  getParametrosChatbotChanges() {
    return this.parametrosChatbot$.asObservable();
  }

  getParametrosChatbot() {
    return this.parametrosChatbot;
  }

  respondWithNeedLogin(mensaje: string, opciones: { texto: string; action: any }[]) {
      if (!this.sendTextNeedLogin) {
        this.putRespuestaWithOptionsChatbot(mensaje, opciones);
        this.sendTextNeedLogin = true;
      }
  }

  respondBievenidaWithName(mensaje: string) {
    if (!this.sendTextBienvenidaWithName) {
      this.putRespuestaChatbot(mensaje);
      this.sendTextBienvenidaWithName = true;
      // this.requestAsesorToChat();
      this.loadUsersToSendNotifications();
    }
  }

  respondWithNeedCelular(mensaje: string, opciones: { texto: string; action: any }[]) {
    if (!this.sendTextNeedCelular) {
      this.putRespuestaWithOptionsChatbot(mensaje, opciones);
      this.sendTextNeedCelular = true;
    }
  }


  requestAsesorToChat() {
    console.log('requestAsesorToChat()');
    let mensaje:  string = '<p>¿No he podido resolver tus dudas?</p>';
    mensaje = mensaje + '<p>¿Deseas comunicarte con un asesor?</p>';
    const opciones: { texto: string; action: any; } [] = [
      { texto: 'SI',
        action: async () => {
          console.log('si click requestAsesorToChat()');
          if (!this.alreadyRequestAsesorToChat) {
            mensaje = 'Solicitud de chat: ' + this.receptorUser.nombre;
            const uids = this.getUidsToSendNotification();
            uids.forEach( uid => {
                const notification: NotificationApp = {
                    type: 'chat',
                    titulo: this.receptorUser.nombre,
                    descripcion: mensaje,
                    enlace: '/chats/' + this.receptorUser.uid
                }
                this.notificationsService.sendNewNotification(notification, uid);
            });
            const email: Email = {
              from: this.receptorUser.email,
              to: this.getEmailsToSendCorreo(),
              subject: 'Solicitud de chat: ' + this.receptorUser.nombre,
              html: null
            };
            email.html = `
                    <h2>Solicitud de chat</h2>
                    <p> <strong>Email: </strong> ${email.from}</p>
                    <p> <strong>Nombre: </strong> ${email.subject}</p>
                    <p> <strong>Mensaje: </strong> 
                    <p></p>`;
            this.sendemailService.sendEmail(email);
          }
          this.alreadyRequestAsesorToChat = true;
          mensaje = '<p>He solicitado un asesor, pronto se unirá al chat</p>';
          this.disabledChatbot = true;
          this.putRespuestaChatbot(mensaje);

        }
      }
    ];
    this.putRespuestaWithOptionsChatbot(mensaje, opciones);
  }

  async sendNewNotification(mensaje: string, emisor: UserChat, receptor: UserChat) {
    const notification: NotificationApp = {
      type: 'chat',
      titulo: emisor.nombre,
      descripcion: mensaje,
      enlace: '/chats/' + emisor.uid
    }
    await this.notificationsService.sendNewNotification(notification, receptor.uid);        
  }

  async loadUsersToSendNotifications() {
    console.log('loadUsersToSendNotifications');
    
    this.usersToSendNotifications = [];
    const path = 'Usuarios/';
    let toAdmins = [];
    let toAsesores = [];
    this.firestoreService.getCollectionQuery<UserInfo>(path, 'rol', 'asesor').subscribe( res => {
        toAsesores = [];
        this.usersToSendNotifications = [];
        if (res.length) {
            res.forEach( asesor => { toAsesores.push({email: asesor.email, uid: asesor.id});});
        }
        // console.log('asesor -> ', toAsesores);
        
        this.usersToSendNotifications = [];
        this.usersToSendNotifications.push(...toAdmins);
        this.usersToSendNotifications.push(...toAsesores);
    });
    this.firestoreService.getCollectionQuery<UserInfo>(path, 'rol', 'admin').subscribe( res => {
        toAdmins = [];
        if (res.length) {
            res.forEach( admin => { toAdmins.push({email: admin.email, uid: admin.id});});
        }
        this.usersToSendNotifications = [];
        this.usersToSendNotifications.push(...toAdmins);
        this.usersToSendNotifications.push(...toAsesores);
        // console.log('toAdmins -> ', toAdmins);
    });
  }

  getEmailsToSendCorreo() : string[] {
    const emails: string[] = [];
    const info = this.emisorBot;
    emails.push(info.email);
    this.usersToSendNotifications.forEach( user => {
        if (info.email != user.email) {
            emails.push(user.email);
        }
    });
    // console.log('getEmailsToSendCorreo() ->', emails);
    return emails;
  }

  getUidsToSendNotification() : string[] {
      const uids: string[] = [];
      const info = this.emisorBot;
      uids.push(info.uid);
      this.usersToSendNotifications.forEach( user => {
          if (info.uid != user.uid) {
              uids.push(user.uid);
          }
      });
      // console.log('getUidsToSendCorreo() ->', uids);
      return uids;
  }









}


const paramsChatBot = {
  mensajeBienvenida: 'Bienvenido al asesor inteligente',
  mensajeFlotante: 'Hola, soy tu asistente virtual',
  opciones: "<p>Te podemos ayudar en lo siguiente:</p> <p> <strong>Horario</strong> </p>",
  ayuda: "<p>A continuación, escribe tu pregunta de manera concisa</p>",
  nombreBot: 'Asistente virtual',
  descripcionBot: 'Chatbot de Atención al cliente',
  respuestaDefualt: '<p> Estoy aprendiendo, por el momento no tengo respuesta para la pregunta </p>',
  iconos: {
     primero: 'assets/imagenes/chatbot-primero.svg',
     segundo: 'assets/imagenes/chatbot-segundo.svg',
     tercero: 'assets/imagenes/chatbot-tercero.svg',
  },
  fondoChatBot: '/assets/imagenes/fondochat.jpg',
  imagenBot: 'assets/imagenes/imagen-chatbot-default.png'
}
