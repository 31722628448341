import { Component, Output, ViewChild, EventEmitter, Input, Renderer2, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { InteraccionService } from '../../../../services/interaccion.service';

@Component({
  selector: 'app-constructor',
  templateUrl: './constructor.component.html',
  styleUrls: ['./constructor.component.scss']
})
export class ConstructorComponent {

  config = {
    extraPlugins: ['divarea', 'autogrow', 'sourcedialog'],
    removePlugins: 'sourcearea',
    tabSpaces: 4,
    sourceAreaTabSize: 8,
    allowedContent: true,
    autoGrow_minHeight: 250,
    // toolbar: [
    //   { name: 'document', items: [ 'Source', '-', 'Save', 'NewPage', 'ExportPdf', 'Preview', 'Print', '-', 'Templates' ] },
    //   { name: 'clipboard', items: [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
    //   { name: 'editing', items: [ 'Find', 'Replace', '-', 'SelectAll', '-', 'Scayt' ] },
    //   { name: 'forms', items: [ 'Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField' ] },
    //   '/',
    //   { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat' ] },
    //   { name: 'paragraph', items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl', 'Language' ] },
    //   { name: 'links', items: [ 'Link', 'Unlink', 'Anchor' ] },
    //   { name: 'insert', items: [ 'Image', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe' ] },
    //   '/',
    //   { name: 'styles', items: [ 'Styles', 'Format', 'Font', 'FontSize' ] },
    //   { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
    // ]
  };
  changeVistaEnable = false;
  @Output() htmlChange = new EventEmitter<string>();
  @Input() html = '';
  @ViewChild('receptorEnabled') receptorEnabled: ElementRef;
  htmlConstructor = '';
  emitChangeHtml = new Subject<void>();

  constructor(private renderer: Renderer2,
              private interaccionService: InteraccionService) {
  }

  changeVista() {
     this.changeVistaEnable = !this.changeVistaEnable;
     if (this.changeVistaEnable) {
        this.renderer.setStyle(this.receptorEnabled.nativeElement, 'visibility', 'inherit');
        this.renderer.setStyle(this.receptorEnabled.nativeElement, 'height', 'auto');
        this.loadHtml();
        setTimeout(() => {
          this.emitChangeHtml.next();
        }, 300);
     } else {
        this.htmlConstructor = '';
        this.renderer.setStyle(this.receptorEnabled.nativeElement, 'visibility', 'hidden');
        this.renderer.setStyle(this.receptorEnabled.nativeElement, 'height', '0px');
     }
  }

  loadHtml(event?: any) {
    if (event !== undefined) {
      this.html = event.editor.getData();
      this.htmlChange.emit(this.html);
    }
    this.htmlConstructor = this.html;
  }

  copyLink() {
      this.interaccionService.showToast('enlace copiado');
  }
}
