import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WindowService {


  private scroll: any;
  private scroll$ = new Subject<any>();


  constructor(private platform: Platform){}

  getWindow(): any {
    return window;
  }

  getDocument(): any {
    return document;
  }

  isDesktop(): boolean {
        if (!this.platform.is('desktop')) {
            return true;
        }
        return false;
  }

  isServer(): boolean {
    // console.log('isServer() false');
    return false;
  }

  setTimeOut(time: number) {
    return new Promise(  async  (resolve) => {
          setTimeout(() => {
            resolve(true);
            return;
          }, time);
    });
  }

  goTop() {
      window.scrollTo(0,0);
      let top = document.getElementById('top') as any;
      if (top !== null) {
        top.scrollIntoView();
        top.scrollToTop()
        top = null;
      }
  }

  getTime(): any {
      return new Date();
  }

  getScrollChanges() {
    return this.scroll$.asObservable();
  }

  setScroll(event: any) {
     this.scroll = event;
     this.scroll$.next(this.scroll);
  }



}
