import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { SeoService } from '../../../services/seo.service';
import { WindowService } from '../../../services/window.service';
import { FrontendService } from '../../../services/frontend.service';
import { FirestoreService } from '../../../services/firestore.service';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigSeo } from '../../../models';
import { ConfigPage } from '../models-loadpage';
import { environment } from '../../../../environments/environment.prod';
import { AutenticationService } from '../../../services/autentication.service';
import { RolesUsers } from '../../../users/models-users';
import { Parametros } from '../../../../environments/parametros';

@Component({
  selector: 'app-loadpage',
  templateUrl: './loadpage.component.html',
  styleUrls: ['./loadpage.component.scss'],
})
export class LoadpageComponent implements OnInit, OnDestroy {

  suscriberRoute: Subscription;
  suscriberRouter: Subscription;
  suscriberEdit: Subscription;
  loadItem = false;
  subscriberItem: Subscription;
  edit = false;
  enlace = '';
  
  @Input() configPage: ConfigPage;
  @Output() data = new EventEmitter<any>();

  constructor(private activateRoute: ActivatedRoute,
              private seoService: SeoService,
              private windowService: WindowService,
              private firestoreService: FirestoreService,
              private autenticationService: AutenticationService,
              private router: Router) {

                    
              }

  ngOnInit() {  

        if (this.configPage) {
            if (this.windowService.isServer()) {
              this.loadServer();
            } else {
              this.loadClient();
            }
        }
  }

  ngOnDestroy() {
    console.log('destroy load page en -> ', this.configPage.path);
    
    // if (this.subscriberItem) {
    //   this.subscriberItem.unsubscribe();
    // }
    // if (this.suscriberEdit) {
    //   this.suscriberEdit.unsubscribe();
    // }
    // if (this.suscriberRouter) {
    //   this.suscriberRouter.unsubscribe();
    // }
    // if (this.suscriberRoute) {
    //   this.suscriberRoute.unsubscribe();
    // }
  }

  loadClient() {
    this.enabledEdit();
    this.suscriberRoute = this.activateRoute.paramMap.pipe(map(() => window.history.state)).subscribe( data => {
        this.loadItem = false;
        this.windowService.goTop();
        const params = this.activateRoute.snapshot.params;
        console.log('loadClient() en', this.configPage.path ,'  -> params ', params);
        if( data.item === undefined) {
            if (params.enlace !== undefined) {
              this.enlace = params.enlace;
              console.log('item loading...', params.enlace);
              this.loadArticulo(params.enlace);
            } else {
                this.suscriberRouter = this.router.events.subscribe( (event) => {
                    if (event instanceof NavigationEnd) {
                      const enlace = event.url.split('/')[1];
                      this.enlace = enlace;
                      if (this.enlace) {
                        console.log('item loading...en', this.configPage.path , this.enlace);
                        this.loadArticulo(this.enlace);
                      }
                    }
                });
                const snapshot = this.activateRoute.snapshot as any;
                if (snapshot._urlSegment.segments[0]) {
                  this.enlace = snapshot._urlSegment.segments[0].path;
                } else {
                  this.enlace = 'home';
                }
                console.log('item loading...', this.enlace);
                this.loadArticulo(this.enlace);
            }
        } else {
            console.log('item loaded!');
            this.loadItem = true;
            this.enlace = params.enlace;
            this.data.emit(data.item);
        }
    })
  }

  loadServer() {
      this.suscriberRoute = this.activateRoute.params.subscribe( params => {
            console.log('loadServer()')
            if (params.enlace !== undefined) {
              console.log('item loading...');
              this.loadArticulo(params.enlace);
            } 
      });  
  }

  loadArticulo(enlace: string) {
    const path = this.configPage.path;
    // if (this.subscriberItem) {
    //   this.subscriberItem.unsubscribe();
    // }
    this.loadItem = false;
    console.log('in loadArticulo');
    this.subscriberItem = this.firestoreService.getCollectionQuery<any>(path, 'enlacePermanente', enlace).subscribe( res => {
     
        if (res !== undefined) {
              if (res.length) {
                if (this.loadItem) {
                  return;
                }
                console.log('loadedArticulo');
                this.loadItem = true;
                const items = res;
                // console.log('this.items ->', items);
                this.data.emit(items[0])
                this.setMetaSeo(items[0]);
                return;
              }
            }
            this.data.emit(null);
    });
  }

  setMetaSeo(item: any) {
    const config: ConfigSeo = {
        title: item.nombre ? item.nombre : item.titulo,
        description: item.descripcion,
        image: item.imagenes ? item.imagenes[0].thumbnail: item.imagenDestacada,
        slug: this.configPage.slug + item.enlacePermanente,
    };
    this.seoService.generateMetaSeo(config);
  }

  enabledEdit() {
    this.suscriberEdit = this.autenticationService.authState().subscribe( res => {
      if (res) {
        res.getIdTokenResult().then( metadata => {
            const claims = metadata.claims as any;
            this.getRol(claims)
        })
      }
    });
  }

  getRol(claims: { rol : RolesUsers, user_id: string }) {
    const rolLoaded = claims.rol ? claims.rol : 'usuario';
    if (claims.user_id === Parametros.uidAdmin || rolLoaded === 'admin') {
      this.edit = true;
    } 
    console.log('getRol loadpage -> ', rolLoaded);
  }

}
