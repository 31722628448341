import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MultimediaRoutingModule } from './multimedia-routing.module';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { AngularModule } from '../angular.module';
import { PipesModule } from '../pipes/pipes.module';
import { MultimediaComponent } from './multimedia/multimedia.component';


@NgModule({
  declarations: [
    MultimediaComponent
  ],
  imports: [
    CommonModule,
    MultimediaRoutingModule,
    IonicModule,
    FormsModule,
    AngularModule,
    PipesModule
  ]
})
export class MultimediaModule { }
