import { Component, Input, OnInit } from '@angular/core';
import { PopoverController, Platform } from '@ionic/angular';
import { InteraccionService } from '../../../services/interaccion.service';
import { environment } from '../../../../environments/environment.prod';
import { WindowService } from '../../../services/window.service';
import { Plugins } from '@capacitor/core';
const { Share } = Plugins;

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss']
})
export class ShareComponent implements OnInit {

  @Input() enlace: string;

  nameSite = environment.domain;

  shareOptions: ShareOption[] = [
    {
      descripcion: 'compartir con whatsapp',
      iconName: 'logo-whatsapp',
      iconColor: '#4ac959',
      accion: () => {
        this.shareWhatsapp();
      }
    },
    {
      descripcion: 'compartir con facebook',
      iconName: 'logo-facebook',
      iconColor: '#3b5998',
      accion: () => {
        this.shareFacebook();
      }
    }
  ];

  constructor(public popoverController: PopoverController,
              private interaccionService: InteraccionService,
              private windowService: WindowService,
              private platform: Platform) {

  }

  ngOnInit(): void {
    if (!this.platform.is('desktop')) {
      this.compartir();
    }
  }

  async compartir() {
    // await this.interaccionService.presentLoading();
    Share.share({
      // title: this.entrada.metaTitulo,
      // text: this.entrada.metaDescripcion,
      url: environment.domain + '/' + this.enlace,
      dialogTitle: 'Compartir con amigos'
    }).then( _ => {
        this.interaccionService.dismissLoading();
        this.popoverController.dismiss();
    }).catch( _ => {
        // this.interaccionService.showToast('lo sentimos, no se puede compartir');
        this.interaccionService.dismissLoading();
        // this.popoverController.dismiss();
    });
    this.popoverController.dismiss();
  }

  copyEnlace() {
    this.interaccionService.showToast('enlace copiado');
    this.popoverController.dismiss();
  }

  shareWhatsapp() {
    const wd = this.windowService.getWindow();
    const enlace = 'https://api.whatsapp.com/send?text=' + this.nameSite + '/' + this.enlace;
    wd.open(enlace);
    this.popoverController.dismiss();
  }

  shareFacebook() {
    const wd = this.windowService.getWindow();
    const enlace = 'https://www.facebook.com/sharer/sharer.php?u=' + this.nameSite + '/' + this.enlace + '&amp;src=sdkpreparse';
    wd.open(enlace);
    this.popoverController.dismiss();
  }

}

interface ShareOption {
  descripcion: string;
  iconName: string;
  accion: any;
  iconColor: string;
}
