import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';


const routes: Routes = [

  // login / conocenos
  { path: 'pages', loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)},

  // module site
  { path: 'page/set', loadChildren: () => import('./site/backend/backendsite.module').then(m => m.BackendsiteModule)},
  { path: 'page/:enlace', loadChildren: () => import('./site/site.module').then(m => m.SiteModule)},
  // { path: '', loadChildren: () => import('./site/site.module').then(m => m.SiteModule)},

   // module multimedia
   { path: 'multimedia', loadChildren: () => import('./multimedia/multimedia.module').then(m => m.MultimediaModule)},

  // module chat 
  { path: 'chats', loadChildren: () => import('./chat/chat.module').then(m => m.ChatModule)},
  { path: 'chats/:chatId', loadChildren: () => import('./chat/chat.module').then(m => m.ChatModule)},
  { path: 'chats/:chatId/:sendwpp', loadChildren: () => import('./chat/chat.module').then(m => m.ChatModule)},

  // module travel
  { path: '', loadChildren: () => import('./travel/travel.module').then(m => m.TravelModule)},
  { path: 'travel', loadChildren: () => import('./travel/travel.module').then(m => m.TravelModule)},
  
  // module servicios
  { path: 'servicios', loadChildren: () => import('./servicios/servicios.module').then(m => m.ServiciosModule)},
  

  //module usuarios
  { path:'user', loadChildren:()=>import('./users/usuarios.module').then(m=>m.UsuariosModule)},

  //module common ajustes
  { path:'ajustes', loadChildren:()=>import('./common/backend/backendcommon.module').then(m=>m.BackendcommonModule)},
  { path:'ajustes/:opcion', loadChildren:()=>import('./common/backend/backendcommon.module').then(m=>m.BackendcommonModule)},
  { path:'ajustes/:opcion/:enlace', loadChildren:()=>import('./common/backend/backendcommon.module').then(m=>m.BackendcommonModule)},
 
  // contact forms
  { path:'contactos', loadChildren:()=>import('./widgets/contactform/contactform.module').then(m=>m.ContactformModule)},
 
  // comentarios
  { path: 'comentarios/gestor', loadChildren: () => import('./widgets/comentarios/gestor/gestor-comentarios.module').then( m => m.GestorComentariosPageModule) },
  
  // module post
  { path: 'posts', loadChildren: () => import('./post/post.module').then(m => m.PostModule)},
 
  //module chatbot
  { path:'chatbot', loadChildren:()=>import('./chatbot/backend/backendchat.module').then(m=>m.BackendchatModule)},
  { path:'chatbot/:opcion', loadChildren:()=>import('./chatbot/backend/backendchat.module').then(m=>m.BackendchatModule)},
  { path:'chatbot/:opcion/:enlace', loadChildren:()=>import('./chatbot/backend/backendchat.module').then(m=>m.BackendchatModule)},


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
    // RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, initialNavigation: 'enabled'})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
