import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { AutenticationService } from '../../../services/autentication.service';
import { NotificationsService } from '../../services/notifications.service';

@Component({
  selector: 'app-suscripcion',
  templateUrl: './suscripcion.component.html',
  styleUrls: ['./suscripcion.component.scss']
})
export class SuscripcionComponent implements OnInit {

  @Input() data: SuscripcionType = {
    size: 'small',
    container: false,
  };
  nombre = '';
  email = '';
  mensaje = '';
  suscrito = false;
  suscripcion: Subscription;

  constructor(private autenticationService: AutenticationService,
              private popoverController: PopoverController,
              private messagingService: NotificationsService) {
  }

  ngOnInit(): void {
      if (this.data.size !== 'registrarse') {
        this.getSuscripcion();
        if (this.suscripcion) {
          this.suscripcion.unsubscribe();
        }
        this.suscripcion = this.autenticationService.getUidChanges().subscribe( res => {
            this.getSuscripcion();
        });
      }
  }

  async suscribirse() {
    const suscrito = await this.autenticationService.registrarUser(this.email, this.nombre);
    if (suscrito) { 
      this.requestNotificaciones(); 
      if (this.data.size === 'registrarse') {
        this.popoverController.dismiss(true);
      }
    } else {
      if (this.data.size === 'registrarse') {
        this.popoverController.dismiss(false);
      }
    }

  }

  async getSuscripcion() {
      const is = await this.autenticationService.isUserSuscrito();
      if (is) {
        this.autenticationService.getSuscripcion().then( res => {
          // console.log(' suscripcion si -> ', res );
          this.suscrito = true;
          this.nombre = res.nombre;
          this.email = res.email;
         });
      } else {
          this.suscrito = false;
          this.nombre = '';
          this.email = '';
      }
  }

  requestNotificaciones() {
    this.messagingService.requestPermissionPWA();
  }

}

interface SuscripcionType {
   size: FormatSuscripcion;
   container: boolean;
}
type FormatSuscripcion = 'big' | 'small' | 'registrarse' | 'contactarse';
