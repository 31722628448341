import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChatbotRoutingModule } from './chatbot-routing.module';
import { ChatbubbleComponent } from './componentes/chatbubble/chatbubble.component';
import { IonicModule } from '@ionic/angular';
import { ChatComponent } from './componentes/chat/chat.component';
import { ChatModule } from '../chat/chat.module';
import { ChatpopComponent } from './componentes/chatpop/chatpop.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { PipesModule } from '../pipes/pipes.module';
import { PopsaludochatComponent } from './componentes/popsaludochat/popsaludochat.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import { CrudfastModule } from '../widgets/crudfast/crudfast.module';
import { ComponentesModule } from '../common/componentes/componentes.module';

@NgModule({
  declarations: [
    ChatbubbleComponent,
    ChatComponent,
    ChatpopComponent,
    PopsaludochatComponent
  ],
  imports: [
    CommonModule,
    ChatbotRoutingModule,
    IonicModule,
    ChatModule,
    FormsModule,
    ClipboardModule,
    PipesModule,
    MatTooltipModule,
    ReactiveFormsModule,
    CrudfastModule,
    ComponentesModule
  ], 
  exports: [
    ChatbubbleComponent
  ]
})
export class ChatbotModule { }
