import { Injectable } from '@angular/core';
import { AutenticationService } from '../../services/autentication.service';
import { Subject, Subscription, Observable } from 'rxjs';
import { Like, View } from '../../models';
import { environment } from '../../../environments/environment.prod';
import { FirestoreService } from '../../services/firestore.service';
import { EntradasService } from './entradas.service';
import { WindowService } from '../../services/window.service';
import { CategoriaItem, CategoriasItem } from '../../widgets/crudfast/models-crud';
import { RecommenderItems, Entrada } from '../models-post';
import { CrudCategoriasBlog, CrudEntradasBlog } from '../backend/models-post-crud';

@Injectable({
  providedIn: 'root'
})
export class RecommenderService {

  uid: string;
  suscriberUid: Subscription;
  loadRecommenderUid = false;
  private recommenderItem: RecommenderItems[] = [];
  recommenderItem$ = new Subject<RecommenderItems[]>();

  private categorias: CategoriasItem = {
    categorias: []
  };
  categoriasShow: CategoriaItem[] = [];
  categoriasShow$ = new Subject<CategoriaItem[]>();
  numberCategorias = 1;
  moreEnableCategorias = true;

  constructor(private autenticationService: AutenticationService,
              private firestoreService: FirestoreService,
              private entradasService: EntradasService,
              private windowService: WindowService) {
        console.log('constructor recommeder services');
        this.setSecciones();
  }

  setSecciones() {
      this.pushSeccion('Más recientes', 2);
      this.pushSeccion('Más populares', 3);
      if (!this.windowService.isServer()){
        this.initUid();
      }
      this.loadRecomendadosGeneral();
      this.loadCategorias();
  }

  pushSeccion(seccion: seccionRemmender, priority: number) {
    let exist = false;
    this.recommenderItem.every( recommender => {
      if ( recommender.seccion === seccion) {
        recommender.entradas = [];
        recommender.priority = priority,
        exist = true;
        return false;
      }
      return true;
    });
    if (!exist) {
      const items: RecommenderItems = {
        seccion,
        entradas: [],
        priority,
      };
      this.recommenderItem.push(items);
    }
  }

  pushEntradasLoadInSeccion(seccion: seccionRemmender, entradas: Entrada[]) {
    this.recommenderItem.every( recommender => {
        if ( recommender.seccion === seccion) {
          recommender.entradas = entradas;
          this.entradasService.putEntradas(entradas);
          return false;
        }
        return true;
    });
    // console.log('emito recommenderChanges en pushEntradasLoadInSeccion()');
    this.recommenderItem$.next(this.recommenderItem);
  }

  async initUid() {
      this.uid = await this.autenticationService.getUid();
      if (this.uid !== undefined) {
        if (this.uid.length) {
          this.loadRecomendadosUid();
        }
      }
      this.suscriberUid = this.autenticationService.getUidChanges().subscribe( res => {
        this.uid = res;
        if (this.uid !== undefined) {
          if (this.uid.length) {
            this.loadRecomendadosUid();
          }
        }
      });
  }

  loadRecomendadosUid() {
    if (!this.loadRecommenderUid) {
      console.log('cargando recomendados para -> ', this.uid);
      // this.loadRecientes();
      // this.loadPopulares();
      this.loadTeGusta();
      this.loadViews();
    }
    this.loadRecommenderUid = true;
  }

  loadRecomendadosGeneral() {
      this.loadRecientes();
      this.loadPopulares();
  }

  getRecomendadosChanges(): Observable<RecommenderItems[]> {
    this.recommenderItem$.next(this.recommenderItem);
    return this.recommenderItem$.asObservable();
  }

  getRecomendados(): RecommenderItems[] {
    return this.recommenderItem;
  }

  loadRecientes() {
      const path = CrudEntradasBlog.config.path;
      this.firestoreService.getCollectionOrderLimit<Entrada>(path, 5, 'fecha', 'desc', null).subscribe( res => {
        this.pushEntradasLoadInSeccion('Más recientes', res);
      });
  }

  loadPopulares() {
      const path = CrudEntradasBlog.config.path;
      this.firestoreService.getCollectionOrderLimit<Entrada>(path, 5, 'views', 'desc', null).subscribe( res => {
          this.pushEntradasLoadInSeccion('Más populares' , res);
      });
  }

  loadTeGusta() {
    let path = environment.pathRoot + this.uid + environment.pathLikes;
    const seccion: seccionRemmender = 'Te gustaron';
    this.pushSeccion(seccion, 1);
    this.firestoreService.getCollectionQueryOrderLimit<Like>(path, 'like', true, 4, 'fecha', 'desc', null).subscribe( res => {
      // this.pushEntradasLoadInSeccion('Más recientes', res);
      if (res.length) {
          //  console.log('los que me gustan -> ', res);
           this.deleteEntradasSeccion(seccion);
           res.forEach( like => {
              if (like.articulo === undefined) { return;}
              let entradaLoad = this.entradasService.getEntrada(like.articulo.id);
              if (entradaLoad !== null) {
                this.putEntradaRecommender(entradaLoad, seccion);
              } else {
                path = CrudEntradasBlog.config.path + like.articulo.id;
                // console.log('get entrada of firestore');
                this.firestoreService.getDocument(path).then ( entrada => {
                  // console.log('recomender loa entrada', entrada)
                  if (entrada.data() !== undefined) {
                    entradaLoad = entrada.data() as Entrada;
                    this.entradasService.putEntrada(entradaLoad);
                    this.putEntradaRecommender(entradaLoad, seccion);
                  }
                });
              }
           });
       }
    });
  }

  loadViews() {
    let path = environment.pathRoot + this.uid + environment.pathViews;
    const seccion: seccionRemmender = 'Vistos recientemente';
    this.pushSeccion(seccion, 1);
    this.firestoreService.getCollectionOrderLimit<View>(path, 4, 'fecha', 'desc', null).subscribe( res => {
      // this.pushEntradasLoadInSeccion('Más recientes', res);
      if (res.length) {
          //  console.log('los que -> he visto ', res);
           this.deleteEntradasSeccion(seccion);
           res.forEach( view => {
              if (view.articulo === undefined) { return;}
              let entradaLoad = this.entradasService.getEntrada(view.articulo.id);
              if (entradaLoad !== null) {
                this.putEntradaRecommender(entradaLoad, seccion);
              } else {
                path = CrudEntradasBlog.config.path + view.articulo.id;
                // console.log('get entrada of firestore');
                this.firestoreService.getDocument(path).then ( entrada => {
                    if (entrada.data() !== undefined) {
                      entradaLoad = entrada.data() as Entrada;
                      this.entradasService.putEntrada(entradaLoad);
                      this.putEntradaRecommender(entradaLoad, seccion);
                    }
                });
              }
           });
       }
    });
  }

  loadRelacionados(entrada: Entrada) {
      if (entrada.vecinos !== undefined) {
          if (entrada.vecinos.length) {
            const seccion: seccionRemmender = 'Entradas similares';
            this.pushSeccion(seccion, 0);
            entrada.vecinos.forEach( idVecino => {
                  const path = CrudEntradasBlog.config.path + idVecino;
                  this.firestoreService.getDocument(path).then ( vecino => {
                    const vecinoLoad = vecino.data() as Entrada;
                    this.recommenderItem.every( recommender => {
                      if ( recommender.seccion === seccion) {
                        recommender.entradas.push(vecinoLoad);
                        return false;
                      }
                      return true;
                    });
                    // console.log('emito recommenderChanges en loadRelacionados()');
                    this.recommenderItem$.next(this.recommenderItem);
                  });
            });
          }
      }
  }

  putEntradaRecommender(entrada: Entrada, seccion: seccionRemmender) {
    this.recommenderItem.every( recommender => {
      if ( recommender.seccion === seccion) {
        let exist = false;
        let index = 0;
        recommender.entradas.every( (entradaExist, indexExist) => {
          if (entradaExist.id === entrada.id) {
            exist = true;
            index = indexExist;
            return false;
          }
          return true;
        });
        if (!exist) {
          recommender.entradas.push(entrada);
        } else {
          recommender.entradas[index] = entrada;
        }
        return false;
      }
      return true;
    });
    // console.log('emito recommenderChanges en putEntradaRecommender()');
    this.recommenderItem$.next(this.recommenderItem);
  }

  deleteEntradasSeccion(seccion: seccionRemmender) {
    this.recommenderItem.every( (recommender) => {
      if ( recommender.seccion === seccion) {
        recommender.entradas = [];
        return false;
      }
      return true;
    });
  }

  deleteRelacionados() {
    const seccion: seccionRemmender = 'Entradas similares';
    this.recommenderItem.every( (recommender, index) => {
      if ( recommender.seccion === seccion) {
        this.recommenderItem.splice(index, 1);
        return false;
      }
      return true;
    });
    // console.log('emito recommenderChanges en deleteRelacionados()');
    this.recommenderItem$.next(this.recommenderItem);
  }

  loadItemsBySeccion(categoria: CategoriaItem, items: Entrada[], orderCampo: string, directionSort: "asc" | "desc") {

    return new Promise((resolve, reject) => {
        const path = CrudEntradasBlog.config.path;
        let STARTAT = null;
        if (items.length) {
            STARTAT = items[items.length - 1][orderCampo]
        }
        const busqueda = [{id: categoria.id, nombre: categoria.nombre}];
        this.firestoreService.getCollectionQueryArray<Entrada>(path, 'categoria', busqueda,  1, orderCampo, directionSort, STARTAT).subscribe( res => {
            if (res !== undefined) {
                  console.log('loadItemsBySeccion -> ', res);
                  res.forEach( itemLoad => {
                        let exist = false;
                        items.every( (itemExist, index) => {
                                if (itemExist.id === itemLoad.id) {
                                  exist = true;
                                  items[index] = itemLoad;
                                  return false;
                                }
                                return true;
                        });
                        if (!exist) {
                          items.push(itemLoad);
                        }
                  });
                  console.log('leerMoreItemsByCategoria() -> ', items);
                  if (items.length) {
                    resolve(true);
                    return;
                  } else {
                    resolve(false);
                    return;
                  }
            }
            resolve(false);
            return;
        });   
    });   
  }

  loadCategorias() {
    const path = CrudCategoriasBlog.config.path + CrudCategoriasBlog.config.id;
    this.firestoreService.getDocument<CategoriasItem>(path).then( res => {
        if (res.exists) {
            this.categorias = res.data();
            this.moreCategorias();
        }
    });
  }

  moreCategorias() {
    const categoriasExist = this.categorias.categorias;
    for (let n = 0; n < this.numberCategorias; n++) {
            const index = this.categoriasShow.length;
            if (categoriasExist.length > index) {
               this.categoriasShow.push(this.categorias.categorias[index]);
            } else {
              this.moreEnableCategorias= false;
            }
    }
    this.categoriasShow$.next(this.categoriasShow);
  }

  getCategoriasChanges(): Observable<CategoriaItem[]> {
    this.categoriasShow$.next(this.categoriasShow);
    return this.categoriasShow$.asObservable();
  }

  


}


type seccionRemmender = 'Más populares' | 'Más recientes' | 'Entradas similares'
                        | 'Te gustaron' | 'Vistos recientemente';


 // loadViews() {
                        //   let path = environment.pathRoot + this.uid + environment.pathViews;
                        //   const seccion: seccionRemmender = 'Vistos recientemente';
                        //   this.pushSeccion(seccion, 0);
                        //   this.firestoreService.getCollectionOrderLimit<View>(path, 4, 'fecha', 'desc', null).subscribe( res => {
                        //     // this.pushEntradasLoadInSeccion('Más recientes', res);
                        //     if (res.length) {
                        //         this.recommenderItem.every( recommender => {
                        //             if ( recommender.seccion === seccion) {
                        //               res.forEach( view => {
                        //                     let exist = false;
                        //                     recommender.entradas.every( viewExist => {
                        //                         if (viewExist.)
                        //                     });
                      
                      
                        //               });
                    
                        //               return false;
                        //             }
                        //             return true;
                        //         });
                        //         console.log('los que -> he visto ', res);
                        //         res.forEach( view => {
                        //             path = CrudEntradasBlog.config.path + view.entrada.id;
                        //             this.firestoreService.getDocument(path).then ( entrada => {
                        //               const entradaLoad = entrada.data() as Entrada;
                        //                 // this.recommenderItem.every( recommender => {
                        //                 // if ( recommender.seccion === seccion) {
                        //                 //   recommender.entradas.push(entradaLoad);
                        //                 //   this.recommenderItem$.next(this.recommenderItem);
                        //                 //   return false;
                        //                 // }
                        //                 // return true;
                        //               });
                        //             });
                        //          });
                        //      }
                        //   });
                        // }
                      