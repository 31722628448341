import { Parametros } from './parametros';
const uidAdmin = Parametros.uidAdmin;

export const environment = {
  production: false,
  firebaseConfig: Parametros.firebaseConfig,
  domain: Parametros.domain,
  siteName: Parametros.siteName,
  uidAdmin: uidAdmin,
  apiKeyMaps: Parametros.firebaseConfig.apiKey,
  version: Parametros.version,
  pathUsuarios: 'Usuarios',
  pathRoot: 'Usuarios/',
  pathLikes: '/likes/',
  pathViews: '/views/',
  pathComments: '/comments/',
  pathRespuestas: '/respuestas/',
  pathNotificaciones: 'Usuarios/' + uidAdmin + '/notificaciones/',
  nameCarpetaNotificaciones: 'Notificaciones',
  pathCorreos: 'Usuarios/' + uidAdmin + '/correos/',
  nameCarpetaMultimedia: 'Entradas',
  enableWhatsapp: Parametros.enableWhatsapp,
};