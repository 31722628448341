import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { CategoriaItem } from '../../models-crud';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-categorias-crud',
  templateUrl: './categorias.component.html',
  styleUrls: ['./categorias.component.scss']
})
export class CategoriasComponent implements OnInit {

  @Input() categorias: any[] = []
  @Input() popover = false;
  @Output() selecCategoria = new EventEmitter<CategoriaItem[]>()

  subscriber: Subscription;

  constructor(public popoverController: PopoverController) {
   }

  ngOnInit(): void {
  }

  selec(event: CategoriaItem[], categoria: CategoriaItem) {
    event.push(categoria);
    if (this.popover) {
      this.popoverController.dismiss(event);
    } else {
      this.selecCategoria.emit(event);
    }

  }

}
