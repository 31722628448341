import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { SearchDoc, ConfigSearchCrud } from '../../models-crud';
import { FirestoreService } from '../../../../services/firestore.service';
import { InteraccionService } from '../../../../services/interaccion.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {

  @Input () save: Observable<any> ;
  @Input () delete: Observable<void> ;
  @Input () config: ConfigSearchCrud;
  @Output() clonar = new EventEmitter();
  
  searchDocs: SearchDoc[] = [];

  subscriber: Subscription;
  
  constructor(private firestoreService: FirestoreService,
              private interaccionService: InteraccionService) { }

  ngOnInit(): void {
       this.save.subscribe( doc => {
            this.saveInSearch(doc);
       });
       this.loadDocSearch();
  }

  ngOnDestroy(): void {
    if (this.subscriber) {
        this.subscriber.unsubscribe();
    } 
  }

  searchEvent(ev: any) {
    const search = ev.detail.value as string;
    this.searchDocs.forEach( doc => {
          const show =  doc.nombre.toLowerCase().search(search.toLowerCase()) > -1;
          doc.show = show ? true: false;
    });

  }

  saveInSearch(item: SearchDoc) {
    const path = this.config.searchPath ? this.config.searchPath: this.config.path;  
    const id = 'search';
    let ItemtoSave = {} as SearchDoc;
    if (item.descripcion) {
       ItemtoSave.descripcion = item.descripcion;
    }
    if (item.nombre) {
      ItemtoSave.nombre = item.nombre;
    }
    if (item.enlacePermanente) {
      ItemtoSave.enlacePermanente = item.enlacePermanente;
    }
    // if (item.type) {

    // }
    ItemtoSave.id = item.id;
    const updateDoc = {} as any;
    updateDoc[ItemtoSave.id] = ItemtoSave;
    // console.log('saveInSearch -', updateDoc)
    this.firestoreService.updateDocumentID(updateDoc, path, id).catch( () => {
      this.firestoreService.createDocumentID(updateDoc, path, id)
    });
  }

  deleteInSearch(item: SearchDoc) {
    const path = this.config.searchPath ? this.config.searchPath: this.config.path;  
    const id = 'search';
    const updateDoc = {} as any;
    updateDoc[item.id] = null;
    this.firestoreService.updateDocumentID(updateDoc, path, id).catch( () => {
      this.firestoreService.createDocumentID(updateDoc, path, id)
    });
  }

  loadDocSearch() {
      const path = this.config.searchPath ? this.config.searchPath: this.config.path;
      const id = 'search';
      this.subscriber = this.firestoreService.getDocumentChanges<any>(path + id).subscribe( res => {
            if (res) {
              this.searchDocs = [];
              for(const docId in res) {
                 if (res[docId] !== null) {
                   res[docId].show = true;
                   this.searchDocs.push(res[docId])
                 }
              }
              this.searchDocs.sort( (n1, n2) => {
                let is = n1.nombre > n2.nombre ? 1 : n1.nombre < n2.nombre ? -1 : 0 
                return is;
              });
            }
            // console.log('loadDocSearch() -> ', this.searchDocs);
      });
  }

  removeItem(i: number) {

    this.interaccionService.preguntaAlert('Eliminar', 'Seguro que desea eliminar este item?').then( res => {
      if (res) {
          this.interaccionService.presentLoading();
          const path = this.config.path;
          this.firestoreService.deleteDocumentID(path, this.searchDocs[i].id).then( _ => {
            this.interaccionService.showToast('Eliminado con éxito');
            this.deleteInSearch(this.searchDocs[i]);
            this.interaccionService.dismissLoading();
          }).catch( error => {
                this.interaccionService.showToast('No se pudo eliminar');
                console.log('error -> ', error);
                this.interaccionService.dismissLoading();
          });
      }
    });
  }

}

