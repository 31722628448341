import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MultimediaComponent } from './multimedia/multimedia.component';
import { canActivate } from '@angular/fire/compat/auth-guard';
import { isAdminOrEditor } from '../users/models-users';


const routes: Routes = [
    { path: '', component: MultimediaComponent, ...canActivate(isAdminOrEditor)},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MultimediaRoutingModule { }
