import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { RecommenderService } from '../../services/recommender.service';
import { Entrada, RecommenderItems } from '../../models-post';
import { CategoriaItem } from 'src/app/widgets/crudfast/models-crud';
import { IonInfiniteScroll } from '@ionic/angular';

@Component({
  selector: 'app-recommender',
  templateUrl: './recommender.component.html',
  styleUrls: ['./recommender.component.scss']
})
export class RecommenderComponent implements OnInit, OnDestroy {

  @Input() entrada: Entrada;
  suscriberRecommender: Subscription;
  recommenderItem: RecommenderItems[] = [];
  recommenderItemShow: RecommenderItems[] = [];
  @Input() container = true;

  categoriasShow: CategoriaItem[] = [];
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  constructor(private recommenderService: RecommenderService) {
                this.initRecommender();
   }

  ngOnDestroy() {
      if (this.suscriberRecommender) {
          this.suscriberRecommender.unsubscribe();
      }
  }

  async ngOnInit() {
  }

  initRecommender() {
    this.recommenderItem = JSON.parse(JSON.stringify(this.recommenderService.getRecomendados()));
    this.removeSameEntrada();
    this.orderByPriority();
    this.showRecommenderItems();
    if (this.suscriberRecommender) {
      this.suscriberRecommender.unsubscribe();
    }
    this.suscriberRecommender = this.recommenderService.getRecomendadosChanges().subscribe( res => {
          // console.log('getRecomendadosChanges()');
          this.recommenderItem = JSON.parse(JSON.stringify(res));
          this.removeSameEntrada();
          this.orderByPriority();
          this.showRecommenderItems();
    });
    if (this.entrada !== undefined) {
      this.recommenderService.loadRelacionados(this.entrada);
    } else {
      this.recommenderService.deleteRelacionados();
    }
    this.getCategoriasShow();
  }

  removeSameEntrada() {
      // console.log('remove same entrada -> ', this.entrada.titulo);
      if (this.entrada !== undefined) {
          this.recommenderItem.forEach( seccionRecommender => {
                seccionRecommender.entradas.every( (entrada, index) => {
                      if (entrada.id === this.entrada.id) {
                        seccionRecommender.entradas.splice(index, 1);
                        return false;
                      }
                      return true;
                });
          });
      }
  }

  orderByPriority() {
    this.recommenderItem.sort((n1, n2) => {
      if (n1.priority > n2.priority) {
          return 1;
      }
      if (n1.priority < n2.priority) {
          return -1;
      }
      return 0;
    });
  }

  async showRecommenderItems() {
     this.recommenderItemShow = this.recommenderItem;
  }


  getCategoriasShow() {
      this.categoriasShow = this.recommenderService.categoriasShow;
      this.recommenderService.getCategoriasChanges().subscribe( res => {
          this.categoriasShow = res;
      });
  }

  loadData() {
    console.log('loadData');
    this.infiniteScroll.complete();
    this.recommenderService.moreCategorias();
  }

}

