import { Injectable } from '@angular/core';
import { ChatType, UserChat, Chat } from '../models-chat';
import { FirestoreService } from './firestore.service';
import { Subject, Subscription } from 'rxjs';
import { WindowService } from '../../services/window.service';
import { AutenticationService } from '../../services/autentication.service';
// import { Pedido } from '../../store/models-store';
import { environment } from '../../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  private chats: Chat[] = [];
  private chats$ = new Subject<Chat[]>();
  private chatsMulti: Chat[] = [];
  private chatsMulti$ = new Subject<Chat[]>();

  uid: string;
  suscriberUid: Subscription;
  timerUltimaConexion: any;
  timerSetEntregado: any;

  suscriberLeerMoreChatsMulti: Subscription;

  constructor(private firestoreService: FirestoreService,
              private windowService: WindowService,
              private autenticationService: AutenticationService) { }

  initChats() {

    //   this.autenticationService.authState().subscribe( res => {
    //     if (res) {
            this.uid = environment.uidAdmin
            this.clearChats();
            this.leerMoreChats();
            this.clearTimerUltimaConexion();
            this.initUltimaConexion();
    //     }
    //   })

  }

  sendChat(mensaje: any, chatType: ChatType, receptor: UserChat, emisor: UserChat): Promise<string> {
        return new Promise( async (resolve) => {
            let path = 'Usuarios/' + emisor.uid + '/chat/' + receptor.uid + '/chats';
            const chat: Chat = {
              id: this.firestoreService.createIdDoc(),
              emisor,
              receptor,
              chatType,
              mensaje,
              chatState: 'enviando', 
              resend: false,
              time: new Date(),
              chatId: '',
              timevalue: ''
            }
            chat.chatId = chat.id;
            if (chatType == 'reserva') {
                chat.noReserva = chat.id.slice(chat.id.length - 5, chat.id.length);
            }
            // console.log('chat -> ',  chat);
            // await  this.firestoreService.createDocumentID(chat, path, chat.id);
            await this.firestoreService.createDocumentID(chat, path, chat.id);
            path = 'Usuarios/' + emisor.uid + '/chat/';
            // await  this.firestoreService.createDocumentID(chat, path, receptor.uid);
            chat.id = receptor.uid;
            await this.firestoreService.createDocumentID(chat, path, receptor.uid);
            // console.log(' send pedido -> ', path, chat);
            resolve(chat.chatId);
            return;
      });
  }

  clearChats() {
     this.chats = [];
  }

  getChatsChanges() {
    this.windowService.setTimeOut(100).then( () => {
        this.chats$.next(this.chats);
    });
    return this.chats$.asObservable();
  }

  leerMoreChats() {
      const path = 'Usuarios/' + this.uid + '/chat/';
      let STARTAT = null;
      if (this.chats.length) {
          STARTAT = this.chats[this.chats.length - 1].time
      }
      this.firestoreService.getCollectionOrderLimit<Chat>(path, 6, 'time', 'desc', STARTAT).subscribe( res => {
          if (res !== undefined) {
                res.forEach( chatLoad => {
                      let exist = false;
                      this.chats.every( (chatExist, index) => {
                              if (chatExist.id === chatLoad.id) {
                                exist = true;
                                this.chats[index] = chatLoad;
                                return false;
                              }
                              return true;
                      });
                      if (!exist) {
                          this.chats.push(chatLoad);
                      }
                });
                // console.log('leerMoreChats() -> emit ->', this.chats);
                this.setEntregadoChat(this.chats)
                this.chats$.next(this.chats);
            }
      });
  }

  leerMoreChatsMulti(chatId: string) {
      // let path = 'Usuarios/' + emisor.uid + '/chat/' + receptor.uid + '/chats';  
      const path = 'Usuarios/' + this.uid + '/chat/' + chatId + '/chats'; ;
      let STARTAT = null;
      if (this.chatsMulti.length) {
          STARTAT = this.chatsMulti[0].time
      }
      if (this.suscriberLeerMoreChatsMulti) {
         this.suscriberLeerMoreChatsMulti.unsubscribe();
      }
      this.suscriberLeerMoreChatsMulti = this.firestoreService.getCollectionOrderLimit<Chat>(path, 6, 'time', 'desc', STARTAT).subscribe( res => {
          if (res !== undefined) {
                // console.log('services chat load -> ', res);
                res.forEach( chatLoad => {
                      this.setVistoChat(chatLoad);
                      let exist = false;
                      this.chatsMulti.every( (chatExist, index) => {
                              if (chatExist.id === chatLoad.id) {
                                exist = true;
                                this.chatsMulti[index] = chatLoad;
                                return false;
                              }
                              return true;
                      });
                      if (!exist) {
                          this.chatsMulti.push(chatLoad);
                      }
                });
                this.chatsMulti$.next(this.chatsMulti);
            }
      });
  }

  getChatsMultiChanges() {
    this.windowService.setTimeOut(100).then( () => {
        this.chatsMulti$.next(this.chatsMulti);
    });
    return this.chatsMulti$.asObservable();
  }

  clearChatsMulti() {
    this.chatsMulti = [];
  }

  setVistoChat(chat: Chat) {
     if (chat.resend && chat.chatState !== 'visto') {
        // console.log('setVistoChat -> ', chat);
        const updateDoc = {
            chatState: 'visto'
        }
        let path = 'Usuarios/' + chat.receptor.uid + '/chat/' + chat.emisor.uid + '/chats';
        this.firestoreService.updateDocumentID(updateDoc, path, chat.id);
        path = 'Usuarios/' + chat.receptor.uid + '/chat/';
        this.firestoreService.updateDocumentID(updateDoc, path, chat.emisor.uid);
        path = 'Usuarios/' + chat.emisor.uid + '/chat/' + chat.receptor.uid + '/chats';
        this.firestoreService.updateDocumentID(updateDoc, path, chat.id);
        path = 'Usuarios/' + chat.emisor.uid + '/chat/';
        this.firestoreService.updateDocumentID(updateDoc, path, chat.receptor.uid);
     }
  }

  setEntregadoChat(chats: Chat[]) {

    if (this.timerSetEntregado) {
        clearTimeout(this.timerSetEntregado);
    }
    this.timerSetEntregado = setTimeout(() => {

        chats.forEach( chat => {
            if (chat.resend && (chat.chatState !== 'visto' && chat.chatState !== 'entregado') ) {
               // console.log('setEntregadoChat -> ', chat);
               const updateDoc = {
                   chatState: 'entregado'
               }
               let path = 'Usuarios/' + chat.receptor.uid + '/chat/' + chat.emisor.uid + '/chats';
               this.firestoreService.updateDocumentID(updateDoc, path, chat.chatId);
               path = 'Usuarios/' + chat.receptor.uid + '/chat/';
               this.firestoreService.updateDocumentID(updateDoc, path, chat.emisor.uid);
               path = 'Usuarios/' + chat.emisor.uid + '/chat/' + chat.receptor.uid + '/chats';
               this.firestoreService.updateDocumentID(updateDoc, path, chat.chatId);
               path = 'Usuarios/' + chat.emisor.uid + '/chat/';
               this.firestoreService.updateDocumentID(updateDoc, path, chat.receptor.uid);
            }
        });
    }, 1500);

 }

  initUltimaConexion() {
     this.setUltimaConexion();
     this.timerUltimaConexion = setTimeout(() => {
        this.initUltimaConexion();
     }, 60000);
  }

  setUltimaConexion() {
        const path = 'Usuarios/'
        const updateDoc = {
            lastConexion: this.windowService.getTime()
        }
        this.firestoreService.updateDocumentID(updateDoc, path, this.uid).catch( () => {
            this.firestoreService.createDocumentID(updateDoc, path, this.uid)
        })
        console.log('setUltimaConexion()');
  }


  clearTimerUltimaConexion() {
      if (this.timerUltimaConexion) {
          clearTimeout(this.timerUltimaConexion); 
      }
  }

  async compartirPedido(chat: Chat) {

        let mensajeWpp: string;
        mensajeWpp = '*Pedido*%0A';
        mensajeWpp = mensajeWpp + this.getTextDatosPedido(chat);
        mensajeWpp = mensajeWpp + this.getTextDatosFecha(chat);
        mensajeWpp = mensajeWpp + this.getTextDatosCliente(chat);
        mensajeWpp = mensajeWpp + this.getTextDatosValues(chat); 

        const url = 'whatsapp://send?text=' + mensajeWpp;
        window.open(url);

  }

  async sendPedidoByWpp(chat: Chat) {

        let mensajeWpp: string;
        mensajeWpp = '*Pedido*%0A';
        mensajeWpp = mensajeWpp + this.getTextDatosPedido(chat);
        mensajeWpp = mensajeWpp + this.getTextDatosFecha(chat);
        mensajeWpp = mensajeWpp + this.getTextDatosCliente(chat);
        mensajeWpp = mensajeWpp + this.getTextDatosValues(chat); 
        
        const phoneNumberDestino = chat.receptor.celular;
        const url = 'https://wa.me/' + phoneNumberDestino + '?text=' + mensajeWpp;
        window.open(url);

  }

  async compartirDirection(chat: Chat) {

        const chatPedido = chat.mensaje as any; 
        let mensajeWpp: string;
        if (chatPedido.datos.city) {
            mensajeWpp = '*Datos e información de entrega* ' + '%0A';
        } else {
            mensajeWpp = '*Un motorizado por favor* ' + '%0A';
        }
        mensajeWpp = mensajeWpp + this.getTextDatosCliente(chat);
        mensajeWpp = mensajeWpp + this.getTextDatosValues(chat); 
        mensajeWpp = 'whatsapp://send?text=' + mensajeWpp;
        window.open(mensajeWpp);
  }

  getTextDatosPedido(chat: Chat): string {
        const chatPedido = chat.mensaje as any; 
        let pedido = '';
        chatPedido.carrito.items.forEach( item => {
            const plato = '*' + item.cantidad + 'x* *' + item.item.nombre + '* $' + item.precioReducido.toFixed(2) + '%0A';
            const descripcion = '_Detalle:_ ' + item.descripcion + '%0A';
            pedido = pedido + plato + descripcion;
            });
        if (chatPedido.carrito.comentario) {
        pedido = pedido + '*Comentario:* ' + chatPedido.carrito.comentario + '%0A';
        }
        return pedido;
  }

  getTextDatosCliente(chat: Chat): string {

        const chatPedido = chat.mensaje as any; 
        let pedido = '';
        const user = chatPedido.datos;
        const direction = chatPedido.direction.direccionEnvio;
        let phoneNumber = '';
        if (chatPedido.datos.celular.length > 10) {
            phoneNumber = chatPedido.datos.celular;
        } else {
          phoneNumber = '593' + chatPedido.datos.celular.slice(1, chatPedido.datos.celular.length);
        }
        if (chatPedido.datos.postalCode) {
            pedido = pedido + '*Datos del Cliente:* ' + '%0A';
            pedido = pedido + '*Nombre:* ' + user.firstName + ' ' + user.lastName + '%0A';
            pedido = pedido + '*Número:* ' + user.celular + '%0A';
            pedido = pedido + '*Email:* ' + user.email + '%0A';
            pedido = pedido + '*Dirección:* ' + user.direccion + '%0A';
            pedido = pedido + '*Estado:* ' + user.state + '%0A';
            pedido = pedido + '*Ciudad:* ' + user.city + '%0A';
            pedido = pedido + '*Código postal:* ' + user.postalCode + '%0A';     
            pedido = pedido + '*Datos de entrega:* ' + '%0A';
            pedido = pedido + '*Nombre:* ' + direction.firstName + ' ' + direction.lastName + '%0A';
            pedido = pedido + '*Número:* ' + direction.phoneNumber + '%0A';
            pedido = pedido + '*Dirección:* ' + direction.address + '%0A';
            pedido = pedido + '*Estado:* ' + direction.state + '%0A';
            pedido = pedido + '*Ciudad:* ' + direction.city + '%0A';
            pedido = pedido + '*Código postal:* ' + direction.postalCode + '%0A';       
      
        } else {
            if (user.factura) {
                pedido = pedido + '*Factura con Datos:* ' + '%0A';
            } else {
                pedido = pedido + '*Datos del Cliente:* ' + '%0A';
            }
            pedido = pedido + '*Nombre:* ' + user.nombre + '%0A';
            pedido = pedido + '*Número:* ' + user.celular + '%0A';
            if (user.factura) {
                pedido = pedido + '*Cédula/Ruc:* ' + user.cedula + '%0A';
                pedido = pedido + '*Email:* ' + user.email + '%0A';
                pedido = pedido + '*Dirección:* ' + user.direccion + '%0A';
            }
            pedido = pedido + '*Whatsapp:* ' + 'https://wa.me/' + phoneNumber + '%0A';
    
            // dirección
            const urlDirection = 'https://www.google.com/maps/search/?api=1%26query=' + direction.position.lat +
                        ',' + direction .position.lng;
            pedido = pedido + '*Dirección de Entrega:* ' + '%0A';
            pedido = pedido + urlDirection + '%0A';
            if (chatPedido.direction.referencia) {
                pedido = pedido + '*Referencia:* ' + chatPedido.direction.referencia + '%0A';
            }     
        }
        return pedido;



  }

  getTextDatosValues(chat: Chat): string {
        const chatPedido = chat.mensaje as any; 
        let pedido = '';
        pedido = pedido + '*Valor del Pedido:* ' + chatPedido.values.subtotal.toFixed(2) + '$' + '%0A';
        if (chatPedido.values.envio === null) {
        pedido = pedido + '*Costo Envío:* '  + 'pendiente' + '%0A';
        } else  {
        pedido = pedido + '*Costo Envío:* ' + chatPedido.values.envio.toFixed(2) + '$' + '%0A';
        }
    
        if (chatPedido.values.descuento === null) {
        pedido = pedido + '*Descuento:* '  + 'pendiente' + '%0A';
        } else  {
        pedido = pedido + '*Descuento:* ' + chatPedido.values.descuento.toFixed(2) + '$' + '%0A';
        }
        pedido = pedido + '*Total:* ' +  chatPedido.values.total.toFixed(2) + '$' + '%0A';
        pedido = pedido + '*Pago:* ' + chatPedido.pago.tipo;
        return pedido;
  }

  getTextDatosFecha(chat: Chat): string {
    const chatPedido = chat.mensaje as any; 
    let pedido = '';
    pedido = pedido + '*Fecha de entrega:* ';
    if (chatPedido.fechaEnvio.tipo === 'now') {
    pedido = pedido + 'lo antes posible' + '%0A';
    } else  {
    const fecha = this.getTime(chatPedido.fechaEnvio.day)
    pedido = pedido + fecha + '%0A';
    // console.log('getTextDatosFecha -> ', fecha, chatPedido.fechaEnvio.day)
    }
    return pedido;
  }

  getTime(timeChat: any): string {
      const timeStampSecods = timeChat.seconds;
      let time: string;
      const date = new Date (timeStampSecods * 1000);
      const now = this.windowService.getTime();
      if (date.getMinutes() < 10) {
        return time = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
        + ', ' + date.getHours() + ':0' + date.getMinutes();
       } else {
        return time = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
        + ', ' + date.getHours() + ':' + date.getMinutes();
      }
  }


  llamar(chat: Chat) {
    const chatPedido = chat.mensaje as any; 
    let cellNumber: string;
    if (chat.receptor.celular) {
        if (chat.receptor.celular.e164Number) {
            cellNumber = 'tel:' + chatPedido.datos.celular.e164Number; 
            window.open(cellNumber, 'blank');
        }
    }
  }

  sendTextByWpp(chat: Chat) {
    const pedido = chat.mensaje as any; 
    let phoneNumber: string;
    let url: string;  
    if (pedido.datos.celular) {
        if (pedido.datos.celular.e164Number) {
            phoneNumber = pedido.datos.celular.e164Number;
            phoneNumber = phoneNumber.replace('+', '');
            url = 'https://wa.me/' + phoneNumber;
            window.open(url);
        }
    } 
  }

  openDirection(chat: Chat) {
    const chatPedido = chat.mensaje as any; 
    if (chatPedido.datos.city) {return}
    const direction = chatPedido.direction.direccionEnvio;
    const urlDirection = 'https://www.google.com/maps/search/?api=1&query=' + direction.position.lat +
    ',' + direction .position.lng;
    window.open(urlDirection);
  }


}
