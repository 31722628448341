import { Component, OnInit, Input } from '@angular/core';
import { ContactanosI } from '../backend/models-pages-crud';

@Component({
  selector: 'app-contactanos',
  templateUrl: './contactanos.component.html',
  styleUrls: ['./contactanos.component.scss'],
})
export class ContactanosComponent implements OnInit {

  @Input() page: ContactanosI;

  constructor() { }

  ngOnInit() {}

}
