import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { finalize} from 'rxjs/operators';
import { FileStorage, Multimedia } from '../models';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class FirestorageService {

  constructor(private fireStorage: AngularFireStorage) {


  }

  uploadImage(img: any, nombre: string, carpeta: string): Promise<string> {

    return new Promise(  resolve => {
      if (img.search('https://') !== 0 && img) {
          const filePath = carpeta + '/' + nombre + '.jpg';
          const fileRef = this.fireStorage.ref(filePath);
          const task = fileRef.putString(img, 'data_url');
          task.snapshotChanges().pipe(
            finalize(() => {
                fileRef.getDownloadURL().forEach( res => {
                    resolve(res);
                });
            })).subscribe();
      } else {
        resolve(img);
      }
    });
  }

  getAllImages(path: string, maxResults: number, nextPageToken: string): Promise<FileStorage[]> {
      return new Promise(  async (resolve) => {
          const urls: FileStorage[] = [];

          // let res: firebase.storage.ListResult;
          let res: any;
          if (nextPageToken == null) {
            res = await this.fireStorage.storage.ref(path).list({ maxResults});
          } else {
            res = await this.fireStorage.storage.ref(path).list(
              { maxResults,
                pageToken: nextPageToken
              });
          }

          const imgByLoad = res.items.length * 2;
          let numImg = 0;
          res.items.forEach( async (img, index) => {
                  const imgLoad: FileStorage = {
                    ref: img,
                    url: '',
                    metadata: null,
                    nextPageToken: res.nextPageToken
                  };
                  urls.push(imgLoad);
                  img.getMetadata().then( metadata => {
                      imgLoad.metadata = metadata;
                      numImg ++;
                      if (numImg >= imgByLoad) {
                        resolve(urls);
                        return;
                      }
                  });
                  img.getDownloadURL().then( url => {
                      imgLoad.url = url;
                      numImg ++;
                      if (numImg >= imgByLoad) {
                        resolve(urls);
                        return;
                      }
                  });
          });
      });
  }

  urlToData(url: any, callback: any) {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
        const reader = new FileReader();
        reader.onloadend = () => {
            callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  downloadFile(url: string, name: string) {
      this.urlToData(url, (myBase64: any) => {
        saveAs(myBase64, name);
      });
  }

  getRef(fullpath: string) {
      return this.fireStorage.ref(fullpath);
  }

  deleteFile(fullpath: string) {
    const fileRef = this.fireStorage.ref(fullpath);
    return fileRef.delete();
  }

  changeNameFile(file: Multimedia, name: string, carpeta: string): Promise<FileStorage> {

    return new Promise(  async (resolve) => {
        this.urlToData(file.img, async (myBase64: any) => {
          const filePath = carpeta + '/' + name + '.jpg';
          const fileRef = this.fireStorage.ref(filePath);
          const task = fileRef.putString(myBase64, 'data_url');
          task.snapshotChanges().pipe(
            finalize(() => {
                fileRef.getDownloadURL().forEach( res => {
                    const fileNew: FileStorage = {
                      url: res,
                      ref: fileRef,
                      metadata: null,
                      nextPageToken: null,
                    };
                    fileRef.getMetadata().subscribe( metadata => {
                        fileNew.metadata = metadata;
                        console.log('fileNew -> ', fileNew);
                        resolve(fileNew);
                        return;
                   });
                });
            })).subscribe();
        });
    });

  }

  uploadFile(img: any, nombre: string, carpeta: string): Promise<FileStorage> {

    return new Promise(  resolve => {
          const filePath = carpeta + '/' + nombre;
          const fileRef = this.fireStorage.ref(filePath);
          const task = fileRef.putString(img, 'data_url');
          task.snapshotChanges().pipe(
            finalize(() => {
                fileRef.getDownloadURL().forEach( res => {
                    const fileNew: FileStorage = {
                      url: res,
                      ref: fileRef,
                      metadata: null,
                      nextPageToken: '',
                    };
                    fileRef.getMetadata().subscribe( metadata => {
                        fileNew.metadata = metadata;
                        resolve(fileNew);
                  });
                });
            })).subscribe();
    });
  }

  getDownloadUrl(pathFile: string): Promise<string> {
    return new Promise((resolve) => {
        this.getRef(pathFile).getDownloadURL().subscribe( res => {
          resolve(res);
          return;    
        })
    }); 
  }

  getDownloadFile(url: string, callback: any) {
    this.urlToData(url, (myBase64: any) => {
          callback(myBase64);
    });
  }




}
