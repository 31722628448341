import { Component, ElementRef } from '@angular/core';
import { Platform } from '@ionic/angular';
import { WindowService } from './services/window.service';
import { Plugins, StatusBarStyle } from '@capacitor/core';
import { filter } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { Parametros } from '../environments/parametros';
import { AppearanceService, Estilos } from './services/appearance.service';
import { SeoService } from './services/seo.service';
import { UidlocalService } from './services/uidlocal.service';
import { LocalStorageService } from './services/local-storage.service';

const { SplashScreen, StatusBar } = Plugins;
declare var gtag: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  dark = false;

  info: any;
  isServer: boolean = false;

  constructor(
              private router: Router,
              private platform: Platform,
              private windowService: WindowService,
              private appearanceService: AppearanceService,
              public seoService: SeoService,
              private UidlocalService: UidlocalService,
              private localStorageService: LocalStorageService,
              private el: ElementRef,
              ) {
          this.initializeApp();
          this.isServer = this.windowService.isServer();
          setTimeout(() => {}, 1000);
        }
        
 

  
  initializeApp() {   
    if (!this.windowService.isServer()) {
        if (this.platform.is('capacitor')) {
          this.platform.ready().then(() => {
            SplashScreen.hide();
            StatusBar.setBackgroundColor({color: '#cea413'});
            StatusBar.setStyle({
              style: StatusBarStyle.Light
            });
          });
        }
        this.setVarStyles();
        this.setAnalytics();
        this.setDarkMode(false);
        this.loadDarkMode();
    }
    this.UidlocalService.setUidLocal(Parametros.uidAdmin);
  }

  setAnalytics() {
      const navEndEvents$ = this.router.events.pipe( filter(event => 
        event instanceof NavigationEnd));
      navEndEvents$.subscribe((event: NavigationEnd) => {
          gtag('config', Parametros.firebaseConfig.measurementId, {
            'page_path': event.urlAfterRedirects
          });
      });
  }

  setVarStyles() {
    this.appearanceService.getEstilosChanges().subscribe( (estilos: Estilos) => {
      Object.keys(estilos).forEach( estilo => {
            const componente = estilos[estilo];
            if (componente.value) {
              // console.log('set parameter ->', componente);
              this.el.nativeElement.style.setProperty(componente.variable, componente.value); 
            }   
            Object.keys(componente).forEach( subestilo => {
                if (componente[subestilo].value) {
                  this.el.nativeElement.style.setProperty(componente[subestilo].variable, componente[subestilo].value); 
                }      
            });
      });
    });
  }

  setDarkMode(ev: boolean) {
    this.dark = ev;
    const mode = {
      dark: this.dark
    }
    const path = 'darkMode';
    this.appearanceService.setDarkMode(this.dark);
    this.localStorageService.setDoc(path, mode);
  }

  async loadDarkMode() {
    const path = 'darkMode';
    const modeSave = await this.localStorageService.getDoc(path);  
    if (modeSave) {
        this.dark = modeSave.dark;
    }
  }

}
