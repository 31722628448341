import { Component, Input, OnInit } from '@angular/core';
import { Chat } from '../../models-chat';
import { PopoverController } from '@ionic/angular';
// import { Pedido, DatosPedido, DireccionEnvio } from '../../../store/models-store';
import { ChatService } from '../../services/chat.service';
import { environment } from '../../../../environments/environment.prod';

@Component({
  selector: 'app-pop-pedido',
  templateUrl: './pop-pedido.component.html',
  styleUrls: ['./pop-pedido.component.scss']
})
export class PopPedidoComponent implements OnInit {

  @Input() chat: Chat;
  @Input() pedido: any;

  enableWhatsapp = environment.enableWhatsapp

  constructor(public popoverController: PopoverController,
              public chatService: ChatService) { }

  ngOnInit(): void {
      console.log('this.pedido -> ', this.pedido);
      this.pedido.fechaEnvio.value = (new Date(this.pedido.fechaEnvio.day.seconds * 1000)).toUTCString();
      console.log( this.pedido.fechaEnvio.value)
  }

  dismiss() {
    this. popoverController.dismiss();
  }


  async tomarPedido() {
    //  const pedido = this.chat.mensaje as Pedido; 
    //  const Uid = this.chat.receptor.uid;
    //  // guarda en la base de datos de pedidos tomados
    //  let path = 'Usuarios/' + Uid + '/tomados';
    //  this.chat.pedido.state = 'enProceso';
    //  // se verifica si tiene un hora planificada por el cliente para que le llegue su pedido
    //  // si si, entonces se pone esa hora para ordenar los pedidos conforme ese valor
    //  let configDay = false;
    //  if (this.chat.pedido.fecha) {
    //     if (this.chat.pedido.fecha.tipo === 'fecha') {
    //         const nowDay = new Date();
    //         const date = new Date (this.chat.pedido.fecha.day);
    //        //console.log('days ->', nowDay, date);
    //         if (date > nowDay) {
    //          //console.log('hora planificada mayor');
    //           this.chat.pedido.time.enviado.fecha = date;
    //           configDay = true;
    //         }
    //     }
    //  }
    //  if (!configDay) {
    //   //console.log('sin planificar hora');
    //    this.chat.pedido.time.enviado.fecha = new Date();
    //  }
    //  this.chat.pedido.time.tomado.fecha = new Date();
    //  this.firebaseService.createDocID(this.chat, path, this.chat.id);
    //  // cambia de estado del pedido del cliente.
    //  path = 'Usuarios/' + this.chat.receptor  + '/chat/' + this.chat.emisor + '/mensajes/';
    //  const UpdateDoc = {
    //    pedido: this.chat.pedido,
    //  };
    //  this.firebaseService.updateDocId(UpdateDoc, path, this.chat.id);

    //  // envia un mensaje nuevo el cliente informando que el pedido ha sido tomado
    //  // primero cargar el mensaje predefinido por el usuario.
    //  path = 'Usuarios/' + Uid + '/fastsMensajes';
    //  this.firebaseService.getCollectionQuery<MFast>(path, 'tipo', 'tomado')  //  tipo: 'tomado'
    //     .subscribe( res => {
    //      //console.log('find tomado msgfast ->', res);
    //       let mensajeText = '';
    //       if (res) {
    //         mensajeText = res[0].mensaje;
    //         const mensaje: Mensaje = {
    //            mensaje: mensajeText,
    //            receptor: this.chat.emisor,
    //            nombre: this.restauranteService.getNombre(),
    //            time: new Date(),
    //            estado: 'enviando',
    //         };
    //         ////console.log('envio mensaje mensaje', mensaje);
    //         this.firebaseService.sendMensaje(mensaje);
    //       }
    //    });
  }



}
