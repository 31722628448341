import { Component, OnInit, ViewChild, ComponentFactoryResolver, ElementRef } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { FrontendService } from '../../services/frontend.service';
import { MatMenu } from '@angular/material/menu';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-pruebas',
  templateUrl: './pruebas.component.html',
  styleUrls: ['./pruebas.component.scss']
})
export class PruebasComponent implements OnInit {


  ids: Array<String> = ['one', 'two', 'three', 'four']

  numero1 = 0;
  numero2 = 0;
  respuesta = {
    suma: 0,
  };

  @ViewChild('menu') menu: MatMenu;
  @ViewChild('button') button: MatButton;

  newFile: any;
  fileLoad: any;

  mediciones: Madlimu[] = [];

  encabezados: string[] = [];
  // const path = 'Laboratorios' 

  constructor(private http: HttpClient,
              public frontendService: FrontendService,
              public componentFactoryResolver: ComponentFactoryResolver) {
               }

  ngOnInit(): void {

            const fecha = new Date('2020-11-01');
            console.log('this.menu -> ', this.menu);
            this.menu._startAnimation()
            // const h = this.button._elementRef.nativeElement as ElementRef;
            // h.nativeElement
            
  }

  mostrar() {
    console.log(this.button._elementRef.nativeElement.click())
  }

  ver() {
    // console.log(this.receptor);
  }

  sumarBankend() {
      const url = 'http://35.232.234.179:8000/apiAnalisis/sumar2/?format=json';
      const data = {
        num1: + this.numero1,
        num2: + this.numero2
      };
      console.log(data);
      const httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
        })
      };
      return this.http.post<any>(url, data, httpOptions).subscribe( res => {
          console.log('recibo -> ', res);
          this.respuesta = res;
      });
    }

    prueba() {
      // str = str.replace(/<br>/gi, "\n");
      // str = str.replace(/<p.*>/gi, "\n");
      // str = str.replace(/<(?:.|\s)*?>/g, "");
      // str = str.replace(/\n|\r/g, "");
      // str = str.replace(/\s+/g,' ');
      // console.log('str -> ', str);
    }

    UploadFile(event: any) {
      if (event.target.files && event.target.files[0]) {
        this.newFile = event.target.files[0];
        // console.log(this.newFile);
        const reader = new FileReader();
        reader.onload = ((image) => {
            this.fileLoad = image.target.result;
            // console.log(this.fileLoad);
            this.procesarTxt(this.fileLoad);
        });
        reader.readAsText(event.target.files[0]);
      }
    }

    procesarTxt(file: string) {
      const lineas = file.split('\n');
      this.encabezados = this.getFilas(lineas[0]);
      const array: Madlimu[] = [];
      // console.log( lineas);
      // lineas = lineas.splice(0);
      let twoRow = 0;
      // console.log( lineas);
      lineas.forEach( (linea, index) => {
            if (!index) {return; }
            twoRow ++;
            // console.log(linea, index, twoRow);
            if (twoRow === 2) {
              const uno = this.getFilas(lineas[index - 1]);
              const dos = this.getFilas(linea);
              console.log('uno -> ', uno);
              console.log('dos -> ', dos);
              const medicion: Madlimu = {
                date: uno[0],
                sampleId: uno[1],
                assay: uno[2],
                dil: this.getDil(uno, dos),
                rlu: this.getRLU(uno, dos),
                concentracion: this.getConcentration(uno, dos),
                range: this.getRange(dos),
                flag: dos[3],
              };
              console.log('medicion', medicion);
              twoRow = 0;
              array.push(medicion);
            }
      });
      console.log(array);
      this.mediciones = array;
    }

    getFilas(linea: string) {
        const parts = (linea.replace(/%/g, '%	')).split('	');
        const encabezados: string[] = [];
        parts.forEach( encabezado => {
            if (encabezado.length) {
                encabezados.push(encabezado);
            }
        });
        return encabezados;
    }

    getRLU(uno: string[], dos: string[]) {
      if (this.exisDil(uno, dos)) {
        uno.splice(3, 1);
      }
      console.log('getRLU -> uno: ', uno);
      const value = uno[3];
      const mean = ((dos[0].split('Mean:'))[1].split(' '))[0];
      const cv = (dos[0].split('CV:'))[1];
      const rlu = {
        value,
        mean,
        cv,
      };
      // console.log('rlu -> ', rlu);
      return rlu;
    }

    getDil(uno: string[], dos: string[]) {
        if (this.exisDil(uno, dos)) {
          return uno[3];
        }
        return '';
    }

    exisDil(uno: string[], dos: string[])  {
      let flag = false;
      let dil = false;
      if (dos[3] !== undefined) {
           flag = true;
      }
      if (flag) {
        if (uno.length > 6) {
          dil = true;
        }
      } else if (uno.length > 5) {
          dil = true;
      }
      return dil;
      // console.log('dil ->', dil);
    }

    getConcentration(uno: string[], dos: string[]) {
      const value = + (uno[4].split(' '))[0];
      const unit = (uno[4].split(' '))[1];
      const mean = {
          value: + ((dos[1].split('Mean:'))[1].split(' '))[0],
          unit: ((dos[1].split('Mean:'))[1].split(' '))[1],
      };
      const cv = (dos[1].split('CV:'))[1];
      const concentracion = {
        value,
        unit,
        mean,
        cv,
      };
      // console.log('concentracion -> ', concentracion);
      return concentracion;
    }

    getRange(dos: string[]) {
      const min = + (dos[2].split(' '))[0];
      const max = + (dos[2].split(' '))[2];
      const range = {
        min,
        max,
      };
      // console.log('range -> ', range);
      return range;
    }

    saveResultados(resultados: Madlimu[]) {
        resultados.forEach( resultado => {
            this.saveResultado(resultado);
        });
    }

    saveResultado(resultado: Madlimu) {
      const id = resultado.date + '_' + resultado.sampleId + '_' + resultado.assay;
      const fecha = new Date(resultado.date);
      resultado.id = id;
      resultado.fecha = fecha;
    }
}


interface Lineas {
  position: number;
  value: string;
}


// const encabezados = ['Date', 'SampleID', 'Assay', 'Dil.', 'RLU', 'Concentration', 'Range', 'Flag'];

interface Encabezados {
   name: string;
   position: number;
}


interface Madlimu {
  id?: string;
  fecha?: Date;
  date: string;
  sampleId: string;
  assay: string;
  dil: string;
  rlu: {
    value: string;
    mean: string;
    cv: string;
  };
  concentracion: {
    value: number;
    unit: string;
    mean: {
      value: number;
      unit: string;
    };
    cv: string;
  };
  range: {
    min: number;
    max: number;
  };
  flag: string;
}

// medicion1 = {
//     date: '2020-11-01',
//     sampleId: 17,
//     assay: 'D-dimer',
//     dil: '',
//     rlu: {
//       value: '1:2166 ',
//       mean: '2166 1:33028',
//       cv: '0.000%',
//     },
//     concentracion: {
//       value: 0.010,
//       unit: 'ng/mL',
//       mean: {
//         value: 0.010,
//         unit: 'ng/mL',
//       },
//       cv: '0.000%',
//     },
//     range: {
//       min: 0,
//       max: 0.1,
//     },
//     flag: 'E;C;<<',
// };
