import { Injectable } from '@angular/core';
import { Entrada } from '../models-post';

@Injectable({
  providedIn: 'root'
})
export class EntradasService {

  entradas: Entrada[] = [];

  constructor() { }

  getEntrada(id: string): Entrada | null {
      let entrada = null;
      this.entradas.every( entradaExist => {
        if (entradaExist.id === id) {
          entrada = entradaExist;
          return false;
        }
        return true;
      });
      return entrada;
  }

  putEntrada(entrada: Entrada) {
      let exist = false;
      let index = 0;
      this.entradas.every( (entradaExist, indexExist) => {
          console.log('entrada', entrada, 'entradaExist', entradaExist)
          if (entradaExist.id === entrada.id) {
            exist = true;
            index = indexExist;
            return false;
          }
          return true;
      });
      if (!exist) {
        this.entradas.push(entrada);
      } else {
        this.entradas[index] = entrada;
      }
  }

  putEntradas(entradas: Entrada[]) {
    entradas.forEach( entrada => {
        let exist = false;
        let index = 0;
        this.entradas.every( (entradaExist, indexExist) => {
            if (entradaExist.id === entrada.id) {
              exist = true;
              index = indexExist;
              return false;
            }
            return true;
        });
        if (!exist) {
          this.entradas.push(entrada);
        } else {
          this.entradas[index] = entrada;
        }
    });
  }

}



