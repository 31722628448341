import { Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { SubscriptionPWA } from '../../models';
import { PopoverController, Platform } from '@ionic/angular';
import { AutenticationService } from '../../services/autentication.service';
import { FirestoreService } from '../../services/firestore.service';
import { QuestionNotificationComponent } from '../componentes/question-notification/question-notification.component';
import { Router } from '@angular/router';
import { LocalStorageService } from '../../services/local-storage.service';
import { InteraccionService } from '../../services/interaccion.service';
import { environment } from '../../../environments/environment';
import { Subject } from 'rxjs';
import { WindowService } from '../../services/window.service';
import { NotificationApp } from '../models-notifications';

import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed,
  LocalNotificationActionPerformed} from '@capacitor/core';


const { PushNotifications } = Plugins;
const { LocalNotifications } = Plugins;


@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  private readonly VAPID_PUBLIC_KEY = 'BNamMqgXoIDuA_31GLWFir7_zIEjhxZxRCUzc_RZJJ8O-8UcASTSjRDql46iWHEGLmq2ZwXqStAzZhFnhL46zDw';
  private notificaciones: NotificationApp[] = [];
  private notificaciones$ = new Subject<NotificationApp[]>();

  private newNotificaciones: NotificationApp[] = [];
  private newNotificaciones$ = new Subject<NotificationApp[]>();

  private soundCorto = new Audio('assets/sounds/corto.mp3');
  private soundLargo = new Audio('assets/sounds/corto.mp3');
  // private soundLargo = new Audio('assets/sounds/largo.mp3');
  private enabledSoundVar = false;

  constructor(
              private swPush: SwPush,
              public platform: Platform,
              private autenticationService: AutenticationService,
              private firestoreService: FirestoreService,
              private popoverController: PopoverController,
              private router: Router,
              private localStorageService: LocalStorageService,
              private interaccionService: InteraccionService,
              private windowService: WindowService) {
                
                if (this.windowService.isServer()) {return};
                this.stateUser();
              
  }

  stateUser() {
      this.autenticationService.authState().subscribe( res => {
          if (res !== null) {
              this.inicializar(false);
              this.getMoreNoticationsUser();
              this.getNewNotications();
              this.enabledSound();
          }  
      });
  } 

  enabledSound() {
        setTimeout(() => {
          this.enabledSoundVar = true;
        }, 5000);
  }

  async inicializar(request: boolean) {

    let permission = null;
    if (!request) {
       permission = await this.getPermission();
    }

    // console.log('permission -> ', permission);
    if (permission != null) {
        if (permission) {
          // console.log('permiso previamente concedido');
        } else {
          // console.log('permiso previamente denagado');
        }
    } else {

        // console.log('pedir permiso');
        const msg = '¿Deseas recibir notificaciones acerca de tus reservas?'
        const acept = await this.showPopAceptNotificacions(msg);
        if (acept) {
              if (this.platform.is('capacitor')) {
                  this.requestPermissionMovil();
              } else {
                  // console.log('es PWA -> requestPermission ');
                  this.requestPermissionPWA();
              }
    
        } else {
            this.savePermission(false);
        }
    }
    return;
  }

  async getPermission() {
    return new Promise( async (resolve, reject) => {
        const path = 'permission-notification'
        const permission = await this.localStorageService.getDoc(path);
        // console.log('permission save: -> ', permission);
        if (permission) {
            if (permission.permission) {
              resolve(true);
              return;
            } else {
              resolve(false);
              return;
            }
        } else {
          resolve(null);
          return;
        }
    });
  }

  async savePermission(enable: boolean) {
    const path = 'permission-notification'
    const doc = {
      permission: enable,
    }
    // console.log('permission to save -> ', doc);
    await this.localStorageService.setDoc(path, doc);
    // console.log('permission saved -> ', doc);
  }

  async requestPermissionMovil() {
      PushNotifications.requestPermission().then( result => {
        // console.log('PushNotifications.requestPermission()');
        if (result.granted) {
          // console.log('permisos concedidos');
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
          this.savePermission(true);
          this.addListeners();
        } else {
          // Show some error
          this.interaccionService.showToast('Permisos denegados')
        }
      });
  }

  async requestPermissionPWA() {
  
        // console.log('requestPermission() -> PWA');
        this.swPush.requestSubscription({
          serverPublicKey: this.VAPID_PUBLIC_KEY }).then( async (sub) =>  {
            let timeExp = 0;
            const pushSubscription = {
              endpoint: sub.endpoint,
              expirationTime: timeExp,
              keys: {
                p256dh: sub.toJSON().keys.p256dh,
                auth: sub.toJSON().keys.auth
              }
            };
            this.suscripcionClick();
            this.savePermission(true);
            this.guardarSuscriptionPWA(pushSubscription);

        }).catch(err =>  {
              this.savePermission(true);
              // // console.error('Could not subscribe to notifications', err);
              this.interaccionService.showToast('Este navegador no soporta notificaciones');
        });
   
  }

  async showPopAceptNotificacions(msg?: string): Promise<boolean>  {

    return new Promise(  async  (resolve) => {

        if (!msg) {
           msg = '¿Deseas recibir notificaciones de nuestros artículos y recetas más recientes?';
        }

        const popover = await this.popoverController.create({
          component: QuestionNotificationComponent,
          cssClass: 'popoverSearch',
          mode: 'ios',
          backdropDismiss: false,
          componentProps: {msg}
        });
        await popover.present();
        const {data} = await   popover.onWillDismiss();
        // console.log('data -> ', data);
        if (data) {
          // console.log('habilitar notificaciones');
          resolve(true);
          return;
        } else {
          // console.log('no! -> notificaciones');
          resolve(false);
          return;
        }

    });
  }

  suscripcionClick() {

    this.swPush.notificationClicks.subscribe(
      ({action, notification}) => {
          // TODO: Do something in response to notification click.
            // console.log('suscripcionClick() ->', action, notification);
    });

    this.swPush.messages.subscribe( res => {
         // console.log('swPush.messages -> ', res);
    });

  }

  addListeners() {

    // LocalNotifications.schedule({
    //   notifications: [
    //     {
    //       title: 'notificacion local',
    //       body: 'notification.body',
    //       id: 1,
    //     }
    //   ]
    // });

    PushNotifications.addListener('registration',
      (token: PushNotificationToken) => {
        // console.log('The token is:', token);
        this.guadarToken(token.value);
      }
    );

    PushNotifications.addListener('registrationError',
      (error: any) => {
        // console.log('Error on registration', error);
      }
    );

    /// primer plano
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotification) => {
         // console.log('Push received en 1er plano: ', notification);
            LocalNotifications.schedule({
              notifications: [
                {
                  title: notification.title,
                  body: notification.body,
                  id: 1,
                  extra: {
                    data: notification.data
                  }
                }
              ]
            });
      }
    );

    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: PushNotificationActionPerformed) => {
          // console.log('Push action performed en segundo plano -> ', notification);   
          this.router.navigate([notification.notification.data.enlace]);
      }
    );

    LocalNotifications.addListener('localNotificationActionPerformed', 
    (notification: LocalNotificationActionPerformed) => {
       // console.log('Push action performed en primer plano: ', notification);
       this.router.navigate([notification.notification.extra.data.enlace]);
    });
  }

  async guardarSuscriptionPWA(pushSubscription: SubscriptionPWA) {
    // console.log('Subscription object To SAVE: ', pushSubscription);
    const Uid = await this.autenticationService.getUid();
    const path = environment.pathRoot + Uid + '/pushSubscription';
    this.firestoreService.createDocument<SubscriptionPWA>(pushSubscription, path);
  }

  async guadarToken(token: any) {

    const Uid = await this.autenticationService.getUid();
    if (Uid) {
        // console.log('guardar Token Firebase ->', Uid);
        const path = environment.pathRoot + Uid + '/tokens';
        const doc = {
          token: token,
          id: this.firestoreService.createIdDoc()
        };
        this.firestoreService.createDocumentID(doc, path, doc.id);
        // console.log('guardar TokenFirebase()->', doc, path, Uid);
    }
  }

  getNotificationChanges() {
      this.windowService.setTimeOut(100).then( () => {
        this.notificaciones$.next(this.notificaciones);
      });
      return this.notificaciones$.asObservable();
  }

  getMoreNoticationsAll() {
  }

  async getMoreNoticationsUser(): Promise<boolean> {
     return new Promise( async (resolve, reject) => {
       const Uid = await this.autenticationService.getUid();
       const path = environment.pathRoot + Uid + '/notifications';
       let startAt = null;
       if (this.notificaciones.length) {
         startAt  = this.notificaciones[this.notificaciones.length - 1].time;
       }
       this.firestoreService.getCollectionOrderLimit<NotificationApp>(path, 2, 'time', 'desc', startAt).subscribe( res => {
             // console.log('getMoreNoticationsUser -> ', res);
             if (res !== undefined) {
                if (!res.length) { 
                      resolve(false);
                      return;
                }
                const items = this.notificaciones;
                res.forEach( itemLoad => {
                      let exist = false;
                      items.every( (itemExist, index) => {
                              if (itemExist.id === itemLoad.id) {
                                exist = true;
                                items[index] = itemLoad;
                                return false;
                              }
                              return true;
                      });
                      if (!exist) {
                        items.push(itemLoad);
                      }
                });
                this.orderNotications(items, 'time');
                this.notificaciones$.next(this.notificaciones);
                resolve(true);
                return;
             }
             resolve(false);
             return;
       });

     });
  }

  orderNotications(items: any[], campo: string) {
      items.sort( (n1, n2) => {
          if (n1[campo] <= n2[campo]) {return 1;}
          if (n1[campo] > n2[campo]) {return -1;}
          return 0;
      });
  }

  getNewNotificationChanges() {
    this.windowService.setTimeOut(100).then( () => {
      this.newNotificaciones$.next(this.newNotificaciones);
    });
    return this.newNotificaciones$.asObservable();
  }

  async getNewNotications() {
      const Uid = await this.autenticationService.getUid();
      const path = environment.pathRoot + Uid + '/notifications';  // notifications
      this.firestoreService.getCollectionQueryOrderLimit<NotificationApp>(path, 'view', false, 1, 'time', 'desc', null).subscribe( res => {
              // console.log('getNewNotications() -> ', res);
              if (res) {
                 this.newNotificaciones = res;
                 this.emitSound(res);
                 this.newNotificaciones$.next(this.newNotificaciones);
              }
      });
  }

  async sendNewNotification(notification: NotificationApp, receptorUid: string) {
      notification.id = this.firestoreService.createIdDoc();
      notification.time = this.windowService.getTime();
      notification.view = false;
      notification.imagen = await this.autenticationService.getPhoteUrlProfile();
      const path = environment.pathRoot + receptorUid + '/notifications';
      this.firestoreService.createDocumentID(notification, path, notification.id).then( () => {
            // console.log('sendNewNotification success');
      })
  }

  async deleteNotification(notificacion: NotificationApp) {
      const Uid = await this.autenticationService.getUid();
      const path = environment.pathRoot + Uid + '/notifications';
      this.firestoreService.deleteDocumentID(path, notificacion.id);
  }

  emitSound(notificaciones: NotificationApp[]) {
      if (this.enabledSoundVar) {
        notificaciones.every( notificacion => {
             if  (notificacion.type === 'tienda') {
              this.soundCorto = new Audio('assets/sounds/corto.mp3');
              this.soundCorto.play();
              return false;
             }
             if (notificacion.type === 'chat') {
                this.soundLargo = new Audio('assets/sounds/largo.mp3');
                this.soundLargo.play();
                return false;
             }
             return true;
        });
      }
  }

  stopSound() {
    this.soundCorto.pause();
    this.soundLargo.pause();
  }

}
