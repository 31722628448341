import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponentesComponent } from './loader-componentes/loader-componentes.component';
import { ReceptorDirective } from './receptor.directive';



@NgModule({
  declarations: [
    LoaderComponentesComponent,
    ReceptorDirective
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    LoaderComponentesComponent
  ]
})
export class LoaderModule { }
