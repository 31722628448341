import { Campo, ImagenEstructura, PdfEstructura } from '../../widgets/crudfast/models-crud';
import { environment } from '../../../environments/environment';
import { BannerEstructura } from '../../common/models-common';
import { PlanTourDetalle } from '../modelstravel';


// MODULO ALIMENTACION-------|
export const AlimentacionEstructura: Campo[] = [
    { name: 'nombre', type: 'string',  label: 'Nombre', value: '', editable: true },
    { name: 'nomenclatura', type: 'string',  label: 'Nomenclatura', value: '', editable: true },
];
export const AlimentacionesEstructura: Campo[] = [
    { name: 'alimentacion', type: 'array', label: 'Tipos', value: [], subCampos: AlimentacionEstructura, editable: true },
];
export const  CrudAlimentacion = {
    icon: 'fast-food',
    label: 'Módulo alimentación',
    url: 'alimentacion',
    colorIcon: '#f8f9fa',
    colorBackgroud: 'linear-gradient(90deg, #ff1027 0%, rgb(253, 108, 165) 100%)',
    config: {
      title: 'Alimentación',
      setVar: {},
      labelAll: '',
      estructura: AlimentacionesEstructura,
      path: 'Travel/' + environment.uidAdmin + '/alimentacion/',
      multiple: false,
      id: environment.uidAdmin,
    },
}
//---------------------------|

// MODULO ACOMODACION--------|
export const AcomodacionEstructura: Campo[] = [
    { name: 'nombre', type: 'string',  label: 'Nombre', value: '', editable: true },
    { name: 'nomenclatura', type: 'string',  label: 'Nomenclatura', value: '', editable: true },
];
export const AcomodacionesEstructura: Campo[] = [
    { name: 'alimentacion', type: 'array', label: 'Tipos', value: [], subCampos: AcomodacionEstructura, editable: true },
];
export const  CrudAcomodacion = {
    icon: 'bed',
    label: 'Módulo acomodación',
    url: 'acomodacion',
    colorIcon: '#343a40',
    colorBackgroud: 'linear-gradient(90deg, #ffc107 0%, #ffd740 100%)',
    config: {
      title: 'Acomodación',
      setVar: {},
      labelAll: '',
      estructura: AcomodacionesEstructura,
      path: 'Travel/' + environment.uidAdmin + '/acomodacion/',
      multiple: false,
      id: environment.uidAdmin,
    },
}
//---------------------------|



// MODULO DESTINOS-----------|

export const CuidadEstructura: Campo[] = [
  { name: 'id', value: '', editable: false },
  { name: 'nombre', type: 'string',  label: 'Destino', value: '', editable: true },
  { name: 'pais', type: 'string',  label: 'Pais', value: '', editable: true },
  { name: 'descripcion', type: 'textarea',  label: 'Descripción', value: '', editable: true },
  { name: 'imagenes', type: 'array', label: 'Añadir imágenes', value: [], subCampos: ImagenEstructura, editable: true },
  { name: 'gentilicio', type: 'string',  label: 'Gentilicio', value: '', editable: true },
  { name: 'moneda', type: 'string',  label: 'Moneda', value: '', editable: true },
  { name: 'clima', type: 'string',  label: 'Clima', value: '', editable: true },
  { name: 'parrafos', type: 'array', label: 'Añadir parrafo', value: [],
  subCampos: [
    { name: 'nombre', type: 'string',  label: 'Título', value: '', editable: true },
    { name: 'texto', type: 'textarea',  label: 'Texto', value: '', editable: true },
  ], editable: true },
  { name: 'lugares', type: 'array', label: 'Añadir atráctivo', value: [], subCampos: [
    { name: 'nombre', type: 'string',  label: 'Nombre', value: '', editable: true },
    { name: 'descripcion', type: 'textarea',  label: 'Descripción (máximo 400 carácteres)', maxlength: 400, value: '', editable: true },
    { name: 'imagen', type: 'Objeto', label: 'Añadir imágen', value: '', subCampos: ImagenEstructura, editable: true },
  ], editable: true },
];

export const  CrudDestinos = {
  icon: 'airplane',
  label: 'Módulo destinos',
  url: 'destinos',
  colorIcon: '#f8f9fa',
  colorBackgroud: 'linear-gradient(90deg, #7ae2f3 0%, #17a2b8 100%)',
  config: {
    title: 'Destinos',
    labelAll: 'Todos los Destinos',
    setVar: {},
    estructura: CuidadEstructura,
    path: 'Travel/' + environment.uidAdmin + '/destinos/',
    editPath: '/travel/set/' + 'destinos/',
    viewPath: '/travel/destino/',
    multiple: true,
    functionValidator: null
  },
}

//---------------------------|





// MODULO HOTELES-----------|
export const HotelEstructura: Campo[] = [
    { name: 'nombre', type: 'string',  label: 'Nombre', value: '', editable: true },
    { name: 'id', type: 'string', value: '', editable: false },
    { name: 'descripcion', type: 'textarea',  label: 'Descripción corta', value: '', editable: true },
    { name: 'descripcion_larga', type: 'html',  label: 'Descripción detallada', value: '', editable: true },
    { name: 'imagenes', type: 'array', label: 'Añadir imágenes del hotel', value: [], subCampos: ImagenEstructura, editable: true },
    { name: 'habitaciones', type: 'array', label: 'Añadir imágenes de habitaciones', value: [], subCampos: ImagenEstructura, editable: true },
    { name: 'clasificacion', type: 'html',  label: 'Clasificación del hotel', value: '', editable: true },
    { name: 'caracteristicas', type: 'html',  label: 'Características', value: '', editable: true },
    // { name: 'tarifas', type: 'html',  label: 'Detalles de tarifas', value: '', editable: true },
    { name: 'ubicacion', type: 'textarea',  label: 'Ubicación (enlace google maps) ', value: '', editable: true },
    {
      name: 'ciudad', type: 'collection', label: 'Destino' ,
      pathColl: CrudDestinos.config.path,
      value: [],
      subCampos: CuidadEstructura,
      selectCampo: 'ciudad',
      selectCampos: CuidadEstructura,
      campoCollLabel: 'nombre',
      editable: true
    },
    { name: 'enlacePermanente', type: 'string',  label: 'Enlace', value: '', editable: true },
];

export const  CrudHoteles = {
    icon: 'business',
    label: 'Módulo Hoteles',
    url: 'hoteles',
    colorIcon: 'rgb(225 242 245)',
    colorBackgroud: 'linear-gradient(90deg,  #511dad 0%, #ac8fe0 100%)',
    config: {
      title: 'Hoteles',
      labelAll: 'Todos los hoteles',
      setVar: {},
      estructura: HotelEstructura,
      path: 'Travel/' + environment.uidAdmin + '/hoteles/',
      multiple: true,
      editPath: '/travel/set/' + 'hoteles/',
      viewPath: '/travel/hotel/',
      functionValidator: null
    }
}
//---------------------------|

// MODULO CATEGORIA-PLAN---------------|

//---------------------------|
export const CategoriaItemEstructura: Campo[] = [
    { name: 'id', value: '', editable: false },
    { name: 'nombre', type: 'string', label: 'Nombre', value: '', editable: true },
    { name: 'descripcion', type: 'textarea', label: 'Descripcion', value: '', editable: true },
    { name: 'subCategorias', type: 'array', label: 'Añadir subcategoria', value: [], subCampos:
     [
      { name: 'id', value: '', editable: false },
      { name: 'nombre', type: 'string', label: 'Nombre', value: '', editable: true },
      { name: 'descripcion', type: 'textarea', label: 'Descripcion', value: '', editable: true },
      { name: 'subCategorias', type: 'array', label: '', value: [], editable: false}
     ]
    , editable: true }
  ];

  export const CategoriasItemEstructura: Campo[] = [
    { name: 'categorias', type: 'array', label: 'Añadir categoría', value: [], subCampos: CategoriaItemEstructura, editable: true }
  ];


export const  CrudCategoriasPlan =
{
    icon: 'text',
    label: 'Configurar categorias',
    url: 'categorias',
    colorIcon: 'white',
    colorBackgroud: 'linear-gradient(90deg, #f44336 0%, #c2185b 100%)',
    config: {
      title: 'Categorias',
      labelAll: 'Todas las categorias',
      setVar: {},
      estructura: CategoriasItemEstructura,
      path: 'Travel/' + environment.uidAdmin + '/categorias/',
      multiple: false,
      id: environment.uidAdmin,
    },
}

// MODULO PLAN---------------|

export const KidPrecioEstructura: Campo[] = [
  { name: 'precioDolares', type: 'number', label: 'Tarifa USD', value: null, editable: true },
  { name: 'precioCop',type: 'number', label: 'Tarifa COP', value: null, editable: true },
]

const HotelSelectEstructura: Campo[] = [
  { name: 'nombre', type: 'string',  label: 'Nombre', value: '', editable: true },
  { name: 'descripcion', type: 'textarea',  label: 'Descripción corta', value: '', editable: true },
  { name: 'id', type: 'string',  label: 'id', value: null, editable: false },

];

export const HotelesEstructura: Campo[] = [
  {
    name: 'hotel', type: 'collection', label: 'Hotel' ,
    pathColl: CrudHoteles.config.path,
    value: [],
    subCampos: HotelEstructura,
    selectCampo: 'Hoteles',
    selectCampos: HotelEstructura,
    arrayName: 'nombre',
    campoCollLabel: 'nombre',
    editable: true
  },
  { name: 'alimentacion', type: 'document', label: 'Alimentación' ,
    pathDoc: CrudAlimentacion.config.path + environment.uidAdmin,
    value: [],
    subCampos: AlimentacionesEstructura,
    selectCampo: 'Tipos',
    selectCampos: AlimentacionEstructura,
    editable: true
  },
  { name: 'acomodaciones', type: 'array', label: 'Añadir Acomodación (opcional)', value: [],
    subCampos: [
      { name: 'acomodacion', type: 'document', label: 'Acomodación' ,
        pathDoc: CrudAcomodacion.config.path + environment.uidAdmin,
        value: [],
        subCampos: AcomodacionesEstructura,
        selectCampo: 'Tipos',
        selectCampos: AcomodacionEstructura,
        arrayName: 'nombre',
        editable: true,
      },
      { name: 'precioDolares', type: 'number', label: 'Tarifa USD', value: null, editable: true },
      { name: 'precioCop',type: 'number', label: 'Tarifa COP', value: null, editable: true },
      { name: 'configuraciones', type: 'string', label: 'Configuraciones', value: '', editable: true },
    ],
    editable: true,
  },
  { name: 'kidstarifa', type: 'Objeto', label: 'Tarifa niños (opcional)', value: {},
    subCampos: KidPrecioEstructura, editable: true},

]

const IncluyeItemTourEstructura: Campo[] = [
  { name: 'cheked', type: 'checkbox', label: '¿Incluye?', value: false, editable: true },
  { name: 'descripcion', type: 'textarea',  label: 'Lo que incluye', value: '', editable: true },
]


export const IncluyeTourEstructura: Campo[] = [

  { name: 'traslados', type: 'Objeto',  label: 'Traslados', value: {}, subCampos: IncluyeItemTourEstructura, editable: true },
  { name: 'alojamiento', type: 'Objeto', label: 'Alojamiento', value: {}, subCampos: IncluyeItemTourEstructura, editable: true },
  { name: 'alimentacion', type: 'Objeto', label: 'Alimentacion', value: {}, subCampos: IncluyeItemTourEstructura, editable: true },
  { name: 'adicionales', type: 'Objeto', label: 'Adicionales', value: {}, subCampos: IncluyeItemTourEstructura, editable: true },
  { name: 'tiquetes', type: 'Objeto',  label: 'Tiquetes Aereos', value: {}, subCampos: IncluyeItemTourEstructura, editable: true },

];

export const CuidadSelectEstructura: Campo[] = [
  { name: 'id', value: '', editable: false },
  { name: 'nombre', type: 'string',  label: 'Destino', value: '', editable: true },
  { name: 'pais', type: 'string',  label: 'Pais', value: '', editable: true },
  { name: 'descripcion', type: 'textarea',  label: 'Descripción', value: '', editable: true },
  { name: 'enlacePermanente', type: 'string',  label: 'Enlace', value: '', editable: true },
];

export const PlanEstructura: Campo[] = [
    { name: 'nombre', type: 'string',  label: 'Nombre', value: '', editable: true },
    {
      name: 'ciudad', type: 'collection', label: 'Destino' ,
      pathColl: CrudDestinos.config.path,
      value: [],
      subCampos: CuidadEstructura,
      selectCampo: 'ciudad',
      campoCollLabel: 'nombre',
      selectCampos: CuidadSelectEstructura,
      editable: true
    },
    { name: 'categoria', type: 'categorias', label: 'Categoria' , value: [], categorias: [], editable: true },
    { name: 'rango_personas', type: 'number',  label: 'Máximo # de personas', value: null, editable: true },
    { name: 'duracion', type: 'string',  label: 'Duración', value: '', editable: true },
    { name: 'fechas_fijas', type: 'array', label: 'Añadir Fechas Fijas', value: [],
      subCampos: [ { type: 'datetime', name: 'nombre', label: 'Fecha', value: null, editable: true},], editable: true },

    { name: 'fechas_rango', type: 'array', label: 'Añadir Rango de Fechas', value: [],
      subCampos: [ { type: 'daterange', name: 'fecha_rango', label: 'Fecha', value: null, editable: true},], editable: true },

    { name: 'hoteles', type: 'array', label: 'Añadir Hotel', value: [],
      subCampos: HotelesEstructura,
      editable: true
    },

    { name: 'descripcion', type: 'textarea',  label: 'Descripción corta', value: '', editable: true },
    { name: 'descripcion_larga', type: 'html',  label: 'Descripción', value: '', editable: true },
    { name: 'imagenes', type: 'array', label: 'Añadir imágenes', value: [], subCampos: ImagenEstructura, editable: true },


    { name: 'condiciones', type: 'textarea', label: 'Condiciones', value: '', editable: true },

    { name: 'incluye', type: 'Objeto',  label: '¿Qué incluye?', subCampos: IncluyeTourEstructura, editable: true },

    { name: 'no_incluye', type: 'html', label: 'No Incluye', value: '', editable: true },
    { name: 'recomendaciones', type: 'html', label: 'Recomendaciones', value: '', editable: true },
    { name: 'asesorInfo', type: 'html', label: 'Información del asesor (opcional)', value: '', editable: true },

    { name: 'pdf', type: 'Objeto', label: 'Pdf (Más información)', value: null, subCampos: PdfEstructura, editable: true },

    { name: 'temporadaAlta', type: 'Objeto', label: 'Temporada alta', value: null, subCampos:
    [
      { name: 'boton', type: 'string',  label: 'Texto del botón', value: '', editable: true },
      { name: 'descripcion', type: 'textarea',  label: 'Descripción', value: '', editable: true },
      {
        name: 'link', type: 'collection', label: 'Tour o Plan',
        pathColl: 'Travel/' + environment.uidAdmin + '/planes/',
        validaterDoc: { campo: 'type',  value: 'plan'},
        value: [],
        subCampos: [
          { name: 'nombre', type: 'string',  label: 'Nombre', value: '', editable: true },
          { name: 'enlacePermanente', type: 'string',  label: 'Enlace', value: '', editable: true },
        ],
        selectCampo: 'Planes',
        campoCollLabel: 'nombre',
        selectCampos: [
          { name: 'nombre', type: 'string',  label: 'Nombre', value: '', editable: true },
          { name: 'enlacePermanente', type: 'string',  label: 'Enlace', value: '', editable: true },
        ],
        editable: true,
      },
    ]
      , editable: true },
    { name: 'destacado', type: 'checkbox', label: 'Destacado', value: true, editable: true },
    { name: 'no_visible', type: 'checkbox', label: 'No Visible', value: false, editable: true },
    { name: 'fecha', type: 'datetime',  label: 'Fecha', value: new Date(), editable: true},

    { name: 'likes', value: null, editable: false },
    { name: 'views', value: null, editable: false },
    { name: 'comments', value: null, editable: false },
    { name: 'type', type: 'string', value: 'plan', editable: false },


];

export const  CrudPlanes = {
    icon: 'calendar',
    label: 'Módulo Planes',
    url: 'planes',
    colorIcon: '#f8f9fa',
    colorBackgroud: 'linear-gradient(90deg, #28a745 0%, #5be27a 100%)',
    config: {
      title: 'Planes',
      labelAll: 'Todos los Planes',
      setVar: {},
      estructura: PlanEstructura,
      path: 'Travel/' + environment.uidAdmin + '/planes/',
      categoriasPath: 'Travel/' + environment.uidAdmin + '/categorias/' + environment.uidAdmin,
      multiple: true,
      editPath: '/travel/set/' + 'planes/',
      viewPath: '/travel/plan/',
      functionValidator: null
    }
}



//------ MODULO TOURS ------//

const TarifaTourEstructura: Campo[] = [
  { name: 'nombre', type: 'string',  label: 'Nombre de la tarifa', value: '', editable: true },
  { name: 'descripcion', type: 'string',  label: 'Descripción de la tarifa', value: '', editable: true },
  { name: 'precioDolares', type: 'number', label: 'Tarifa por persona USD', value: null, editable: true },
  { name: 'precioCop',type: 'number', label: 'Tarifa por persona COP', value: null, editable: true },
  { name: 'desde', type: 'number', label: 'Aplica desde (# personas)' , value: null, editable: true },
];


const PuntoEncuentroEstructura: Campo[] = [
  { name: 'lugar_fijo', type: 'string',  label: 'Lugar fijo de encuentro', value: '', editable: true },
  { name: 'pickup', type: 'checkbox', label: 'Incluye pickup', value: false, editable: true },
];

export const TourEstructura: Campo[] = [
  { name: 'nombre', type: 'string',  label: 'Nombre', value: '', editable: true },
  {
    name: 'ciudad', type: 'collection', label: 'Destino' ,
    pathColl: CrudDestinos.config.path,
    value: [],
    subCampos: CuidadEstructura,
    selectCampo: 'ciudad',
    selectCampos: CuidadSelectEstructura,
    campoCollLabel: 'nombre',
    editable: true
  },
  { name: 'categoria', type: 'categorias', label: 'Categoria' , value: [], categorias: [], editable: true },
  { name: 'rango_personas', type: 'number',  label: 'Máximo # de personas', value: null, editable: true },

  { name: 'duracion', type: 'string',  label: 'Duración', value: '', editable: true },
  { name: 'hora_inicio', type: 'horatime',  label: 'Hora de inicio', value: null, editable: true },

  { name: 'fechas_fijas', type: 'array', label: 'Añadir Fechas Fijas', value: [],
    subCampos: [ { type: 'datetime', name: 'nombre', label: 'Fecha', value: null, editable: true},], editable: true },

  { name: 'fechas_rango', type: 'array', label: 'Añadir Rango de Fechas', value: [],
    subCampos: [ { type: 'daterange', name: 'fecha_rango', label: 'Fecha', value: null, editable: true},], editable: true },

  { name: 'punto_encuentro', type: 'Objeto', label: 'Punto de encuentro', value: {}, subCampos: PuntoEncuentroEstructura, editable: true },
  { name: 'tarifas', type: 'array', label: 'Añadir tarifa', value: [], subCampos: TarifaTourEstructura, editable: true },


  { name: 'descripcion', type: 'textarea',  label: 'Descripción corta', value: '', editable: true },
  { name: 'descripcion_larga', type: 'html',  label: 'Descripción', value: '', editable: true },
  { name: 'imagenes', type: 'array', label: 'Añadir imágenes', value: [], subCampos: ImagenEstructura, editable: true },


  { name: 'condiciones', type: 'textarea', label: 'Condiciones', value: '', editable: true },

  { name: 'incluye', type: 'Objeto',  label: '¿Qué incluye?', subCampos: IncluyeTourEstructura, editable: true },

  { name: 'no_incluye', type: 'html', label: 'No Incluye', value: '', editable: true },
  { name: 'recomendaciones', type: 'html', label: 'Recomendaciones', value: '', editable: true },
  { name: 'asesorInfo', type: 'html', label: 'Información del asesor (opcional)', value: '', editable: true },

  { name: 'pdf', type: 'Objeto', label: 'Pdf (Más información)', value: null, subCampos: PdfEstructura, editable: true },
  { name: 'temporadaAlta', type: 'Objeto', label: 'Temporada alta', value: null, subCampos:
    [
      { name: 'boton', type: 'string',  label: 'Texto del botón', value: '', editable: true },
      { name: 'descripcion', type: 'textarea',  label: 'Descripción', value: '', editable: true },
      {
        name: 'link', type: 'collection', label: 'Tour' ,
        pathColl: CrudPlanes.config.path,
        validaterDoc: { campo: 'type',  value: 'tour'},
        value: [],
        subCampos: [
          { name: 'nombre', type: 'string',  label: 'Nombre', value: '', editable: true },
          { name: 'enlacePermanente', type: 'string',  label: 'Enlace', value: '', editable: true },
        ],
        selectCampo: 'Tour',
        campoCollLabel: 'nombre',
        selectCampos: [
          { name: 'nombre', type: 'string',  label: 'Nombre', value: '', editable: true },
          { name: 'enlacePermanente', type: 'string',  label: 'Enlace', value: '', editable: true },
        ],
        editable: true,
      },
    ]
  , editable: true },
  { name: 'destacado', type: 'checkbox', label: 'Destacado', value: true, editable: true },
  { name: 'no_visible', type: 'checkbox', label: 'No Visible', value: false, editable: true },
  { name: 'fecha', type: 'datetime',  label: 'Fecha', value: new Date(), editable: true},


  // { name: 'boton_pago', type: 'checkbox', label: 'Boton de pago', value: false, editable: true },

  { name: 'likes', value: null, editable: false },
  { name: 'views', value: null, editable: false },
  { name: 'comments', value: null, editable: false },
  { name: 'type', type: 'string', value: 'tour', editable: false },




];


export const  CrudTours = {
  icon: 'alarm',
  label: 'Módulo Tours',
  url: 'tours',
  colorIcon: '#f8f9fa',
  colorBackgroud: 'linear-gradient(90deg, #0d6efd 0%, #769ad0 100%)',
  config: {
    title: 'Tours',
    labelAll: 'Todos los Tours',
    setVar: {},
    estructura: TourEstructura,
    path: 'Travel/' + environment.uidAdmin + '/planes/',
    searchPath: 'Travel/' + environment.uidAdmin + '/tours/',
    categoriasPath: 'Travel/' + environment.uidAdmin + '/categorias/' + environment.uidAdmin,
    multiple: true,
    editPath: '/travel/set/' + 'tours/',
    viewPath: '/travel/plan/',
    functionValidator: null
  }
}



// --------- BANNER TRAVEL ---------  //

const BannersEstructura: Campo[] = [
  { name: 'banners', type: 'array', label: 'Añadir banner', value: [], subCampos:  BannerEstructura, editable: true },
];

export const  CrudBannerTravel = {
  icon: 'images',
  label: 'Banners - Inicio',
  url: 'banners',
  colorIcon: 'white',
  colorBackgroud: 'linear-gradient(90deg, #cddc39 0%, rgb(135 146 21))',
  config: {
    title: 'Banners',
    labelAll: 'Todos los banners',
    setVar: {},
    estructura: BannersEstructura,
    path: 'Travel/' + environment.uidAdmin + '/banners/',
    multiple: false,
    id: environment.uidAdmin,
  },
}
