import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as marked from 'marked';

@Pipe({
  name: 'mdtoHtml'
})
export class MdtoHtmlPipe implements PipeTransform {

  constructor(private domSanitizer: DomSanitizer) {
  }

  transform(value: string): any {
    // return value;
    return  this.domSanitizer.bypassSecurityTrustHtml(marked(value));
  }

}
