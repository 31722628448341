import { ParametrosI } from './models-parametros';

export const Parametros: ParametrosI = {
  login_google: true,
  firebaseConfig: {
    apiKey: "AIzaSyDwwmsS94PA59N5NKOQ_gA6x_Guo55lU9Y",
    authDomain: "entravel-tours.firebaseapp.com",
    projectId: "entravel-tours",
    storageBucket: "entravel-tours.appspot.com",
    messagingSenderId: "558866066408",
    appId: "1:558866066408:web:1ba31acf039407e1c4125d",
    measurementId: "G-NN2TLVLPNC"
  },
  uidAdmin: 'lXxJXTYUUWN04nDyoyxqDeORsBF2',
  // uidAdmin: 'DEZtyEkziMNVId41zDMYD5AqjMk2',
  domain: 'https://entraveltours.com',
  siteName: 'Entravel Tours',
  version: "4.0.0",
  enableWhatsapp: true,
  tipoletra: 'Montserrat',
  apariencia: {
    themeSite: 'tercero',
  },
  colores: {
    primario: '#ee982e',
    primario_contraste: '#ffffff',
    secundario: '#1f5e6c',
    secundario_contraste: '#ffffff',
    terciario: '#54acba',
    terciario_contraste: 'white',
  },
  maxPreguntasChatbot: 60
}
