import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PruebasComponent } from './pruebas/pruebas.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { AngularModule } from '../angular.module';
import { PipesModule } from '../pipes/pipes.module';
import { ComponentesModule } from '../common/componentes/componentes.module';
import { RouterModule } from '@angular/router';
import { ConocenosComponent } from './conocenos/conocenos.component';
import { PagesRoutingModule } from './pages-routing.module';
import { PaginaComponent } from './pagina/pagina.component';
import { LoadpageModule } from '../widgets/loadpage/loadpage.module';
import { ContactanosComponent } from './contactanos/contactanos.component';
import { ContactformModule } from '../widgets/contactform/contactform.module';

@NgModule({
  declarations: [
    PruebasComponent,
    ConocenosComponent,
    ContactanosComponent,
    PaginaComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AngularModule,
    ComponentesModule,
    RouterModule,
    PagesRoutingModule,
    PipesModule,
    LoadpageModule,
    ContactformModule,
    PipesModule,
  ],
})
export class PagesModule { }
