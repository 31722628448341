import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import { SendemailService } from '../../../notifications/services/sendemail.service';
import { InteraccionService } from '../../../services/interaccion.service';
import { CrudDatos, DatosEmpresaI } from '../../../common/backend/models-common-crud';
import { FirestoreService } from 'src/app/services/firestore.service';
import { Subscription } from 'rxjs';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { Email } from '../../../notifications/models-notifications';
import { UserInfo } from '../../../models';

@Component({
  selector: 'app-contact-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent implements OnInit, OnDestroy {


  contactoForm = this.fb.group({
    nombre: [null, Validators.required],
    email: [null, Validators.required],
    celular: [null, Validators.required],
    mensaje: [null, Validators.required], 
  });

  infoEmpresa: DatosEmpresaI;
  suscripcionInfo: Subscription;
  suscripcionAdmins: Subscription;
  loading = true;

  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.Colombia];
  selectedCountryISO: CountryISO = CountryISO.Colombia;

  correoEnvio = false;
  usersToSendEmails : {email: string}[] = []; 

  constructor(private fb: FormBuilder,
              private sendemailService: SendemailService,
              private interaccionService: InteraccionService,
              private firestoreService: FirestoreService) { 
                      this.loadInfoEmpresa();
                      this.loadUsersToSendEmail();
              }


  ngOnInit() { 
  }

  ngOnDestroy(): void {
      if (this.suscripcionInfo) {
         this.suscripcionInfo.unsubscribe();
      }
      if (this.suscripcionAdmins) {
         this.suscripcionAdmins.unsubscribe();
      }
  }

  loadInfoEmpresa() {
      const path = CrudDatos.config.path + CrudDatos.config.id;
      this.suscripcionInfo = this.firestoreService.getDocumentChanges<DatosEmpresaI>(path).subscribe( res => {
              if (res) {
                  this.loading = false;
                  this.infoEmpresa = res;
              }
      });
  }

  async enviar() {
      if (this.contactoForm.status !== 'VALID') {
          this.validNumber();
          return;
      }
      const email: Email = {
          from: this.contactoForm.value.email,
          to: this.getEmailsToSendCorreo(),
          subject: 'Solicitud de contacto:' + this.contactoForm.value.nombre,
          html: null
      };
      email.html = `
              <h2>Solicitud de contacto</h2>
              <p> <strong>Email: </strong> ${email.from}</p>
              <p> <strong>Nombre: </strong> ${email.subject}</p>
              <p> <strong>Celular: </strong> ${this.contactoForm.value.celular.e164Number}</p>
              <p> <strong>Mensaje: </strong> 
              <p>${this.contactoForm.value.mensaje}</p>`;
      await this.interaccionService.presentLoading('Enviando...')
      this.sendemailService.sendEmail(email).then( res => {
            this.interaccionService.dismissLoading();
            if(res) {
                this.interaccionService.showToast('Correo enviado');
                this.contactoForm.reset();
                this.correoEnvio = true;
            } else {
              this.interaccionService.showToast('No se pudo enviar el correo');
            }
      });
  }

  validNumber(): boolean {
     let valid = true;
     if (this.contactoForm.controls['celular'].hasError('validatePhoneNumber') 
        || this.contactoForm.controls['celular'].hasError('required') ) {
           this.interaccionService.showToast('Ingresa un número válido')
           valid = false;
     }
     return valid;
  }

  async loadUsersToSendEmail() {
    this.usersToSendEmails= [];
    const path = 'Usuarios/';
    let toAdmins = [];
    this.suscripcionAdmins = this.firestoreService.getCollectionQuery<UserInfo>(path, 'rol', 'admin').subscribe( res => {
        toAdmins = [];
        if (res.length) {
            console.log('loadUsersToSendEmail() -> ', res);
            res.forEach( admin => { toAdmins.push({email: admin.email, uid: admin.id});});
        }  
        this.usersToSendEmails= [];
        this.usersToSendEmails.push(...toAdmins);
    });
  }

  getEmailsToSendCorreo() : string[] {
    const emails: string[] = [];
    emails.push(this.infoEmpresa.email);
    this.usersToSendEmails.forEach( user => {
        if (this.infoEmpresa.email != user.email) {
            emails.push(user.email);
        }
    });
    // console.log('getEmailsToSendCorreo() ->', emails);
    return emails;
  }


}
