import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as marked from 'marked';

@Pipe({
  name: 'mdtoUrl'
})
export class MdtoUrlPipe implements PipeTransform {

  constructor(private domSanitizer: DomSanitizer) {
  }

  transform(value: string): any {
    return  this.domSanitizer.bypassSecurityTrustResourceUrl(value);
  }

}
