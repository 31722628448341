import { Component, OnInit, ViewChild } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { UidlocalService } from '../../../services/uidlocal.service';
import { WindowService } from '../../../services/window.service';
import { UserChat } from '../../../chat/models-chat';
import { ChatpopComponent } from '../chatpop/chatpop.component';
import { InteraccionService } from '../../../services/interaccion.service';
import { ParametrosChatbotI } from '../../backend/models-chatbot-crud';
import { ChatbotService } from '../../services/chatbot.service';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-chatbubble',
  templateUrl: './chatbubble.component.html',
  styleUrls: ['./chatbubble.component.scss'],
})
export class ChatbubbleComponent implements OnInit {

  suscriberChat: Subscription;
  chatLocal: ChatLocal;

  uidLocal: string;
  infoChatbot: UserChat;
  suscriberUidLocal: Subscription;
  suscriberChatLocal: Subscription;
  parametrosChatbot: ParametrosChatbotI;
  @ViewChild('tooltip') tooltip: MatTooltip


  constructor(private popoverController: PopoverController,
              private uidlocalService: UidlocalService,          
               private windowService: WindowService,
               private interaccionService: InteraccionService,
               private chatbotService: ChatbotService) {
                
      this.parametrosChatbot = this.chatbotService.getParametrosChatbot();
      if (this.windowService.isServer()) {
        return;
      }  
      
      this.chatbotService.getParametrosChatbotChanges().subscribe( res => {
        console.log('res => ', res);
        "assets/imagenes/chatbot-tercero.svg"
        
            this.parametrosChatbot = res; 
      });

      this.loadInfoChatbot();
   }

   
  ngOnInit() {
    if (this.windowService.isServer()) {return;}
    this.windowService.setTimeOut(1000).then( () => {
      this.tooltip.show(100);
    });
    this.windowService.setTimeOut(5000).then( () => {
      this.tooltip.hide();
    });
  }

  loadInfoChatbot() {
    const uidLocal = this.uidlocalService.getUidLocal()
    const subscriber = this.uidlocalService.getInfoChatChanges().subscribe( res => {
      // console.log('loadChatDefault -> ', res);      
      if(res) {
           this.infoChatbot = {
              uid: uidLocal,
              nombre: res.nombre,
              email: res.email,
              celular: res.celular? res.celular : null,
              photo: res.photoUrl ? res.photoUrl : null
           }
           subscriber.unsubscribe();
      }
     });
  }


  async openChat(ev: any) {
    if (this.infoChatbot == undefined) {
      await this.interaccionService.presentLoading('cargando...');
      await this.windowService.setTimeOut(1500);
      await this.interaccionService.dismissLoading();
      this.openChat(ev);
      return;
    }
    const popover = await this.popoverController.create({
      component: ChatpopComponent,
      cssClass: 'popoverChatBot',
      componentProps: { infoChatbot: this.infoChatbot},
      event: ev,
    });
    await popover.present();
  }


}


interface ChatLocal {
  nombre: string;
  photo: string;
  lastConexion: any;
  whatsapp: string;
  chatId: string;
}
