import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MdtoHtmlPipe } from './mdto-html.pipe';
import { SearchwordPipe } from './searchword.pipe';
import { SizebytesPipe } from './sizebytes.pipe';
import { MdtoUrlPipe } from './mdto-url.pipe';



@NgModule({
  declarations: [
    MdtoHtmlPipe,
    SearchwordPipe,
    SizebytesPipe,
    MdtoUrlPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    MdtoHtmlPipe,
    SearchwordPipe,
    SizebytesPipe,
    MdtoUrlPipe
  ]
})
export class PipesModule { }
