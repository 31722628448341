import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSliderModule } from '@angular/material/slider';
import {MatChipsModule} from '@angular/material/chips';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatExpansionModule} from '@angular/material/expansion';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MatSliderModule,
    MatChipsModule,
    MatSidenavModule,
    MatMenuModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    ClipboardModule,
    MatTooltipModule,
    ScrollingModule,
    MatProgressSpinnerModule,
    MatExpansionModule
  ],
  exports: [
    MatSliderModule,
    MatChipsModule,
    MatSidenavModule,
    MatMenuModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    ClipboardModule,
    MatTooltipModule,
    ScrollingModule,
    MatProgressSpinnerModule,
    MatExpansionModule
  ]
})
export class AngularModule { }
