import { environment } from '../../../environments/environment.prod';
import { Campo } from '../../widgets/crudfast/models-crud';

const CategoriaItemEstructura: Campo[] = [
    { name: 'id', value: '', editable: false },
    { name: 'nombre', type: 'string', label: 'Nombre', value: '', editable: true },
    { name: 'descripcion', type: 'textarea', label: 'Descripcion', value: '', editable: true },
    { name: 'subCategorias', type: 'array', label: 'Añadir subcategoria', value: [], subCampos: [], editable: false }
  ];

  const CategoriasItemEstructura: Campo[] = [
    { name: 'categorias', type: 'array', label: 'Añadir categoría', value: [], subCampos: CategoriaItemEstructura, editable: true }
  ];


export const  CrudCategoriasBlog =
{
    icon: 'text',
    label: 'Configurar categorias',
    url: 'categorias',
    colorIcon: 'white',
    colorBackgroud: 'linear-gradient(90deg, #f44336 0%, #c2185b 100%)',
    config: {
      title: 'Categorias',
      labelAll: 'Todas las categorias',
      setVar: {},
      estructura: CategoriasItemEstructura,
      path: 'Blog/' + environment.uidAdmin + '/categorias/',
      multiple: false,
      id: environment.uidAdmin,
    },
}
const CuerpoPostEstructura: Campo[] = [
  { name: 'id', value: '', editable: false },
  { name: 'banner', type: 'img', label: 'Banner principal', value: '', editable: true },
  { name: 'parrafos', type: 'array', label: 'Añadir parrafo', value: [], editable: true,
  subCampos: [
    { name: 'titulo', type: 'string', label: 'Título', value: '', editable: true },
    { name: 'imagen', type: 'img',  label: 'Imagen ', value: '', editable: true },
    { name: 'descripcion', type: 'html',  label: 'Descripción', value: '', editable: true },
  ]},
];

const EntradaEstructura: Campo[] = [
    { name: 'id', value: '', editable: false },
    { name: 'titulo', type: 'string', label: 'Título', value: '', editable: true },
    { name: 'enlacePermanente', type: 'string', label: 'Enlace permanente', value: '', editable: false },
    { name: 'descripcion', type: 'textarea',  label: 'Descripción', value: '', editable: true },
    { name: 'imagenDestacada', type: 'img',  label: 'Imagen destacada', value: '', editable: true },
    { name: 'fecha', type: 'datetime', label: 'fecha', value: new Date(), editable: true },
    { name: 'autor', type: 'string', label: 'Autor', value: '', editable: true },
    { name: 'categoria', type: 'categorias', label: 'Categoria' , value: [], categorias: [], editable: true },
    { name: 'contenido', type: 'outside', label: 'Contenido', pathOutside: "contenido", 
      subCampos: CuerpoPostEstructura, value: null, loaded: false, editable: true},
    // no editables
    { name: 'likes', value: null, editable: false },
    { name: 'views', value: null, editable: false },
    { name: 'comments', value: null, editable: false },
    { name: 'vecinos', value: [], editable: false },
];

export const  CrudEntradasBlog = {
    icon: 'newspaper-sharp',
    label: 'Configurar Blogs',
    url: 'blog',
    colorIcon: 'white',
    colorBackgroud: 'linear-gradient(90deg, #28a745 0%, #5be27a 100%)',
    config: {
        title: 'Blog',
        labelAll: 'Todos los blogs',
        setVar: {},
        estructura: EntradaEstructura,
        path: 'Blog/' + environment.uidAdmin + '/post/',
        multiple: true,
        editPath: '/posts/set/blog/',
        viewPath: '/posts/',
        categoriasPath: 'Blog/' + environment.uidAdmin + '/categorias/' + environment.uidAdmin,
        functionValidator: null
    }
}

