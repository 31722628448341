import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchword'
})
export class SearchwordPipe implements PipeTransform {

  transform(value: string, search: string): string{

    let text = value.toLowerCase();
    text = text.replace(/á/gi, 'a');
    text = text.replace(/é/gi, 'e');
    text = text.replace(/í/gi, 'i');
    text = text.replace(/ó/gi, 'o');
    text = text.replace(/ú/gi, 'u');
    text = text.replace(/ñ/gi, 'n');

    search = search.toLowerCase();
    search = search.replace(/á/gi, 'a');
    search = search.replace(/é/gi, 'e');
    search = search.replace(/í/gi, 'i');
    search = search.replace(/ó/gi, 'o');
    search = search.replace(/ú/gi, 'u');
    search = search.replace(/ñ/gi, 'n');

    const pos = text.search(search);
    let inicial = 0;
    let final = 0;
    if (pos > 20) { inicial = pos - 20;
    } else { inicial = 0; }
    if (value.length > (pos + 100) ) { final = pos + 100;
    } else { final = value.length; }
    value = value.slice(inicial, final);
    value = '...' + value + '...';
    return value;
  }

}
