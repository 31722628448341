import { Component, OnInit } from '@angular/core';
import { AutenticationService } from '../../../services/autentication.service';
import { LocalStorageService } from '../../../services/local-storage.service';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss']
})
export class CookiesComponent implements OnInit {

  ok = true;
  path = 'cookies'
  constructor(private autenticationService: AutenticationService,
              private localStorageService: LocalStorageService) { 
    this.autenticationService.getUidChanges().subscribe( res => {
        this.changeOk();
    });
  }

  ngOnInit(): void {
      this.getOk();
  }

  async changeOk() {
      const suscrito = await  this.autenticationService.isUserSuscrito();
      if (suscrito) { this.ok = true; }
  }

  getOk() {
     this.localStorageService.getDoc(this.path).then( res => {
            // console.log('getOk() cookies ->', res);
            if (res) {
              this.ok = res.acept;
            } else {
              this.ok = false;
            }
     })
  }

  setOk() {
      this.ok = true;
      const cookies = {
        acept: true
      }
      this.localStorageService.setDoc(this.path, cookies);
  }

}
