import { Component, OnInit, Output, EventEmitter, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { FormBuilder, Validators } from "@angular/forms";
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-inputphone',
  templateUrl: './inputphone.component.html',
  styleUrls: ['./inputphone.component.scss'],
})
export class InputphoneComponent implements OnInit, OnDestroy {

  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.Colombia, CountryISO.UnitedStates, CountryISO.Ecuador, CountryISO.Venezuela, CountryISO.Peru, CountryISO.Spain ];
  selectedCountryISO: CountryISO = CountryISO.Colombia;

  phoneForm = this.fb.group({
    phone: [null, Validators.required],
  });

  @Output() phoneChanges = new EventEmitter<InputPhone>();
  @Input() inputPhone: Observable<InputPhone>;
  suscriber: Subscription;
  phoneFormsuscriber: Subscription;

  constructor(private fb: FormBuilder) { 
      // this.getValueChanges();
  }

  getValueChanges() {
    if (this.phoneFormsuscriber) {
      this.phoneFormsuscriber.unsubscribe();
    }
    this.phoneFormsuscriber = this.phoneForm.valueChanges.subscribe( res => {
      // console.log('getValueChanges() ->');
      if (res.phone) {
        this.validNumber(res.phone.number, res.phone.e164Number, res.phone.countryCode);
      }
    });
  }



  ngOnInit() {
      this.suscriber = this.inputPhone.subscribe( (res) => {
            if (res.number) {
              res.number = res.number.replace(/ /g, '');
            }
            this.phoneForm.patchValue({
              phone: res.number
            });
            if (res.countryCode) {
              this.selectedCountryISO = res.countryCode.toLowerCase() as CountryISO;
            }
            // console.log('inputPhone.subscribe -> ', res);
            this.getValueChanges();
      });
  }

  ngOnDestroy(): void {
    if (this.phoneFormsuscriber) {
      this.phoneFormsuscriber.unsubscribe();
    }
  }


  validNumber(number: any, e164Number: any, countryCode: any) {
      if (this.phoneForm.controls['phone'].hasError('validatePhoneNumber') 
        || this.phoneForm.controls['phone'].hasError('required') ) {
            this.phoneChanges.emit(null);
      } else {
        const res = {
          number,
          countryCode,
          e164Number
        }
        this.phoneChanges.emit(res);
      }
  }

  

}

export interface InputPhone {
  countryCode: string;
  number: string;
  e164Number: string;
}
