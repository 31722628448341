import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Chat } from '../../models-chat';
// import { Pedido } from '../../../store/models-store';
import { ChatService } from '../../services/chat.service';

@Component({
  selector: 'app-sendpedidowpp',
  templateUrl: './sendpedidowpp.component.html',
  styleUrls: ['./sendpedidowpp.component.scss']
})
export class SendpedidowppComponent implements OnInit {

  @Input() chat: Chat;
  @Input() pedido: any;

  constructor(private popoverController: PopoverController,
              private chatService: ChatService) { }

  ngOnInit(): void {
  }

  dismiss() {
    this.popoverController.dismiss();
  }

  sendByWpp() {
    this.chatService.sendPedidoByWpp(this.chat);
  }

}
