import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment.prod';
import { FirestoreService } from '../../../services/firestore.service';

@Component({
  selector: 'app-question-notification',
  templateUrl: './question-notification.component.html',
  styleUrls: ['./question-notification.component.scss']
})
export class QuestionNotificationComponent implements OnInit {

  @Input() msg: string = '¿Deseas recibir notificaciones acerca de tus reservas?';
  suscriberInfo: Subscription;
  info: any = null;

  constructor(private popoverController: PopoverController,
              public firestoreService: FirestoreService) { 
      const path = 'Tienda/' + environment.uidAdmin + '/info/' + environment.uidAdmin;
      this.suscriberInfo = this.firestoreService.getDocumentChanges<any>(path).subscribe( res => {
              this.info = res;
      });
  }

  ngOnInit(): void {
  }

  cancelar() {
      this.popoverController.dismiss(false);
  }

  aceptar() {
      this.popoverController.dismiss(true);
  }

  ngOnDestroy() {
    if (this.suscriberInfo) {
       this.suscriberInfo.unsubscribe();
    }
  }

}
