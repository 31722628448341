import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ChatsComponent } from './chats/chats.component';
import { canActivate } from '@angular/fire/compat/auth-guard';
import { isAdminOrAsesor } from '../users/models-users';


const routes: Routes = [
  {path: '', component: ChatsComponent, ...canActivate(isAdminOrAsesor)},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ChatRoutingModule { }
