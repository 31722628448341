

import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { IonContent, ModalController, PopoverController } from '@ionic/angular';
import { Subscription, Subject } from 'rxjs';
import { FirestoreService } from '../../../services/firestore.service';
import { InteraccionService } from '../../../services/interaccion.service';
import { NotificationApp, UserInfo } from '../../../models';
import { NotificationsService } from '../../../notifications/services/notifications.service';
import { InputPhone } from '../../../widgets/crudfast/componentes/inputphone/inputphone.component';
import { AutenticationService } from '../../../services/autentication.service';
import { ChatService } from '../../../chat/services/chat.service';
import { Chat, UserChat } from '../../../chat/models-chat';
import { ChatbotService } from '../../services/chatbot.service';
import { PopLoginComponent } from '../../../common/componentes/pop-login/pop-login.component';
import { ParametrosChatbotI } from '../../backend/models-chatbot-crud';
import { FormBuilder, Validators } from '@angular/forms';
import { WindowService } from '../../../services/window.service';

@Component({
  selector: 'app-chatpop',
  templateUrl: './chatpop.component.html',
  styleUrls: ['./chatpop.component.scss'],
})
export class ChatpopComponent implements OnInit, OnDestroy {

  @Input() infoChatbot: UserChat;
  chats: Chat[] = [];
  chatsCopy: any = [];
  suscriberChats: Subscription;
  suscriberObsChat: Subscription;
  parametrosChatbot: ParametrosChatbotI;

  suscriberLastConexion: Subscription;
  suscriberParametrosChatbot: Subscription;

  mensajeText = '';
  autoGrow = true;
  enable = false;

  loadMore = false;
  ultConexion = '';
  phoneNumberReceptor: InputPhone = null;
  nombreChat = '';
  infoUser: UserChat;
  pressLoadMore: boolean = false;


  @ViewChild('content', { static: false }) content: IonContent;

  datosForm = this.fb.group({
    celular: [null, Validators.required],
  });
  emitChangeCelular = new Subject<InputPhone>();
  celular = {
    number: null,
    countryCode: null,
    e164Number: null
  }
  isLogin: boolean = false;
  loadinLogin: boolean = true;
  loadinInfo: boolean = true;
  withoutCelular: boolean = false;
  
  constructor(private chatService: ChatService,
              private firestoreService: FirestoreService,
              private autenticationService: AutenticationService,
              public modalController: ModalController,
              private popoverController: PopoverController,
              private interaccionService: InteraccionService,
              private notificationsService: NotificationsService,
              public chatbotService: ChatbotService,
              private fb: FormBuilder,
              private windowService: WindowService) { 

                this.parametrosChatbot = this.chatbotService.getParametrosChatbot();
                this.suscriberParametrosChatbot = this.chatbotService.getParametrosChatbotChanges().subscribe( res => {
                    this.parametrosChatbot = res; 
                });

                this.autenticationService.authState().subscribe( async (res) => {
                    this.isLogin = res ? true : false;
                    if (res) {
                      if (res.displayName) {
                        let name = '<p>Hola ' + res.displayName + '</p';
                        name = name + '<p>' +  this.parametrosChatbot.mensajeBienvenida + '</p';
                        this.chatbotService.respondBievenidaWithName(name);
                      } else {
                        this.chatbotService.initChatBot();  
                      }
                    } else {
                      this.chatbotService.initChatBot();
                    }
                    const info = await this.loadInfoUser();
                    if (info) {
                      this.chatbotService.setChat(this.infoUser, this.infoChatbot);
                      if (!info.celular) {
                        this.respondWithNeedCelular();
                      }
                    }
                    // console.log('info user', info);
                    this.gotoBottom();
                    this.loadinLogin = false;
                });

              }

  ngOnInit(): void {
      this.loadChatsMulti();  
  }

  ngOnDestroy() {
      if (this.suscriberChats) {
         this.suscriberChats.unsubscribe();
      }
      if (this.suscriberObsChat) {
        this.suscriberObsChat.unsubscribe();
      }
      if (this.suscriberLastConexion) {
        this.suscriberLastConexion.unsubscribe();
      }
      if (this.suscriberParametrosChatbot) {
        this.suscriberParametrosChatbot.unsubscribe();
      }
  }

  loadInfoUser(): Promise<UserChat | null> {
     return new Promise( async (resolve, reject) => {
          if (this.infoUser) {
            resolve(this.infoUser);
            return;
          } else {
              const uid = await this.autenticationService.getUid();
              if (uid) {
                const path = 'Usuarios/' + uid;
                const doc = await this.firestoreService.getDocument<UserInfo>(path);
                if (doc.exists) {
                    const data = doc.data();
                    this.infoUser = {
                      uid: data.id,
                      nombre: data.nombre,
                      email: data.email,
                      celular: data.celular ? data.celular : null,
                      photo: data.photoUrl ? data.photoUrl : null
                    }
                    this.loadinInfo = true;
                    this.withoutCelular = data.celular ? false : true;
                    resolve(this.infoUser);
                    return;
                }
              } 
              resolve(null);
              return;
          }
     });
  }

  loadChatsMulti() {
      this.getNombreChat();
      this.getUltimaConexion();
      if (this.suscriberChats) {
        this.suscriberChats.unsubscribe();
      }
      this.gotoBottom();
      this.suscriberChats = this.chatbotService.getChatsMultiChanges().subscribe( res => {
          if (res) {
              // console.log('loadChatsMulti');
              // console.log('loadChatsMulti -> ', res);
              this.chats = res;
              this.setDayOfChat();
              if (res[res.length - 1].resend) {
                this.chatbotService.thinking = false;
              } 
              if (!this.pressLoadMore) {
                setTimeout(() => {
                  this.gotoBottom();
                }, 400);
              }
              this.loadMore = false;
          }
      });

  }

  leerMoreChatMulti() {
    this.loadMore = true;
    this.pressLoadMore = true;
    this.chatbotService.leerMoreChatsMulti(this.infoChatbot.uid);
  }

  sendMsgByWpp(chat: Chat) {
    if (chat.receptor.celular) {
      let url = '';
      if (chat.receptor.celular) {
        if (chat.receptor.celular.e164Number) {
          const cellnumber = chat.receptor.celular.e164Number.replace('+', '');
          url = 'https://wa.me/' + cellnumber;
        }
      }
      url = url + '?text=';
      const mensaje = chat.mensaje.replace(/ /g, '%20');
      url = url + mensaje;
      window.open(url);
    }
  }

  setDayOfChat() {
    this.chats.sort((n1, n2) => {
      let fecha1: Date;
      let fecha2: Date;
      fecha1 = n1.time.seconds ? new Date(n1.time.seconds * 1000) : n1.time;
      fecha2 = n2.time.seconds ? new Date(n2.time.seconds * 1000) : n2.time;
      if (fecha1> fecha2) {
          return 1;
      }
      if (fecha1 < fecha2) {
          return -1;
      }
      return 0;
    });
    this.chatsCopy = [];
    this.chatsCopy = JSON.parse(JSON.stringify(this.chats));
    let day0 = new Date();
    this.chatsCopy.forEach( (chat, index) => {
        let day: Date;
        if (chat.time.seconds) {
          day = new Date (chat.time.seconds * 1000);
        } else {
          day = new Date(chat.time);
        }
        chat['timeString'] = this.getTimeChat(day);
        this.chatsCopy[index].time = 0;
        if ( !this.isEqualDay(day0, day)) {
          const now = new Date();
          let infoDay = '';
          if ( now.getFullYear() === day.getFullYear() &&
               now.getMonth() === day.getMonth() ) {
               const difDate = now.getDate() - day.getDate();
               if ( difDate === 0) {
                infoDay = 'hoy';
               } else if (difDate === 1) {
                  infoDay = 'ayer';
               }
          }
          if (infoDay === '') {
              const month  = day.getMonth() + 1;
              let monthString: string;
              if (month < 10 ) {
                  monthString = '0' + month;
              } else  {
                monthString = '' + month;
              }
              const date  = day.getDate();
              let dayString: string;
              if (date < 10 ) {
                  dayString = '0' + date;
              } else  {
                dayString = '' + date;
              }
              infoDay = day.getFullYear() + '-' + monthString + '-'
                              + dayString;
          }
          this.chatsCopy[index].time = infoDay;
        }
        day0 = day;
    });
  }

  isEqualDay(day1: Date, day2: Date): boolean {
     const stringDay1 = day1.getFullYear() + '-' + day1.getMonth() + '-' + day1.getDate();
     const stringDay2 = day2.getFullYear() + '-' + day2.getMonth() + '-' + day2.getDate();
     if (stringDay1 === stringDay2) {
        return true;
     } else {
        return false;
     }
  }
   
  getTimeChat(date: Date) {
      let time: string;
      if (date.getMinutes() < 10) {
        time = date.getHours() + ':0' + date.getMinutes();
      } else {
        time = date.getHours() + ':' + date.getMinutes();
      }
      return time;
  }

  updatePhoneNumberReceptor() {
    if (this.phoneNumberReceptor) {
      this.chatsCopy.forEach( (chat: Chat) => {
            if (chat.emisor) {
              if (chat.emisor.uid == this.infoChatbot.uid) {
                chat.emisor.celular = this.phoneNumberReceptor;
              } 
            }
            if (chat.receptor) {
              if (chat.receptor.uid == this.infoChatbot.uid) {
                chat.receptor.celular = this.phoneNumberReceptor;
              }
            }
      });
    }
  }

  handleKeyDown(event: any) {
    if (event.code === 'Enter') {
        this.sendChat();
    }
  }

  async openPopLogin() {
      await this.interaccionService.presentLoading('cargando...');
      const popover = await this.popoverController.create({
        component: PopLoginComponent,
        cssClass: 'popoverSearch',
        mode: 'ios',
        backdropDismiss: false,
      });
      this.interaccionService.dismissLoading();
      await popover.present();
      const {data} = await popover.onWillDismiss();
      if (data) {
        let name = '<p>Hola ' +  data.nombre + '</p';
        name = name + '<p>' +  this.parametrosChatbot.mensajeBienvenida + '</p';
        this.chatbotService.respondBievenidaWithName(name);
      }
  }

  async sendChat() {
      this.pressLoadMore = false;
      if (!this.mensajeText) { return} ;
      const uid = await this.autenticationService.getUid();
      let loadMoreChat = false;
      
      if (!uid) {
          loadMoreChat = true;
          this.respondWithNeedLogin();
          await this.windowService.setTimeOut(1500);
          await this.openPopLogin();
          const info = await this.loadInfoUser();
          if (info) {
            this.chatbotService.setChat(info, this.infoChatbot);
          } else {
              return;
          }
      }
      const mensaje = this.mensajeText; 
      console.log('sendChat', mensaje);
      this.mensajeText = '';
      this.chatService.sendChat(mensaje, 'string', this.infoChatbot, this.infoUser).then( res => {
        if (loadMoreChat) {            
          this.leerMoreChatMulti();
        }
        if (!this.chatbotService.disabledChatbot) {
          this.chatbotService.sendQuestion({question: mensaje});
        }
      });
      if (!this.chatbotService.disabledChatbot) {
        this.chatbotService.thinking = true;
      }
      setTimeout(() => {
        this.gotoBottom();
      }, 200);

  
  }

  sendNewNotification(mensaje: string, emisor: UserChat, receptor: UserChat) {
      this.mensajeText = '';
      const notification: NotificationApp = {
        type: 'chat',
        titulo: emisor.nombre,
        descripcion: mensaje,
        enlace: '/chats/' + emisor.uid
      }
      this.notificationsService.sendNewNotification(notification, receptor.uid);        
  }

  gotoBottom() {
      let bottom = document.getElementById('bottom');
      if (bottom !== null) {
          // console.log('gotoBottom()');
          bottom.scrollIntoView({behavior: 'smooth'});
          bottom = null;
      } else {
        setTimeout(() => {
          this.gotoBottom() 
        }, 500);
      }
  }

  getNombreChat() {
    this.nombreChat = this.infoChatbot.nombre;
  }

  getUltimaConexion() {
    const path = 'Usuarios/' + this.infoChatbot.uid;
    this.suscriberLastConexion = this.firestoreService.getDocumentChanges<any>(path).subscribe( res => {
          if (res) {
              // console.log('getUltimaConexion() -> ', res);
              // console.log('getUltimaConexion() -> ');
              const lastConexion = res.lastConexion;
              if (res.celular) {
                this.phoneNumberReceptor = res.celular;
                this.updatePhoneNumberReceptor();
              }
              this.ultConexion = this.getStringUltimaConexion(lastConexion.seconds);
          }
    });
 
  }

  getStringUltimaConexion(timeStampSecods: number): string {
    const ultConeccion = new Date (timeStampSecods * 1000);
    const now = new Date();
    if ( now.getFullYear() === ultConeccion.getFullYear() &&
         now.getMonth() === ultConeccion.getMonth() ) {

         const difDate = now.getDate() - ultConeccion.getDate();
         if ( difDate === 0) {
             if (now.getHours() === ultConeccion.getHours()) {
                const dif = now.getMinutes() - ultConeccion.getMinutes();
                if ( dif < 1 && dif > -1 ) {
                    return 'en linea';
                }
             }
             return 'ult vez: hoy a las ' + this.getTimeChat(ultConeccion);
         } else if (difDate === 1) {
           return 'ult vez: ayer a las ' + this.getTimeChat(ultConeccion);
         }
    }
    return 'ult vez: ' +  ultConeccion.getDate() + '/' +
                  (ultConeccion.getMonth() + 1) + '/' + ultConeccion.getFullYear() +
                   ' a las ' + this.getTimeChat(ultConeccion);
  }

  copyMsg() {
    this.interaccionService.showToast('mensaje copiado');
  }

  dismiss() {
      this.popoverController.dismiss();
  }

  async onSubmit() {
      if (this.datosForm.valid) {
            await this.interaccionService.presentLoading('Guardando...')
            console.log('onSubmit()');
            const uid = this.infoUser.uid;
            this.infoUser.celular = this.celular;
            const path = 'Usuarios/';
            const updataDoc = {
                celular: this.infoUser.celular
            };
            this.chatbotService.setChat(this.infoUser, this.infoChatbot);
            await this.firestoreService.updateDocumentID(updataDoc, path, uid);
            this.interaccionService.dismissLoading();
            this.withoutCelular = false;
            let mensaje = '<p>Gracias ' + this.infoUser.nombre + ' por dejarnos tu número de contacto' + '</p';
            this.chatbotService.putRespuestaChatbot(mensaje);
          }
  }

  changeCelular(ev: InputPhone) {
    if (ev) {
        //  console.log('changeCelular -> ', ev);
         this.datosForm.controls['celular'].setValue(ev.number);
         this.celular.countryCode = ev.countryCode;
         this.celular.e164Number = ev.e164Number;
         this.celular.number = ev.number;
     } else {
        this.datosForm.controls['celular'].setValue(null);
     }
  }

  respondWithNeedLogin() {
      const mensaje:  string = 'Para ofrecerte una mejor experiencia como tu asistente vitual, es importante que primero ingreses o te registres';
      const opciones: { texto: string; action: any; } [] = [
        { texto: 'Ingresar',
          action: () => {
            this.openPopLogin();
          }
        }
      ];
      this.chatbotService.respondWithNeedLogin(mensaje, opciones);
  }

  respondWithNeedCelular() {
    let mensaje:  string = '<p>¿Deseas dejarnos tu número de celular?</p>';
    mensaje = mensaje + '<p>Si es necesario nos pondremos en contacto contigo</p>';
    const opciones: { texto: string; action: any; } [] = [
      { texto: 'NO',
        action: () => {
          if (this.withoutCelular) {
            this.chatbotService.enableGetCelular = false;
          }
        }
      }, 
      { texto: 'SI',
        action: () => {
          console.log('si click -> ', this.withoutCelular);   
          if (this.withoutCelular) {
            this.chatbotService.enableGetCelular = true; 
              setTimeout(() => {
                this.gotoBottom() 
              }, 200);
              let input = { number: null, countryCode: null, e164Number: null };
              setTimeout(() => {
                this.emitChangeCelular.next(input);
              }, 2000);
           }
        }
      }
    ];
    this.chatbotService.respondWithNeedCelular(mensaje, opciones);
  }



}

