import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { IonicModule } from '@ionic/angular';
import { FooterComponent } from './footer/footer.component';
import { BannerComponent } from './banner/banner.component';
import { FormsModule } from '@angular/forms';
import { CookiesComponent } from './cookies/cookies.component';
import { SidemenuComponent } from './sidemenu/sidemenu.component';
import { ShareComponent } from './share/share.component';
import { RouterModule } from '@angular/router';
import { AngularModule } from '../../angular.module';
import { PipesModule } from '../../pipes/pipes.module';
import { PopLoginComponent } from './pop-login/pop-login.component';
import { PopNotificationsComponent } from '../../notifications/componentes/pop-notifications/pop-notifications.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatTabsModule } from '@angular/material/tabs';
import { CategoriasComponent } from './categorias/categorias.component';





@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    BannerComponent,
    CookiesComponent,
    SidemenuComponent,
    ShareComponent,
    PopLoginComponent,
    PopNotificationsComponent,
    CategoriasComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    AngularModule,
    FormsModule,
    // SwiperModule,
    PipesModule,
    TranslateModule,
    MatTabsModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    BannerComponent,
    CookiesComponent,
    SidemenuComponent,
    PopLoginComponent,
    CategoriasComponent
  ]
})
export class ComponentesModule { }
