import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CategoriaItem } from 'src/app/widgets/crudfast/models-crud';
import { Apariencia, AppearanceService } from '../../../services/appearance.service';


@Component({
  selector: 'app-categorias',
  templateUrl: './categorias.component.html',
  styleUrls: ['./categorias.component.scss'],
})
export class CategoriasComponent implements OnInit {

  @Input() categorias: CategoriaItem[] = [];
  @Input() isSubcategoria: boolean = false;
  @Output() selecCategoria = new EventEmitter<CategoriaItem[]>()
  apariencia: Apariencia;   // ---- template

  constructor(  private appearanceService: AppearanceService) {
        this.loadAppearance();
   }

  ngOnInit(): void {
  }

  loadAppearance() {   // ---- templates
    this.apariencia = this.appearanceService.getApariencia();
    this.appearanceService.getAparienciaChanges().subscribe( apariencia => {
      this.apariencia = apariencia;  
    });
  }

}