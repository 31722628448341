import { Component, OnInit, Input } from '@angular/core';
import { Apariencia } from '../../services/appearance.service';
import { Parametros } from '../../../environments/parametros';
import { ConocenosI } from '../backend/models-pages-crud';
import { Router } from '@angular/router';

@Component({
  selector: 'app-conocenos',
  templateUrl: './conocenos.component.html',
  styleUrls: ['./conocenos.component.scss'],
})
export class ConocenosComponent implements OnInit {

  @Input() page: ConocenosI;
  apariencia: Apariencia = Parametros.apariencia;

  constructor(private router: Router) { }

  ngOnInit() {
      console.log('page -> ConocenosComponent');
      console.log(this.router.url);
      // console.log(this.router.);
      
  }

}
