import { Component, OnInit, Output, EventEmitter} from '@angular/core';
import { environment } from '../../../../environments/environment.prod';
import { AutenticationService } from '../../../services/autentication.service';
import { PopoverController } from '@ionic/angular';
import { PopLoginComponent } from '../pop-login/pop-login.component';
import { FrontendService, Menu } from '../../../services/frontend.service';
import { Subscription } from 'rxjs';
import { RolesUsers } from '../../../users/models-users';
import { Parametros } from '../../../../environments/parametros';
import { WindowService } from '../../../services/window.service';
import { LocalStorageService } from '../../../services/local-storage.service';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss']
})
export class SidemenuComponent implements OnInit {

  @Output() darkMode = new EventEmitter<boolean>();
  menu: Menu[] = [];
  suscriberAuth: Subscription;

  version = environment.version;
  rol: RolesUsers = null;
  rolLoaded: RolesUsers = null;
  dark = false;

  user = { email: '', photoURL: 'https://ionicframework.com/docs/demos/api/avatar/avatar.svg', displayName: '' }

  constructor(public autenticationService: AutenticationService,
              private popoverController: PopoverController,
              private frontendService: FrontendService,
              private windowService: WindowService,
              private localStorageService: LocalStorageService) {

            if (this.windowService.isServer()) {
              // this.loadSideMenu();
              return;
            }    
            this.loadDarkMode();
            this.suscriberAuth = this.autenticationService.authState().subscribe( res => {
                if (res) {
                  this.user.displayName = res.displayName;
                  this.user.email = res.email;
                  if (res.photoURL) {
                      this.user.photoURL = res.photoURL;
                  }
                  res.getIdTokenResult().then( metadata => {
                      // console.log('metadata -> ', metadata)
                      const claims = metadata.claims as any;
                      this.getRol(claims)
                  })
                }
                this.loadSideMenu();
            });


  }

  ngOnInit(): void {}

  changeTheme(ev: any) {
    this.darkMode.emit(ev.detail.checked); 
  }

  async openPopLogin() {
    const modal = await this.popoverController.create({
      component: PopLoginComponent,
      mode: 'ios',
      cssClass: 'popoverLogin',
    });
    await modal.present();
  }

  getRol(claims: { rol : RolesUsers, user_id: string }) {
      this.rolLoaded = claims.rol ? claims.rol : 'usuario';
      if (claims.user_id === Parametros.uidAdmin) {
         this.rol = 'admin';
      } else {
        this.rol = claims.rol ? claims.rol : 'usuario';
      }
      console.log('getRol -> ', this.rol);
      this.loadSideMenu();
  }

  loadSideMenu() {
      this.menu = [];
      this.menu = this.frontendService.getSideMenu();
      // console.log('loadSideMenu()');
      this.frontendService.getSideMenuChanges().subscribe( res => {
        this.menu = res;
        // console.log('loadSideMenuChanges()');
      });
  }

  async ingresar() {
    const modal = await this.popoverController.create({
      component: PopLoginComponent,
      mode: 'ios',
      cssClass: 'popoverLogin',
    });
    await modal.present();
  }

  async logout() {
      await this.autenticationService.logout();
      window.location.reload();
  }

  async loadDarkMode() {
    const path = 'darkMode';
    const modeSave = await this.localStorageService.getDoc(path);  
    if (modeSave) {
        this.dark = modeSave.dark;
    }
  }


}





