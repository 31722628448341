import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment.prod';
import { Email } from '../models-notifications';

@Injectable({
  providedIn: 'root'
})
export class SendemailService {

  constructor(private http: HttpClient) { }

  sendEmail(email: Email): Promise<boolean> {
    return new Promise(  async (resolve) => {
          const url = 'https://us-central1-' + environment.firebaseConfig.projectId + '.cloudfunctions.net/sendEmailServer';    
          const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                // 'Access-Control-Allow-Origin': '*'
            })
          };
          // console.log('sendEmail -> ', email);
          this.http.post<boolean>(url, {email}, httpOptions).subscribe( res => {
                resolve(res)
          });
    });
  }


}


