import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection,
  AngularFirestoreCollectionGroup
} from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {

  constructor(public FireStore: AngularFirestore) { }

  getCollectionChanges<tipo>(path: string): Observable<tipo[]> {
    const itemsCollection: AngularFirestoreCollection<tipo> =
      this.FireStore.collection<tipo>(path);
    return itemsCollection.valueChanges();
  }

  getCollection<tipo>(path: string) {
    const itemsCollection: AngularFirestoreCollection<tipo> =
                      this.FireStore.collection<tipo>(path);
    return itemsCollection.ref.get();
  }

  getDocument<tipo>(enlace: string) {
    const itemDoc: AngularFirestoreDocument<tipo> = this.FireStore.doc<tipo>(enlace);
    return itemDoc.ref.get();
  }

  getDocumentChanges<tipo>(enlace: string): Observable<tipo> {
    const itemDoc: AngularFirestoreDocument<tipo> = this.FireStore.doc<tipo>(enlace);
    return itemDoc.valueChanges();
  }

  createDocument<tipo>(data: tipo, enlace: string) {
    const itemsCollection: AngularFirestoreCollection<tipo> =
      this.FireStore.collection<tipo>(enlace);
    return itemsCollection.add(data);
  }

  async createDocumentID<tipo>(data: tipo, enlace: string, idDoc: string) {
    const itemsCollection: AngularFirestoreCollection<tipo> =
      this.FireStore.collection<tipo>(enlace);
    return itemsCollection.doc(idDoc).set(data);
  }

  async updateDocumentID<tipo>(data: tipo, enlace: string, idDoc: string) {
    const itemsCollection: AngularFirestoreCollection<tipo> =
      this.FireStore.collection<tipo>(enlace);
    return itemsCollection.doc(idDoc).update(data);
  }

  createIdDoc(): string {
    return this.FireStore.createId();
  }

  deleteDocumentID(enlace: string, idDoc: string) {
    const itemsCollection: AngularFirestoreCollection =
      this.FireStore.collection(enlace);
    return itemsCollection.doc(idDoc).delete();
  }

  getCollectionQuery<tipo>(path: string, parametro: string, busqueda: any): Observable<tipo[]> {
    const itemsCollection: AngularFirestoreCollection<tipo> =
      this.FireStore.collection<tipo>(path
        , ref => ref.where(parametro, '==', busqueda));
    return itemsCollection.valueChanges();
  }

  getCollectionOrderLimit<tipo>(path: string, LIMIT: number, orderId: string, directionSort: 'asc' | 'desc',
    STARTAT?: any): Observable<tipo[]> {
    if (STARTAT === null) {
      const itemsCollection: AngularFirestoreCollection<tipo> =
        this.FireStore.collection<tipo>(path
          , ref => ref.orderBy(orderId, directionSort)
            .limit(LIMIT));
      return itemsCollection.valueChanges();
    } else {
      const itemsCollection: AngularFirestoreCollection<tipo> =
        this.FireStore.collection<tipo>(path
          , ref => ref.orderBy(orderId, directionSort)
            .startAfter(STARTAT).limit(LIMIT));
      return itemsCollection.valueChanges();
    }
  }

  getCollectionQueryOrderLimit<tipo>(path: string, parametro: string, busqueda: any,
    LIMIT: number, orderId: string, directionSort: 'asc' | 'desc',
    STARTAT?: any): Observable<tipo[]> {
    if (STARTAT === null) {
      const itemsCollection: AngularFirestoreCollection<tipo> =
        this.FireStore.collection<tipo>(path
          , ref => ref.where(parametro, '==', busqueda)
            .orderBy(orderId, directionSort)
            .limit(LIMIT));
      return itemsCollection.valueChanges();
    } else {
      const itemsCollection: AngularFirestoreCollection<tipo> =
        this.FireStore.collection<tipo>(path
          , ref => ref.where(parametro, '==', busqueda)
            .orderBy(orderId, directionSort)
            .startAfter(STARTAT).limit(LIMIT));
      return itemsCollection.valueChanges();
    }
  }

  getCollection2QueryOrderLimit<tipo>(path: string,
    parametro1: string, condicion1: any, busqueda1: any,
    parametro2: string, condicion2: any, busqueda2: any,
    LIMIT: number, orderId: string, directionSort: 'asc' | 'desc',
    STARTAT?: any): Observable<tipo[]> {
    if (STARTAT === null) {
      const itemsCollection: AngularFirestoreCollection<tipo> =
        this.FireStore.collection<tipo>(path
          , ref => ref.where(parametro1, condicion1, busqueda1)
            .where(parametro2, condicion2, busqueda2)
            .orderBy(orderId, directionSort)
            .limit(LIMIT));
      return itemsCollection.valueChanges();
    } else {
      const itemsCollection: AngularFirestoreCollection<tipo> =
        this.FireStore.collection<tipo>(path
          , ref => ref.where(parametro1, condicion1, busqueda1)
            .where(parametro2, condicion2, busqueda2)
            .orderBy(orderId, directionSort)
            .startAfter(STARTAT)
            .limit(LIMIT));
      return itemsCollection.valueChanges();
    }
  }

  getCollection3QueryOrderLimit<tipo>(path: string,
    parametro1: string, condicion1: any, busqueda1: any,
    parametro2: string, condicion2: any, busqueda2: any,
    parametro3: string, condicion3: any, busqueda3: any,
    LIMIT: number, orderId: string, directionSort: 'asc' | 'desc',
    STARTAT?: any): Observable<tipo[]> {
    if (STARTAT === null) {
      const itemsCollection: AngularFirestoreCollection<tipo> =
        this.FireStore.collection<tipo>(path
          , ref => ref.where(parametro1, condicion1, busqueda1)
            .where(parametro2, condicion2, busqueda2)
            .where(parametro3, condicion3, busqueda3)
            .orderBy(orderId, directionSort)
            .limit(LIMIT));
      return itemsCollection.valueChanges();
    } else {
      const itemsCollection: AngularFirestoreCollection<tipo> =
        this.FireStore.collection<tipo>(path
          , ref => ref.where(parametro1, condicion1, busqueda1)
            .where(parametro2, condicion2, busqueda2)
            .where(parametro3, condicion3, busqueda3)
            .orderBy(orderId, directionSort)
            .startAfter(STARTAT)
            .limit(LIMIT));
      return itemsCollection.valueChanges();
    }
  }


  getCollectionQueryArray<tipo>(path: string, parametro: string, tags: any[], LIMIT: number,
    orderId: string, directionSort: 'asc' | 'desc', STARTAT?: any): Observable<tipo[]> {
    let itemsCollection: AngularFirestoreCollection<tipo>;
    if (STARTAT === null) {
      itemsCollection = this.FireStore.collection<tipo>(path
        , ref => ref.where(parametro, 'array-contains-any', tags)
          .orderBy(orderId, directionSort)
          .limit(LIMIT));
      return itemsCollection.valueChanges();
    }
    else {
      itemsCollection = this.FireStore.collection<tipo>(path
        , ref => ref.where(parametro, 'array-contains-any', tags)
          .orderBy(orderId, directionSort)
          .startAfter(STARTAT).limit(LIMIT));
      return itemsCollection.valueChanges();
    }
  }

  getCollectionAllQueryOrderLimit<tipo>(path: string, parametro: string, condicion: any, busqueda: string,
    orderId: string, directionSort: 'asc' | 'desc',
    limit: number, startAt: any) {
    let itemsCollection: AngularFirestoreCollectionGroup<tipo>;
    if (startAt == null) {
      itemsCollection = this.FireStore.collectionGroup<tipo>(path,
        ref => ref.where(parametro, condicion, busqueda)
          .orderBy(orderId, directionSort)
          .limit(limit)
      );
    }
    else {
      itemsCollection = this.FireStore.collectionGroup<tipo>(path,
        ref => ref.where(parametro, condicion, busqueda)
          .orderBy(orderId, directionSort)
          .limit(limit)
          .startAfter(startAt)
      );
    }
    return itemsCollection.valueChanges();
  }
  getCollectionAllOrderLimit<tipo>(path: string,
    orderId: string, directionSort: 'asc' | 'desc',
    limit: number, startAt: any) {
    let itemsCollection: AngularFirestoreCollectionGroup<tipo>;
    if (startAt == null) {
      itemsCollection = this.FireStore.collectionGroup<tipo>(path,
        ref => ref.orderBy(orderId, directionSort)
          .limit(limit)
      );
    }
    else {
      itemsCollection = this.FireStore.collectionGroup<tipo>(path,
        ref => ref.orderBy(orderId, directionSort)
          .limit(limit)
          .startAfter(startAt)
      );
    }
    return itemsCollection.valueChanges();
  }

  getCollection2AllQueryOrderLimit<tipo>(path: string,
    parametro1: string, condicion1: any, busqueda1: any,
    parametro2: string, condicion2: any, busqueda2: any,
    orderId: string, directionSort: 'asc' | 'desc',
    limit: number, startAt: any) {
    let itemsCollection: AngularFirestoreCollectionGroup<tipo>;
    if (startAt == null) {
      itemsCollection = this.FireStore.collectionGroup<tipo>(path,
        ref => ref.where(parametro1, condicion1, busqueda1)
          .where(parametro2, condicion2, busqueda2)
          .orderBy(orderId, directionSort)
          .limit(limit)
      );
    }
    else {
      itemsCollection = this.FireStore.collectionGroup<tipo>(path,
        ref => ref.where(parametro1, condicion1, busqueda1)
          .where(parametro2, condicion2, busqueda2)
          .orderBy(orderId, directionSort)
          .limit(limit)
          .startAfter(startAt)
      );
    }
    return itemsCollection.valueChanges();
  }

  getCollection3AllQueryOrderLimit<tipo>(path: string, parametro1: string, condicion1: any, busqueda1: any,
    parametro2: string, condicion2: any, busqueda2: any,
    parametro3: string, condicion3: any, busqueda3: any,
    orderId: string, directionSort: 'asc' | 'desc',
    limit: number, startAt: any) {
    let itemsCollection: AngularFirestoreCollectionGroup<tipo>;
    if (startAt == null) {
      itemsCollection = this.FireStore.collectionGroup<tipo>(path,
        ref => ref.where(parametro1, condicion1, busqueda1)
          .where(parametro2, condicion2, busqueda2)
          .where(parametro3, condicion3, busqueda3)
          .orderBy(orderId, directionSort)
          .limit(limit)
      );
    }
    else {
      itemsCollection = this.FireStore.collectionGroup<tipo>(path,
        ref => ref.where(parametro1, condicion1, busqueda1)
          .where(parametro2, condicion2, busqueda2)
          .where(parametro3, condicion3, busqueda3)
          .orderBy(orderId, directionSort)
          .limit(limit)
          .startAfter(startAt)
      );
    }
    return itemsCollection.valueChanges();

  }

  getCollection4AllQueryOrderLimit<tipo>(path: string, parametro1: string, condicion1: any, busqueda1: string,
    parametro2: string, condicion2: any, busqueda2: any,
    parametro3: string, condicion3: any, busqueda3: any,
    parametro4: string, condicion4: any, busqueda4: any,
    orderId: string, directionSort: 'asc' | 'desc',
    limit: number, startAt: any) {
    let itemsCollection: AngularFirestoreCollectionGroup<tipo>;
    if (startAt == null) {
      itemsCollection = this.FireStore.collectionGroup<tipo>(path,
        ref => ref.where(parametro1, condicion1, busqueda1)
          .where(parametro2, condicion2, busqueda2)
          .where(parametro3, condicion3, busqueda3)
          .where(parametro4, condicion4, busqueda4)
          .orderBy(orderId, directionSort)
          .limit(limit)
      );
    }
    else {
      itemsCollection = this.FireStore.collectionGroup<tipo>(path,
        ref => ref.where(parametro1, condicion1, busqueda1)
          .where(parametro2, condicion2, busqueda2)
          .where(parametro3, condicion3, busqueda3)
          .where(parametro4, condicion4, busqueda4)
          .orderBy(orderId, directionSort)
          .limit(limit)
          .startAfter(startAt)
      );
    }
    return itemsCollection.valueChanges();

  }

  getCollection5AllQueryOrderLimit<tipo>(path: string, parametro1: string, condicion1: any, busqueda1: string,
    parametro2: string, condicion2: any, busqueda2: string,
    parametro3: string, condicion3: any, busqueda3: any,
    parametro4: string, condicion4: any, busqueda4: any,
    parametro5: string, condicion5: any, busqueda5: any,
    orderId: string, directionSort: 'asc' | 'desc',
    limit: number, startAt: any) {
    let itemsCollection: AngularFirestoreCollectionGroup<tipo>;
    if (startAt == null) {
      itemsCollection = this.FireStore.collectionGroup<tipo>(path,
        ref => ref.where(parametro1, condicion1, busqueda1)
          .where(parametro2, condicion2, busqueda2)
          .where(parametro3, condicion3, busqueda3)
          .where(parametro4, condicion4, busqueda4)
          .where(parametro5, condicion5, busqueda5)
          .orderBy(orderId, directionSort)
          .limit(limit)
      );
    }
    else {
      itemsCollection = this.FireStore.collectionGroup<tipo>(path,
        ref => ref.where(parametro1, condicion1, busqueda1)
          .where(parametro2, condicion2, busqueda2)
          .where(parametro3, condicion3, busqueda3)
          .where(parametro4, condicion4, busqueda4)
          .where(parametro5, condicion5, busqueda5)
          .orderBy(orderId, directionSort)
          .limit(limit)
          .startAfter(startAt)
      );
    }
    return itemsCollection.valueChanges();

  }




}
