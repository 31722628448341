import { Component, OnInit, OnDestroy } from '@angular/core';
import { NotificationsService } from '../../services/notifications.service';
import { Subscription } from 'rxjs';
import { AutenticationService } from '../../../services/autentication.service';
import { environment } from '../../../../environments/environment';
import { PopoverController } from '@ionic/angular';
import { FirestoreService } from '../../../services/firestore.service';
import { NotificationApp } from '../../models-notifications';
declare var $: any;


declare var $: any;


@Component({
  selector: 'app-pop-notifications',
  templateUrl: './pop-notifications.component.html',
  styleUrls: ['./pop-notifications.component.scss']
})
export class PopNotificationsComponent implements OnInit, OnDestroy {

  notificaciones: NotificationApp[] = [];
  suscriberNotifications: Subscription;

  permission = false;

  moreEnable = true;

  constructor(private notificationsService: NotificationsService,
              private autenticationService: AutenticationService,
              private firestoreService: FirestoreService,
              private popoverController: PopoverController) {
        this.suscriberNotifications = this.notificationsService.getNotificationChanges().subscribe( res => {
              this.notificaciones = res;
              this.notificaciones.forEach( notification => {
                notification.descripcion = $(notification.descripcion).text();
              })
              // console.log('this.notificaciones -> ', this.notificaciones);
        });
        this.getPermission();
   }

  ngOnInit(): void {
       this.notificationsService.stopSound();
  }

  ngOnDestroy() {
     if (this.suscriberNotifications) {
         this.suscriberNotifications.unsubscribe();
     }
  }

  habilitarNotifications() {
      this.notificationsService.inicializar(true);
  }

  async getPermission() {
    const permission = await this.notificationsService.getPermission();
    if (permission) {
       this.permission = true;
    } else {
      this.permission = false;
    }
  }

  async setViewNotification(notification: NotificationApp) {
      const Uid = await this.autenticationService.getUid();
      const path = environment.pathRoot + Uid + '/notifications';
      const uddateDoc = {
        view: true,
      }
      notification.view = true;
      this.firestoreService.updateDocumentID(uddateDoc, path, notification.id);
      this.popoverController.dismiss();
  }

  async loadMore() {
      this.moreEnable = await this.notificationsService.getMoreNoticationsUser();
  }

  goToNotification() {
      this.notificationsService.stopSound();
  }

  deleteNotificacion(notificacion: NotificationApp, index: number) {
      this.notificationsService.deleteNotification(notificacion);
      this.notificaciones.splice(index, 1);
      console.log('delete')
  }

}
