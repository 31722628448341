import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormComponent } from './componentes/form/form.component';
import { CategoriasComponent } from './componentes/categorias/categorias.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {MatExpansionModule} from '@angular/material/expansion';
import { ConstructorComponent } from './componentes/constructor/constructor.component';
import { CKEditorModule } from 'ckeditor4-angular';
import { PipesModule } from '../../pipes/pipes.module';
import {ClipboardModule} from '@angular/cdk/clipboard';
import { EditorComponent } from './componentes/editor/editor.component';
import { SearchComponent } from './componentes/search/search.component';
// import { GooglemapsModule } from '../googlemaps/googlemaps.module';
import { CssEditorComponent } from './componentes/css-editor/css-editor.component';
import { LoaderModule } from '../loader/loader.module';
import { SelectComponent } from './componentes/select/select.component';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule} from '@angular/material/core';
import { ColorPickerModule } from 'ngx-color-picker';
import { InputphoneComponent } from './componentes/inputphone/inputphone.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { MultimediaModule } from '../../multimedia/multimedia.module';

@NgModule({
  declarations: [
    FormComponent,
    CategoriasComponent,
    ConstructorComponent,
    EditorComponent,
    SearchComponent,
    CssEditorComponent,
    SelectComponent,
    InputphoneComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    MatExpansionModule,
    CKEditorModule,
    PipesModule,
    ClipboardModule,
    // GooglemapsModule,
    LoaderModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    ColorPickerModule,
    NgxIntlTelInputModule,
    MultimediaModule
  ], 
  exports: [
    FormComponent,
    CategoriasComponent,
    EditorComponent,
    InputphoneComponent
  ]
})
export class CrudfastModule { }
