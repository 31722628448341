import { Component, Input, OnInit, ComponentFactoryResolver, Renderer2, ViewChild, OnDestroy, ViewContainerRef } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { WindowService } from '../../../services/window.service';
import { HeaderComponent } from '../../../common/componentes/header/header.component';
import { FooterComponent } from '../../../common/componentes/footer/footer.component';
import { RecommenderComponent } from '../../../post/componentes/recommender/recommender.component';
import { ReceptorDirective } from '../receptor.directive';

import { SuscripcionComponent } from '../../../notifications/componentes/suscripcion/suscripcion.component';
import { HometravelComponent } from '../../../travel/pages/hometravel/hometravel.component';


@Component({
  selector: 'app-loader-componentes',
  templateUrl: './loader-componentes.component.html',
  styleUrls: []
})
export class LoaderComponentesComponent implements OnInit, OnDestroy {

  @Input () ChangeHtml: Observable<void>;
  suscriberHtmlChange: Subscription;

  listComponente = [
    {
      nombre: 'header',
      componente: HeaderComponent
    },
    {
      nombre: 'footer',
      componente: FooterComponent,
    },
    {
      nombre: 'suscripcion',
      componente: SuscripcionComponent
    },
    {
      nombre: 'recommender',
      componente: RecommenderComponent
    },
    {
      nombre: 'travel',
      componente: HometravelComponent
    }
  ];

 @ViewChild(ReceptorDirective, {static: true}) receptor: ReceptorDirective;

 constructor(private componentFactoryResolver: ComponentFactoryResolver,
             private renderer: Renderer2,
             private windowService: WindowService) { }

 ngOnInit(): void {
    if (this.windowService.isServer()) { return; }
    this.loadComponentes();
 }

 ngOnDestroy() {
    if (this.suscriberHtmlChange) {
      this.suscriberHtmlChange.unsubscribe();
    }
 }

 async loadComponentes() {

      await this.windowService.setTimeOut(200);
      this.findComponente();
      if (this.ChangeHtml) {
        this.suscriberHtmlChange = this.ChangeHtml.subscribe( () => {
            this.findComponente();
        });
      }
  }


  findComponente(){
    const elements = document.getElementsByTagName('var');
    // tslint:disable-next-line: prefer-for-of
    for (let index = 0; index < elements.length; index++) {
        const element = elements[index];
        const componente = this.getComponente(element.innerHTML);
        if (componente === null) {return; }
        this.loadComponente(componente, element);
    }
  }

  getComponente(text: string): any {
     if (!text.length) {return null; }
     let name = null;
     let componente = null;
     const parts = text.split('=');
     if (parts.length > 1) {
         name = parts[0];
     } else {
        name = text;
     }
     this.listComponente.every( comp => {
        if (comp.nombre === name) {
          componente = comp.componente;
          console.log('loadComponente -> ', comp.nombre);
          return false;
        }
        return true;
     });
     return componente;
  }

  loadComponente(componente: any, ref: Element) {
      const viewContainerRef = this.receptor.viewContainerRef;
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componente);
      const componentRef = viewContainerRef.createComponent<any>(componentFactory);
      const data = this.getDataComponente(ref.innerHTML);
      if (data != null) {
        componentRef.instance.data = data;
      }
      ref.innerHTML = '';
      this.renderer.setStyle(ref, 'font-style', 'normal');
      this.renderer.appendChild(ref, componentRef.location.nativeElement);
  }

  getDataComponente(innerHTML: string): any {
    const parts = innerHTML.split('=');
    let inner = '';
    let data = null;
    if (parts.length > 1) {
        const inputs = parts[1].split(',');
        inputs.forEach( (input, index) => {
            const values = input.split(':');
            inner = inner + '"' + values[0] + '" : ' + '"' + values[1] + '"';
            if (index !== (inputs.length - 1 )) {
              inner = inner + ',';
            }
        });
        inner = '{' +  inner + '}';
        try {
          data = JSON.parse(inner);
        } catch (error) {
            console.log('error en construción del componente');
            data = null;
        }
    }
    return data;
  }

}

