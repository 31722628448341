import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { ConfigSeo } from '../models';
import { CrudDatos, DatosEmpresaI } from '../common/backend/models-common-crud';
import { FirestoreService } from '../chat/services/firestore.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  datos: DatosEmpresaI = null;
  setMetaSeo: boolean = false;

  constructor(private meta: Meta,
              private titleService: Title,
              private firestoreService: FirestoreService,
              private router: Router) { 
                      this.loadInfo();
  }

  generateMetaSeo(config: ConfigSeo) {
    // default values
    this.setMetaSeo = true;
    config = {
      title: this.datos ? this.datos.nombre : environment.siteName,
      description: this.datos ? this.datos.descripcion : '',
      image: this.datos ? this.datos.logo : 'assets/imagenes/travel-image.jpeg',
      slug: '',
      ...config
    };
    //console.log('generateMetaSeo -> ', config);

    const siteNameEmpresa = this.datos ? this.datos.nombre : environment.siteName;
    const domainEmpresa = this.datos ? this.datos.domain : environment.domain;

    this.titleService.setTitle(config.title);

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({ name: 'twitter:site', content: siteNameEmpresa });
    this.meta.updateTag({ name: 'twitter:title', content: config.title });
    this.meta.updateTag({ name: 'twitter:description', content: config.description });
    this.meta.updateTag({ name: 'twitter:image', content: config.image });

    this.meta.updateTag({ property: 'og:type', content: 'article' });
    this.meta.updateTag({ property: 'og:site_name', content: siteNameEmpresa});
    this.meta.updateTag({ property: 'og:title', content: config.title });
    this.meta.updateTag({ property: 'og:description', content: config.description });
    this.meta.updateTag({ property: 'og:image', content: config.image });
    this.meta.updateTag({ property: 'og:url', content: domainEmpresa + '/' + config.slug });
  }

  generateSlug(nombre: string): string {
        // Definimos los caracteres que queremos eliminar
        const specialChars = '!@#$^&%*()+=-[]\/{}|:<>?,.¿';
        // Los eliminamos todos
        for (const specialChar of specialChars) {
          nombre = nombre.replace(new RegExp('\\' + specialChar, 'gi'), '');
        }

        // Lo queremos devolver limpio en minusculas
        nombre = nombre.toLowerCase();

        // Quitamos espacios y los sustituimos por _ porque nos gusta mas asi
        nombre = nombre.replace(/ /g, '_');
        // Quitamos acentos y "ñ". Fijate en que va sin comillas el primer parametro
        nombre = nombre.replace(/á/gi, 'a');
        nombre = nombre.replace(/é/gi, 'e');
        nombre = nombre.replace(/í/gi, 'i');
        nombre = nombre.replace(/ó/gi, 'o');
        nombre = nombre.replace(/ú/gi, 'u');
        nombre = nombre.replace(/ñ/gi, 'n');
        return nombre;
  }

  loadInfo() {
    const path = CrudDatos.config.path + CrudDatos.config.id;
    this.firestoreService.getDocumentChanges<DatosEmpresaI>(path).subscribe( res => {
        if (res) {
          this.datos = res;
          if (!this.setMetaSeo) {
              this.generateMetaSeo({slug: this.router.url})
          }
        }
    });
  }


}
