import { Campo } from '../widgets/crudfast/models-crud';

export interface Banner {
    nombre: string;
    image: string;
    descripcion: string;
    textBoton: string;
    url: string;
    enable: boolean;
}

export const BannerEstructura: Campo[] = [
    { name: 'nombre', type: 'string',  label: 'Título (opcional)', value: '', editable: true },
    // { name: 'descripcion', type: 'textarea',  label: 'Descripción (opcional)', value: '', editable: true },
    // { name: 'textBoton', type: 'string',  label: 'Texto del botón (opcional)', value: '', editable: true },
    { name: 'url', type: 'string',  label: 'Enlace', value: '', editable: true },
    { name: 'image', type: 'img', label: 'Imagen', value: '', editable: true },
    { name: 'enable', type: 'checkbox', label: 'Habilitar', value: true, editable: true },
];