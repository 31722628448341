import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FrontendService } from '../../../services/frontend.service';
import { Router } from '@angular/router';
import { Banner } from '../../models-common';
import { IonSlides } from '@ionic/angular';


@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {

  @Input() banners: Banner[] = [];
  @ViewChild('swiper') swiper: IonSlides;

  slideOpts = {
    initialSlide: 0,
    speed: 1000,
    updateOnWindowResize: true,
    centeredSlides: false,
        autoplay: {
        delay: 5000,
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      // renderBullet:  (index, className) => {
      //   return '<span class="' + className + '">' + (index + 1) + '</span>';
      // },
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  };

  constructor(private router: Router) {}

  ngOnInit(): void {}

  nextSlide() {
    this.swiper.slideNext();
  }

  prevSlide() {
    this.swiper.slidePrev();
  }

  banerClick(banner: Banner) {
      console.log('banerClick');
      if (banner.url) {
        if (banner.url.search('http') == 0) {
           window.open(banner.url, 'blank');
        } else {
          this.router.navigate([`/${banner.url}`]);
        }
      }
  }

}
