import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PaginaComponent } from './pagina/pagina.component';


const routes: Routes = [
  { path: '',  component: PaginaComponent},
  { path: 'set', loadChildren: () => import('./backend/backendpages.module').then(m => m.BackendPagesModule)},
  { path: 'set/:opcion', loadChildren: () => import('./backend/backendpages.module').then(m => m.BackendPagesModule)},
  { path: 'set/:opcion/:enlace', loadChildren: () => import('./backend/backendpages.module').then(m => m.BackendPagesModule)},
  { path: ':enlace', component: PaginaComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
