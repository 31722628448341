import { Component, OnInit, Input} from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { Campo } from '../../models-crud';
import { MultimediaComponent } from '../../../../multimedia/multimedia/multimedia.component';
import { InteraccionService } from '../../../../services/interaccion.service';
import { FirestoreService } from '../../../../services/firestore.service';
import { CategoriasComponent } from '../categorias/categorias.component';
import { CssEditorComponent } from '../css-editor/css-editor.component';
import { SelectComponent } from '../select/select.component';
import {FormGroup, FormControl} from '@angular/forms';
import { FirestorageService } from '../../../../multimedia/firestorage.service';
import { Subject } from 'rxjs';
import { InputPhone } from '../inputphone/inputphone.component';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

  @Input() camposEstructura: Campo[] = [];
  @Input() setVar: any;
  @Input() path: string;
  @Input() enlace: string;
  minDate: string;
  maxDate: string;
  minRangeDate: Date;
  maxRangeDate: Date;

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  isValid = false;
  color = "#faebd7";
  public color11: string = '#f2ff00';
  public toggle: boolean = false;
  emitChangeCelular = new Subject<InputPhone>();
  idfileupload: string;
  
  constructor(private modalController: ModalController,
              private interaccionService: InteraccionService,
              private firebaseService: FirestoreService,
              private popoverController: PopoverController,
              private firestorageService: FirestorageService,
              private firestoreService: FirestoreService) {
                   this.setMinDate();
                   this.idfileupload = this.firestoreService.createIdDoc();
               }

  ngOnInit(): void {
      this.camposEstructura.forEach( campos => {
          if (campos.subCampos !== undefined) {
            if (!campos.subCampos.length) {
                campos.subCampos = JSON.parse(JSON.stringify(this.camposEstructura));
            }
          }
      });
      // console.log('setvar validater -> ', this.setVar);
      this.validater(this.camposEstructura, this.setVar);
  }

  validater(camposEstructura: Campo[], setVar: any) {
    const init = setVar;
    camposEstructura.forEach( campo => {
      if (campo.type === 'Objeto') {
          if (init[campo.name] === undefined || ( (typeof init[campo.name]) !== 'object' )  ) {
            init[campo.name] = {};
          }
          campo.subCampos.forEach( subCampo => {
                if (init[campo.name][subCampo.name] === undefined) {
                    init[campo.name][subCampo.name] = subCampo.value;
                }
          });
      } 
      else {
          if (!init[campo.name] && campo.type !== 'outside' && campo.type !== 'checkbox') {
            if (campo.type === 'select') {
              init[campo.name] = '';
            } else {
              init[campo.name] = campo.value;
            }
          }
      }
      if (campo.type === 'phone') {
        this.emitInputPhone(campo.name)
      }
      if (campo.type === 'datetime') {
        const now = new Date();
        if (this.setVar[campo.name]) {
          const date = new Date(this.setVar[campo.name]);
          now.setFullYear(date.getFullYear());
          now.setMonth(date.getMonth());
          now.setDate(date.getDate());
        }
        this.setVar[campo.name] = now.toISOString();
      }
    });
    // console.log('setVar -> ', setVar);
    this.isValid = true;
  }


  setMinDate() {
    const today = new Date();
    const month  = today.getMonth() + 1;
    let monthString: string;
    if (month < 10 ) {
        monthString = '0' + month;
    } else  {
      monthString = '' + month;
    }
    const day  = today.getDate();
    let dayString: string;
    if (day < 10 ) {
        dayString = '0' + day;
    } else  {
      dayString = '' + day;
    }
    this.minDate = today.getFullYear() + '-' + monthString + '-'
                   + dayString;
    this.maxDate = (today.getFullYear() + 2) + '-' + monthString + '-'
                    + dayString;
    this.minRangeDate = (new Date(this.minDate));
    this.maxRangeDate = new Date(this.maxDate);
    this.minRangeDate.setDate(this.minRangeDate.getDate() + 1);
    // console.log('this.minRangeDate -> ', this.minRangeDate);
    
    // console.log('this.maxDate ->', this.maxDate);
  }

  async addImage(name?: string, ownMultimedia: boolean = false) {
    const modalAdd  = await this.modalController.create({
      component: MultimediaComponent,
      mode: 'ios',
      cssClass: 'aviso',
      swipeToClose: true,
      componentProps: {isModal: true, ownMultimedia}
    });
    await modalAdd.present();
    const {data} = await modalAdd.onWillDismiss();
    if (data !== undefined && name !== undefined) {
      this.setVar[name] = data.url;
      if (this.setVar.thumbnail !== undefined) {
        this.setVar.thumbnail = data.thumbnail;
      }
    }
  }

  async removeImage(name?: string) {
      const res = await this.interaccionService.preguntaAlert('¡Alerta!', '¿Seguro que desea quitar la imagen?');
      if (res) {
        this.setVar[name] = null;
        if (this.setVar.thumbnail !== undefined) {
          this.setVar.thumbnail = null;
        }
      }
  }

  async addCss(value: any, name: string) {
      console.log('addCSS -> ', this.enlace);
      const modalAdd  = await this.modalController.create({
        component: CssEditorComponent,
        mode: 'ios',
        cssClass: 'aviso',
        swipeToClose: true,
        componentProps: {css: value, enlaceBefore: this.enlace}
      });
      await modalAdd.present();
      const {data} = await modalAdd.onWillDismiss();
      if (data !== undefined) {
        this.setVar[name] = data;
      }
  }

  async selecCategoria(campo: Campo) {
      // console.log('selecCategoria -> ', campo.categorias)
      const modalAdd  = await this.popoverController.create({
        component: CategoriasComponent,
        mode: 'ios',
        cssClass: 'popoverRegistrarse',
        componentProps: {categorias: campo.categorias}
      });
      await modalAdd.present();
      const {data} = (await modalAdd.onWillDismiss());
      if (data !== undefined) {
         data.forEach( (value: any) => {
            const categoria = {
                id: value.id,
                nombre: value.nombre
            };
            const exist = this.setVar[campo.name].find( (categoriaExist: any) => {
                  return categoria.id === categoriaExist.id && categoria.nombre === categoriaExist.nombre
            });
            if(exist === undefined) {
                this.setVar[campo.name].push(categoria)
            }
         }); 
      }
  }

  doReorder(ev: any, campoArray: Campo) {
    this.setVar[campoArray.name] = ev.detail.complete(this.setVar[campoArray.name]);
  }

  addOpcArray(campoArray: Campo, index: number) {
    const init = {};
    if (this.camposEstructura[index].subCampos.length) {
      this.camposEstructura[index].subCampos.forEach( campo => {
          if (campo.type === 'Objeto') {
              init[campo.name] = {};
              campo.subCampos.forEach( subCampo => {
                  init[campo.name][subCampo.name] = subCampo.value;
              });
          }
          else if (campo.type === 'array') {
            init[campo.name] = []
          }
          else {
            if (campo.type === 'select') {
              init[campo.name] = '';
            } else {
              init[campo.name] = campo.value;
              console.log(campo.name, campo.value);
            }
          }
      });
    }
    if (init['id'] !== undefined ) {
        init['id'] = this.firebaseService.createIdDoc();
    }
    if (this.setVar[campoArray.name] === undefined) {
      this.setVar[campoArray.name] = [];
    }
    this.setVar[campoArray.name].push(init);
  }

  async removeOpcArray(campoArray: Campo, index: number) {
    const alert = await this.interaccionService.preguntaAlert('Advertencia', '¿Seguro que desea eliminar?');
    if (alert) {
      this.setVar[campoArray.name].splice(index, 1);
    }
  }

  removeCategoria(campo: any[], i: number) {
      campo.splice(i, 1);
  }

  loadExtra(campo: Campo) {
      const path = this.path + this.setVar.id + '/' + campo.pathOutside;
      console.log('loadExtra -> ', path)
      this.firebaseService.getCollectionChanges<any>(path).subscribe( res => {
              if (res.length) {
                campo.value = res[0];
                campo.loaded = true;
              } else {
                campo.value = {} as any;
                campo.value.id = this.firebaseService.createIdDoc();
                this.validater(campo.subCampos, campo.value);
                console.log('init ->', campo.value);
                campo.loaded = true;
              }
      });
  }

  saveExtra(campo: Campo) {
      const path = this.path + this.setVar.id + '/' + campo.pathOutside;
      const id = campo.value.id;
      this.interaccionService.presentLoading();
      this.firebaseService.createDocumentID(campo.value, path, campo.value.id).then( _ => {
              this.interaccionService.showToast('Guardado con éxito');
              this.interaccionService.dismissLoading();
        }).catch( error => {
              this.interaccionService.showToast('No se pudo guardar');
              console.log('error -> ', error);
              this.interaccionService.dismissLoading();
      });
  }

  deleteExtra(campo: Campo) {
    const path = this.path + this.setVar.id + '/' + campo.pathOutside;
    const id = campo.value.id;
    this.interaccionService.presentLoading();
    this.firebaseService.deleteDocumentID(path, campo.value.id).then( _ => {
            this.interaccionService.showToast('Guardado con éxito');
            this.interaccionService.dismissLoading();
      }).catch( error => {
            this.interaccionService.showToast('No se pudo eliminar');
            console.log('error -> ', error);
            this.interaccionService.dismissLoading();
    });
  }

  async addDirection(campo: Campo) {
  }

  changeSelect(event: any, campo: any) {
     this.setVar[campo] = event.detail.value;
    //  console.log('this.setVar -> ', this.setVar);
  }

  loadDocument(campo: Campo) {
      let openPop = false;
      this.firebaseService.getDocumentChanges<any>(campo.pathDoc).subscribe( async res => {
          if (res !== undefined) {
              console.log('loadDocument -> ', res);
              if (!openPop) {
                openPop = true;
                const modalAdd  = await this.popoverController.create({
                  component: SelectComponent,
                  mode: 'ios',
                  cssClass: 'popoverSelectCrudFast',
                  componentProps: {
                    setVar: res, 
                    camposEstructura: campo.subCampos, 
                    selectCampo: campo.selectCampo,
                    setVarUp: campo
                  }
                });
                await modalAdd.present();
                const {data} = (await modalAdd.onWillDismiss());
                if (data !== undefined) {
                  this.setVar[campo.name] = data;
                  console.log('this.setVar[campo.name] -> ', this.setVar[campo.name])
                  if (campo.arrayName) {
                    this.setVar['nombre'] = data[campo.arrayName];
                  }
                }
              }
          }
      });

  }

  loadCollection(campo: Campo) {
     let openPop = false;
     this.firebaseService.getCollectionChanges(campo.pathColl).subscribe( async res => {
          if (res.length) {
          const collectEstructura: Campo[] = [
              { name: campo.selectCampo, type: 'array', label: campo.selectCampo, value: [], subCampos: campo.subCampos, editable: true },
          ];
          const docs = {};
          docs[campo.selectCampo] = [];
            //  console.log('loadCollection RES -> ', res);
            //  console.log('loadCollection Campo -> ', campo);
              res.forEach( doc => {
                  if (doc[collectEstructura[0].subCampos[0].name] !== undefined) {
                      if (campo.validaterDoc) {
                          if ( doc[campo.validaterDoc.campo] === campo.validaterDoc.value ) {
                            docs[campo.selectCampo].push(doc);
                          }
                      } else {
                        docs[campo.selectCampo].push(doc);     
                      }
                  }
              });
              if (!openPop) {
                openPop = true;
                const modalAdd  = await this.popoverController.create({
                  component: SelectComponent,
                  mode: 'ios',
                  cssClass: 'popoverSelectCrudFast',
                  componentProps: {
                    setVar: docs, 
                    camposEstructura: collectEstructura, 
                    selectCampo: campo.selectCampo,
                    setVarUp: campo
                  }
                });
                await modalAdd.present();
                const {data} = (await modalAdd.onWillDismiss());
                if (data !== undefined) {
                  console.log('campo.selectCampos -> ', campo.selectCampos);
                  this.setVar[campo.name] = {};
                  campo.selectCampos.forEach( campoSelect => {
                    this.setVar[campo.name][campoSelect.name] = data[campoSelect.name]    
                  }); 
                  console.log('this.setVar[campo.name] -> ', this.setVar[campo.name]);
                  
                  // this.setVar[campo.name] = data;
                  if (campo.arrayName) {
                    this.setVar['nombre'] = data[campo.arrayName];
                  }
                }
              }
          }
     });
    
  }

  dateRangeChange(campo: Campo) {
    this.setVar[campo.name] = this.range.value;
    let year = null;
    let month = null;
    let day = null;
    let start = '';
    let end = '';
    if (this.range.value.start) {
      year = this.range.value.start.getFullYear();
      month = this.range.value.start.getMonth() + 1;
      day = this.range.value.start.getDate();
      start = year + '/' + month + '/' + day;
    }
    if (this.range.value.end) {
      year = this.range.value.end.getFullYear();
      month = this.range.value.end.getMonth() + 1;
      day = this.range.value.end.getDate();
      end = year + '/' + month + '/' + day;
    }
    this.setVar['nombre'] = start + ' - ' + end;
    // console.log(this.setVar[campo.name]);
  }

  async getFile(event: any, campo: Campo) {
    if (event.target.files && event.target.files[0]) {
        const maxSize = 10000000;
        console.log(' pdf size -> ', event.target.files[0].size);
        if (event.target.files[0].size <= maxSize) {
          await this.interaccionService.presentLoading();
          const nameFile = event.target.files[0].name as string;
          const reader = new FileReader();
          reader.onload = ( async (image) => {
              const fileLoad = image.target.result;
              try {
                const nombre = nameFile.split('.')[0] + '-' + this.firebaseService.createIdDoc() + '.' + nameFile.split('.')[1];
                 const urlPdf = await this.uploadPdf(fileLoad, nombre);
                 if (!this.setVar['nombre']) {
                   this.setVar['nombre'] = nameFile;
                 }
                 this.setVar[campo.name] = urlPdf;
                 console.log('this.setVar -> ', this.setVar);
                 this.interaccionService.dismissLoading();
                 this.interaccionService.showToast('Archivo subido con éxito');
              } catch (error) {
                  console.log(error)
                  this.interaccionService.dismissLoading();
                  this.interaccionService.showToast('Error al subir el archivo')
              }
          });
          reader.readAsDataURL(event.target.files[0]);
        } else {
         this.interaccionService.showToast('El archivo excede el tamaño máximo permitido', 4000);
        }
    }
  }

  uploadPdf(file: any, nombrePdf: string): Promise<string> {

    return new Promise((resolve) => {
        const carpeta = 'PDFs';
        this.setVar['path'] = carpeta + '/' + nombrePdf;
        this.firestorageService.uploadFile(file, nombrePdf, carpeta).then( res => {
                console.log('pdf url -> ', res.url);
                resolve(res.url);
                return;
        });
      
    });
  }

  async deletePdf(campo: Campo) {
    const res = await this.interaccionService.preguntaAlert('Alerta', '¿Seguro que desea eliminar este archivo?');
    if (res) {
      try {
        console.log('a borrar 1 -> ', this.setVar);
        this.firestorageService.deleteFile(this.setVar['path']).subscribe( res => {
            this.interaccionService.showToast('Archivo eliminado con éxito')
            this.setVar['nombre'] = null;
            this.setVar[campo.name] = null;
            this.setVar['path'] = null; 
        });
        this.setVar['nombre'] = null;
        this.setVar[campo.name] = null;
        this.setVar['path'] = null; 
      } catch (error) {
          this.setVar['nombre'] = null;
          this.setVar[campo.name] = null;
          this.setVar['path'] = null; 
      }
    }
  }

  onSelecColor(color: string, campo: Campo): void {
    console.log('Color selected:', color);
    this.setVar[campo.name] = color
  }

    // phone input
    changeCelular(ev: any, campo: any) {
      if (ev) {
        this.setVar[campo].number = ev.number ? ev.number : null;
        this.setVar[campo].countryCode = ev.countryCode ? ev.countryCode : null;
        this.setVar[campo].e164Number = ev.e164Number ? ev.e164Number : null;
      }
    }
  
    emitInputPhone(campo: string) {
        setTimeout(() => {
          if ( (typeof this.setVar[campo] !== 'object' )) { this.setVar[campo] = {} }
          const value = this.setVar[campo];
          value.number = value.number ? value.number : null;
          value.countryCode = value.countryCode ?  value.countryCode : null;
          value.e164Number = value.e164Number ? value.e164Number :null;
          const input = {
            number: value.number,
            countryCode: value.countryCode,
            e164Number: value.e164Number
          };
          // console.log('emitInputPhone -> ', input);
          this.emitChangeCelular.next(input);
        }, 1000);
    }


}
