import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContactformRoutingModule } from './contactform-routing.module';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FormComponent } from './form/form.component';
import { MatInputModule } from '@angular/material/input';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ComponentesModule } from '../../common/componentes/componentes.module';

@NgModule({
  declarations: [
    FormComponent
  ],
  imports: [
    CommonModule,
    ContactformRoutingModule,
    IonicModule,
    FormsModule,
    NgxIntlTelInputModule,
    ReactiveFormsModule,
    MatInputModule,
    ComponentesModule
  ],
  exports: [
    FormComponent
  ]
})
export class ContactformModule { }
