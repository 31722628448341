import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { FirestoreService } from '../../services/firestore.service';
import { ModalController } from '@ionic/angular';
import { FirestorageService } from '../firestorage.service';
import { AutenticationService } from '../../services/autentication.service';
import { InteraccionService } from '../../services/interaccion.service';
import { FileStorage, Multimedia } from '../../models';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment.prod';

@Component({
  selector: 'app-multimedia',
  templateUrl: './multimedia.component.html',
  styleUrls: ['./multimedia.component.scss']
})
export class MultimediaComponent implements OnInit, OnDestroy {

  @Input() isModal = false;
  @Input() ownMultimedia = false;
  private pathMultimedia = 'Usuarios/' + environment.uidAdmin + '/Multimedia';
  carpetaMultimedia = 'Galeria';
  multimedia: Multimedia[] = [];
  selecMultimedia: Multimedia;

  files: FileStorage[] = [];
  selecFile: Multimedia;
  selected = false;
  suscriber: Subscription;
  endResult = false;
  indexSelec = null;
  beforeName = '';
  newImage = '';
  newThumbnail = '';
  newImageName = '';
  newImageAlt = '';
  expandSelec = null;
  modalSelecUrl = '';
  modalSelecThumbnail = '';
  insertar: string;

  suscriberMultimedia: Subscription;
  allMultimedia: boolean = true;


  constructor(private firestoreService: FirestoreService,
              public modalController: ModalController,
              private firestorageService: FirestorageService,
              private autenticationService: AutenticationService,
              private interaccionService: InteraccionService) {


  }

  async ngOnInit() {
      if (this.ownMultimedia) {
        const uid = await this.autenticationService.getUid();
        this.pathMultimedia = 'Usuarios/' + uid + '/Multimedia';
        this.carpetaMultimedia = 'Galeria/' + uid;
      }
      if (this.allMultimedia) {
        this.loadAllMultimedia();
      } else {
        this.loadMultimedia();
      }
  }

  ngOnDestroy(): void {
      if (this.suscriber) {
        this.suscriber.unsubscribe();
      }
      if (this.suscriberMultimedia) {
        this.suscriberMultimedia.unsubscribe();
      }
  }

  async loadMultimedia(ev: any = null) {
      const path = this.pathMultimedia;
      let STARTAT = null;
      if (this.multimedia.length) {
          STARTAT = this.multimedia[this.multimedia.length - 1].timeModified;
      }
      this.firestoreService.getCollectionOrderLimit<Multimedia>(path, 6, 'timeModified', 'desc', STARTAT).subscribe( res => {
          if (res !== undefined) {
                res.forEach( multimediaLoad => {
                      let exist = false;
                      this.multimedia.every( multimediaExist => {
                              if (multimediaExist.id === multimediaLoad.id) {
                                exist = true;
                                return false;
                              }
                              return true;
                      });
                      if (!exist) {
                          this.multimedia.push(multimediaLoad);
                          this.orderMultimedia();
                      }
                });
          }
          if (ev) { ev.target.complete();}
      });

  }

  loadAllMultimedia() {
    const path = this.pathMultimedia;
    this.firestoreService.getCollectionChanges<Multimedia>(path).subscribe( res => {
        if (res) {
              res.forEach( multimediaLoad => {
                multimediaLoad['show'] = true;
              });
              this.multimedia = res;
              this.orderMultimedia();
        }
    });
  }

  orderMultimedia() {
    this.multimedia.sort((file1, file2) => {
      if (file1.timeModified > file2.timeModified) {
          return -1;
      }
      if (file1.timeModified < file2.timeModified) {
          return 1;
      }
      return 0;
    });
  }


  async saveMultimedia() {

    this.interaccionService.presentLoading();
    const files = await this.saveNewFile();
    const file: Multimedia = {
          id: this.firestoreService.createIdDoc(),
          timeCreated: new Date(),
          timeModified: new Date(),
          img: files[0].url,
          thumbnail: files[1].url,
          alt: this.newImageName,
          refImg: files[0].metadata.fullPath,
          refThumbnail: files[1].metadata.fullPath,
          metadata: {
            size: files[0].metadata.size,
            sizeThumbnail: files[1].metadata.size,
            type: files[0].metadata.type,
            contentType: files[0].metadata.contentType,
            name: files[0].metadata.name,
            fullPath: files[0].metadata.fullPath,
            timeCreated: files[0].metadata.timeCreated,
            updated: files[0].metadata.updated,
            customMetadata: '',
          },
    };
    const path = this.pathMultimedia;
    this.firestoreService.createDocumentID(file, path, file.id).then( _ => {
            this.interaccionService.showToast('Guardado con éxito');
            this.interaccionService.dismissLoading();
            this.newImageName = '';
            this.newImage = '';
            this.newThumbnail = '';
      }).catch( error => {
            this.interaccionService.showToast('No se pudo guardar');
            console.log('error -> ', error);
            this.interaccionService.dismissLoading();
      });
  }

  expandSelecFile(index: number) {
    if (this.expandSelec !== index ) {
      this.expandSelec = index;
    } else {
      this.expandSelec = null;
    }
  }

  selec(file: Multimedia, index: number) {
    this.selecFile = file;
    this.selected = true;
    this.indexSelec = index;
    this.beforeName = file.metadata.name;
    this.insertar = '<img src="' + this.selecFile.img + '" alt="' + this.selecFile.metadata.name + '">';
    if (this.selecFile.alt.length) {
      this.newImageAlt = this.selecFile.alt;
      this.insertar = '<img src="' + this.selecFile.img + '" alt="' + this.newImageAlt + '">';
    } else {
      this.newImageAlt = '';
    }
  }

  downloadFile() {
      this.firestorageService.downloadFile(this.selecFile.img, this.selecFile.metadata.name);
      this.interaccionService.showToast('descargando archivo...');
  }

  changeName() {
      this.interaccionService.presentLoading();
      if (this.selecFile.metadata.name !== this.beforeName && this.selecFile.metadata.name.length) {
        this.firestorageService.changeNameFile(this.selecFile, this.selecFile.metadata.name,
                                               this.carpetaMultimedia).then( res => {
            this.firestorageService.deleteFile(this.selecFile.refImg);
            this.multimedia.splice(this.indexSelec, 1);
            this.selecFile.img = res.url;
            this.selecFile.metadata.size = res.metadata.size;
            this.selecFile.metadata.name = res.metadata.name;
            this.selecFile.metadata.fullPath = res.metadata.fullPath;
            this.selecFile.refImg = res.metadata.fullPath;
            this.selecFile.metadata.updated = res.metadata.updated;
            this.selecFile.timeModified = new Date();
            this.updateMetadata(true);
        });
      } else {
        this.updateMetadata(false);
      }
  }

  updateMetadata(update: boolean) {
      if (this.selecFile.alt !== this.newImageAlt) {
            update = true;
            this.selecFile.alt = this.newImageAlt;
            this.selecFile.timeModified = new Date();
      }
      if (update) {
        this.firestoreService.createDocumentID(this.selecFile, this.pathMultimedia, this.selecFile.id).then( _ => {
          this.interaccionService.showToast('Guardado con éxito');
          this.selec(this.selecFile, this.indexSelec);
          this.interaccionService.dismissLoading();
        }).catch( error => {
              this.interaccionService.showToast('No se pudo guardar');
              console.log('error -> ', error);
              this.interaccionService.dismissLoading();
        });
      } else {
        this.interaccionService.showToast('actualizado con éxito');
      }
  }

  insertarFile() {
    this.interaccionService.showToast('código copiado');
  }

  deleteFile() {
      this.interaccionService.preguntaAlert('Advertencia', '¿Seguro que deseas eliminar este archivo ?').then( res => {
            if (res) {
              this.interaccionService.presentLoading();
              this.firestorageService.deleteFile(this.selecFile.refImg);
              this.firestorageService.deleteFile(this.selecFile.refThumbnail);
              const path = this.pathMultimedia;
              this.firestoreService.deleteDocumentID(path, this.selecFile.id).then( _ => {
                      this.interaccionService.showToast('archivo eliminado');
                      this.interaccionService.dismissLoading();
                      this.multimedia.splice(this.indexSelec, 1);
                }).catch( error => {
                      this.interaccionService.showToast('No se pudo eliminar');
                      console.log('error -> ', error);
                      this.interaccionService.dismissLoading();
                });
              this.selected = false;
            }
      });
  }

  copyLink() {
    this.interaccionService.showToast('enlace copiado');
  }

  expandImg(file: Multimedia) {
      const modal = document.getElementById('myModal');
      const modalImg = document.getElementById('img01');
      const captionText = document.getElementById('caption');
      modal.style.display = 'block';
      modalImg.setAttribute('src', file.img);
      captionText.innerHTML = file.alt;
  }

  closeModal() {
    const modal = document.getElementById('myModal');
    modal.style.display = 'none';
  }

  saveNewFile(): Promise<FileStorage[]> {
      return new Promise(  async resolve => {
        const image: FileStorage[] = [];
        const numFile = 2;
        let uploadFile = 0;
        this.firestorageService.uploadFile(this.newImage, this.newImageName,
                                           this.carpetaMultimedia).then( res => {
              uploadFile ++;
              image.unshift(res);
              if (uploadFile >= numFile) {
                  resolve(image);
                  return;
              }
              this.files.unshift(res);
        });
        this.firestorageService.uploadFile(this.newThumbnail, this.newImageName + '-thumbnail',
            this.carpetaMultimedia).then( res => {
            uploadFile ++;
            image.push(res);
            if (uploadFile >= numFile) {
                  resolve(image);
                  return;
            }
            this.files.unshift(res);
        });
      });
  }

  addNew(event: any) {
    if (event.target.files && event.target.files[0]) {
      console.log('addNew -> ',event)
      const reader = new FileReader();
      reader.onload = ((image) => {
          this.newImageName = event.target.files[0].name;
          this.resizeImage(image.target.result, 300);
          this.newImage = image.target.result as string;
      });
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  dismissModal() {
    const data = {
      url: this.modalSelecUrl,
      thumbnail: this.modalSelecThumbnail
    }
    this.modalController.dismiss(data);
  }

  resizeImage(imgIn: any, MAX_WIDTH: number) {

    const img = document.createElement('img');
    img.src = imgIn;

    setTimeout(() => {
      const MAX_HEIGHT = MAX_WIDTH;
      let width = img.width;
      let height = img.height;
      if (width >= height) {
        if (width >= MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        } else if (height >= MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      } else {
        if (height >= MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        } else if (width >= MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      }
      const canvas = document.getElementById('canvas') as HTMLCanvasElement;
      const ctx = canvas.getContext('2d');
      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(img, 0, 0, width, height);
      this.newThumbnail = canvas.toDataURL('image/png');
    }, 200);

  }

  searchEvent(ev: any) {
    const search = ev.detail.value as string;
    this.multimedia.forEach( doc => {
          doc.show = doc.alt.toLowerCase().search(search.toLowerCase()) > -1;
    });
  }

}
