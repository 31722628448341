import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SuscriptionRoutingModule } from './notification-routing.module';
import { QuestionNotificationComponent } from './componentes/question-notification/question-notification.component';
import { SuscripcionComponent } from './componentes/suscripcion/suscripcion.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    QuestionNotificationComponent,
    SuscripcionComponent
  ],
  imports: [
    CommonModule,
    SuscriptionRoutingModule,
    IonicModule,
    FormsModule,
    TranslateModule
  ],
  exports: [
    SuscripcionComponent,
  ]
})
export class NotificationModule { }
