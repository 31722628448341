import { Component, OnInit, ViewChild, ElementRef, Renderer2, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ChatService } from '../../services/chat.service';
import { Chat } from '../../models-chat';
import { Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-singlechat',
  templateUrl: './singlechat.component.html',
  styleUrls: ['./singlechat.component.scss']
})
export class SinglechatComponent implements OnInit, OnDestroy {

  chats: Chat[] = [];
  height: number;
  suscriberChat: Subscription;

  @Output() select = new EventEmitter<Chat>();

  @ViewChild('chatsingle') chatsingle: ElementRef;

  constructor(private chatService: ChatService) {
                
        this.chatService.initChats();
        this.suscriberChat = this.chatService.getChatsChanges().subscribe( res => {
                this.chats = res;
                this.chats.forEach( chat => {
                       chat['timevalue'] = this.getTimeChat(chat.time.seconds)
                });
                // console.log('chats -> ', this.chats)
                // if (this.chats.length === 1) {
                //     this.selectChat(this.chats[0]);
                // }
        });

  }

  ngOnInit(): void {

    // setTimeout(() => {
    //   const header = this.chatsingle.nativeElement.getBoundingClientRect().top;
    //   const height = this.platform.height() - header;
    //   this.render.setStyle(this.chatsingle.nativeElement, 'height', height + 'px');
    // }, 100);

  }

  ngOnDestroy(): void {
      if (this.suscriberChat) {
         this.suscriberChat.unsubscribe();
      }
  }

  selectChat(chat: Chat) {
      this.select.emit(chat);
  }

  getTimeChat(timeStampSecods: number): string {
      let time: string;
      const date = new Date (timeStampSecods * 1000);
      const now = new Date();
      if (now.getFullYear() === date.getFullYear() &&
          now.getMonth() === date.getMonth()) {
            if ( now.getDate() === date.getDate() ) {
              if (date.getMinutes() < 10) {
                return time = date.getHours() + ':0' + date.getMinutes();
              } else {
                return time = date.getHours() + ':' + date.getMinutes();
              }
            } else if ( (now.getDate() - date.getDate()) <= 1 ) {
              return time = 'Ayer';
            }
      }
      return time = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
  
  }

  leerMoreChats() {
      this.chatService.leerMoreChats();
  }



}