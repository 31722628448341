import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { environment } from '../../environments/environment.prod';
import { Campo, campoType } from '../widgets/crudfast/models-crud';
import { WindowService } from './window.service';
import { Parametros } from '../../environments/parametros';
import { FirestoreService } from '../services/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class AppearanceService {

  private apariencia: Apariencia;
  private apariencia$ = new Subject<Apariencia>();

  private estilos: Estilos;
  private estilos$ = new Subject<Estilos>();
  private dark: boolean = false;
  private dark$ = new Subject<boolean>();

  constructor(private firestoreService: FirestoreService,
              private windowService: WindowService) {
                    this.initApariencia();
                     this.initEstilos();
                     //  this.loadParametros();
                     //this.loadEstilos();
               }

  initApariencia() {
      this.apariencia = Parametros.apariencia;
      this.apariencia$.next(this.apariencia);
  }
  


  initEstilos() {
      const colores = Parametros.colores;
      const estilosColor : Estilos = {
        font: {
          value: Parametros.tipoletra,
          variable: '--font'
        },
        primario_set: {
          value: colores.primario,
          variable: '--primario-set'
        },
        primario: {
            value: colores.primario,
            variable: '--primario'
        },
        primario_contraste: {
            value: colores.primario_contraste,
            variable: '--primario-contraste'
        },
        primario_fixed: {
          value: colores.primario,
          variable: '--primario-fixed'
        },
        secundario_set: {
          value: colores.primario,
          variable: '--secundario-set'
        },
        secundario: {
            value: colores.secundario,
            variable: '--secundario'
        },
        secundario_contraste: {
            value: colores.secundario_contraste,
            variable: '--secundario-contraste'
        },
        secundario_fixed: {
          value: colores.secundario,
          variable: '--secundario-fixed'
        },
        terciario_set: {
          value: colores.primario,
          variable: '--terciario-set'
        },
        terciario: {
            value: colores.terciario,
            variable: '--terciario'
        },
        terciario_contraste: {
            value: colores.terciario_contraste,
            variable: '--terciario-contraste'
        },
        terciario_fixed: {
          value: colores.terciario,
          variable: '--terciario-fixed'
        },
      };
      this.estilos = estilosColor;
      this.estilos$.next(this.estilos);
  }

  loadParametros() {
      const path = CrudApariencia.config.path + CrudApariencia.config.id;
      this.firestoreService.getDocumentChanges<Apariencia>(path).subscribe( doc => {
              if (doc) {
                  this.apariencia = doc;
                  Parametros.apariencia = this.apariencia
                  this.apariencia$.next(this.apariencia);
              }
      }); 
  }
  
  



  loadEstilos() {
    const path = CrudEstilos.config.path + CrudEstilos.config.id;
    this.firestoreService.getDocumentChanges<Estilos>(path).subscribe( doc => {
            if (doc) {
                this.estilos = doc;
                this.estilos$.next(this.estilos);
            }
    }); 
  }

  getApariencia() {
        if (!this.apariencia) {
            this.initApariencia();
        }
        this.apariencia$.next(this.apariencia);
        return this.apariencia;
  }

  getEstilosChanges(): Observable<Estilos> {
    this.windowService.setTimeOut(50).then( () => {
         if (this.estilos) {
           this.estilos$.next(this.estilos);
         }
    });
    return this.estilos$.asObservable();
  }

  getAparienciaChanges(): Observable<Apariencia> {
      this.windowService.setTimeOut(50).then( () => {
           if (this.apariencia) {
            this.apariencia$.next(this.apariencia);
           }
      });
      return this.apariencia$.asObservable();
  }

  setDarkMode(value: boolean) {
    this.dark = value;
    this.dark$.next(this.dark);
  }

  getDarkMode() {
    return this.dark;
  }

  getDarkModeChanges() {
    return this.dark$.asObservable();
  }

}

const opcionsTypeTheme = [{ nombre: 'primero' }, { nombre: 'segundo' }, { nombre: 'tercero' }];
const opcionsTypeFontFamily = [{ nombre: "'Nunito'" }, 
                               { nombre: "'Roboto Condensed'" }, 
                               { nombre: "'Raleway'" },
                               { nombre: "'Montserrat'" },
                               { nombre: "'Playfair Display'" }];

const AparienciaEstructura: Campo[] = [
  { name: 'themeSite', type: 'select', label: 'Template' , value: opcionsTypeTheme, editable: true },
 ];

export const  CrudApariencia = {
  icon: 'color-palette',
  label: 'Apariencia',
  url: 'apariencia',
  colorIcon: 'white',
  colorBackgroud: 'linear-gradient(90deg, #87e89d 0%, #057b20 100%)',
  config: {
      title: 'Apariencia',
      labelAll: 'Apariencia',
      setVar: {},
      estructura: AparienciaEstructura,
      path: 'Usuarios/' + environment.uidAdmin + '/apariencia/',
      multiple: false,
      id: environment.uidAdmin,
  }
}


export interface Apariencia {
  themeSite: 'primero' | 'segundo' | 'tercero',
}


const CSSVar = (value: string, type: campoType): Campo[] => {
   const estilo: Campo[] = [
    { name: 'value', type, label: 'Valor', value: '#fff', editable: true },
    { name: 'variable', type: 'string', label: 'Variable', value, editable: false },  
   ]
   return estilo;
}

const EstiloEstructura = (name: string, label: string, varname: string, type: campoType): Campo => {
  const estilo: Campo = 
    { name, type: 'Objeto', label, value: {}, 
    subCampos: CSSVar(varname, type), editable: true }
   return estilo;
}

const EstilosEstructura: Campo[] = [
    { name: 'font', type: 'Objeto', label: 'Colores', value: {}, subCampos: [
      EstiloEstructura('primario', 'Primario', '--primario-set', 'color'),
      EstiloEstructura('primario-font', 'Primario contraste', '--primario-set-contraste', 'color'),
      EstiloEstructura('secundario', 'Secundario', '--secundario-set', 'color'),
      EstiloEstructura('secundario-font', 'Secundario contraste', '--secundario-set-contraste', 'color'),
      EstiloEstructura('terciario', 'Terciario', '--terciario-set', 'color'),
      EstiloEstructura('terciario-font', 'Terciario contraste', '--terciario-set-contraste', 'color'),
      
    ], editable: true },
    { name: 'tipoletra', type: 'Objeto', label: 'Tipo letra', value: {}, subCampos: [
        { name: 'value', type: 'select', label: 'Tipo', value: opcionsTypeFontFamily, editable: true },
        { name: 'variable', type: 'string', label: 'Variable', value: '--font', editable: false },   
    ], editable: true },
];

export const  CrudEstilos = {
  icon: 'color-fill',
  label: 'Estilos',
  url: 'estilos',
  colorIcon: 'white',
  colorBackgroud: 'linear-gradient(90deg, #f44336 0%, #c2185b 100%)',
  config: {
      title: 'Estilos',
      labelAll: 'Estilos',
      setVar: {},
      estructura: EstilosEstructura,
      path: 'Usuarios/' + environment.uidAdmin + '/estilos/',
      multiple: false,
      id: environment.uidAdmin,
  }
}

export interface Estilos {
  primario_set: CSSVar,
  primario: CSSVar,
  primario_contraste: CSSVar,
  primario_fixed: CSSVar,
  secundario_set: CSSVar,
  secundario: CSSVar,
  secundario_contraste: CSSVar,
  secundario_fixed: CSSVar,
  terciario_set: CSSVar,
  terciario: CSSVar,
  terciario_contraste: CSSVar,
  terciario_fixed: CSSVar,
  font: CSSVar
} 

interface CSSVar {
  variable: string;
  value: string;
} 
