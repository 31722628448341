import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AutenticationService } from '../../../services/autentication.service';
import { environment } from '../../../../environments/environment';
import { MenuController, PopoverController } from '@ionic/angular';
import { FrontendService } from '../../../services/frontend.service';
import { PopLoginComponent } from '../pop-login/pop-login.component';
import { InteraccionService } from '../../../services/interaccion.service';
import { PopNotificationsComponent } from '../../../notifications/componentes/pop-notifications/pop-notifications.component';
import { NotificationsService } from '../../../notifications/services/notifications.service';
import { Subscription } from 'rxjs';
import { FirestoreService } from '../../../services/firestore.service';
import { WindowService } from '../../../services/window.service';
import { NotificationApp } from '../../../notifications/models-notifications';
import { MenuHeader, CrudDatos, DatosEmpresaI } from '../../backend/models-common-crud';
import { AppearanceService, Apariencia } from '../../../services/appearance.service';
import { Parametros } from '../../../../environments/parametros';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  @Output() menu = new EventEmitter<void>();

  seccion = 'Home';
  isAdmin = false;

  newNotificaciones: NotificationApp[] = [];

  menuHearder: MenuHeader[] = [];

  suscriberInfo: Subscription;
  info: DatosEmpresaI = null;
  openSubmenu = false;
  indexOpenMenu: number;
  popoverSubMenu: any;

  suscriberAuth: Subscription;
  user = {
    email: '',
    photoURL: '',
    displayName: '',
  }
  apariencia: Apariencia = Parametros.apariencia;
  dark = false;
  imageLogoHeader: string = null;


  constructor(public router: Router,
              private autenticationService: AutenticationService,
              private popoverController: PopoverController,
              private frontendService: FrontendService,
              private interaccionService: InteraccionService,
              private notificationsService: NotificationsService,
              private firebaseService: FirestoreService,
              public menuController: MenuController,
              private windowService: WindowService,
              private appearanceService: AppearanceService // ---- templates
              ) {


                if (this.windowService.isServer()) {return};

                this.router.events.subscribe((event: any) => {
                  if (event.url !== undefined) {
                    const url = event.url as string;
                    const urlArray = event.url.split('#');
                    const enlaceInput = urlArray[0];
                    this.changePage(enlaceInput);
                  }
                });

                this.notificationsService.getNewNotificationChanges().subscribe( res => {
                      this.newNotificaciones = res;
                });

                const path = CrudDatos.config.path + CrudDatos.config.id;
                this.suscriberInfo = this.firebaseService.getDocumentChanges<any>(path).subscribe( res => {
                        this.info = res;  
                        this.setImageLogoHeader();          
                });
                this.loadAppearance();   // ---- templates

  }

  ngOnInit(): void {

      if (this.windowService.isServer()) {return};
      this.autenticationService.getUidChanges().subscribe( res => {
        if (res === environment.uidAdmin) {
          this.isAdmin = true;
        }
      });
      this.menuHearder = this.frontendService.getMenu();
      this.frontendService.getMenuChanges().subscribe( res => {
        this.menuHearder = res;
      });

      this.suscriberAuth = this.autenticationService.authState().subscribe( res => {
        if (res) {
          if (res.photoURL) {
              this.user.photoURL = res.photoURL;
          }
        }
      });

      this.dark = this.appearanceService.getDarkMode();
      this.appearanceService.getDarkModeChanges().subscribe( res => {
          this.dark = res;
          this.setImageLogoHeader();
      })
  }

  ngOnDestroy() {
    if (this.suscriberInfo) {
      this.suscriberInfo.unsubscribe();
    }
    if (this.suscriberAuth) {
      this.suscriberAuth.unsubscribe();
    }
  }

  clickMenu() {
    // this.menu.emit();
    this.menuController.open('side');
    console.log('clickMenu()');
    // this.menuController.enable(false, 'side');
  }

  changePage(path: string) {
    this.seccion = path;
  }

  async openLogin(event: any) {
    await this.interaccionService.presentLoading('cargando...');
    const popover = await this.popoverController.create({
      component: PopLoginComponent,
      cssClass: 'popoverLogin',
      event,
      mode: 'ios',
    });
    this.interaccionService.dismissLoading();
    await popover.present();
  }

  async openNotifications(event: any) {
    this.notificationsService.stopSound();
    await this.interaccionService.presentLoading('cargando...');
    const popover = await this.popoverController.create({
      component: PopNotificationsComponent,
      cssClass: 'popoverSearch',
      event,
      mode: 'ios',
    });
    this.interaccionService.dismissLoading();
    await popover.present();
  }

  loadAppearance() {   // ---- templates
    this.apariencia = this.appearanceService.getApariencia();
    this.appearanceService.getAparienciaChanges().subscribe( apariencia => {
      this.apariencia = apariencia;  
    });
  }

  setImageLogoHeader() {
     if (this.info) {
          if (!this.dark) {
            this.imageLogoHeader = this.info.logo ? this.info.logo : null;
          } else {
            this.imageLogoHeader = this.info.logoDark ? this.info.logoDark : (this.info.logo ? this.info.logo : null);
          }
     }
  }



}
