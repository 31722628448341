import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from '../../../../environments/environment.prod';
// import { FirebaseService } from '../../../store/services/firebase.service';
import { Subscription } from 'rxjs';
// import { InfoLocal } from '../../../store/models-store';
import { FirestoreService } from '../../../services/firestore.service';
import { Platform } from '@ionic/angular';
import { MenuHeader, CrudDatos, DatosEmpresaI } from '../../backend/models-common-crud';
import { FrontendService } from '../../../services/frontend.service';
import { WindowService } from '../../../services/window.service';
import { AppearanceService, Apariencia } from '../../../services/appearance.service';
import { Parametros } from '../../../../environments/parametros';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  year = (new Date()).getFullYear();
  suscriberInfo: Subscription;
  info: DatosEmpresaI = null;

  menuHearder: MenuHeader[] = [];
  apariencia: Apariencia = Parametros.apariencia;

  constructor(private firebaseService: FirestoreService,
              private frontendService: FrontendService,
              private windowService: WindowService,
              private appearanceService: AppearanceService) { 

      if (this.windowService.isServer()) {return};
      const path = CrudDatos.config.path + CrudDatos.config.id;
      this.suscriberInfo = this.firebaseService.getDocumentChanges<any>(path).subscribe( res => {
            if (res) {
              res.domain = res.domain.split('//')[1];
              this.info = res;
              // console.log('info ->', res);
              if(this.info.celular) {
                  if (this.info.celular.e164Number && this.info.celular.number) {
                    this.info.celular.number = this.info.celular.number.replace(' ', '');
                    const codePais = this.info.celular.e164Number.replace(this.info.celular.number, '');
                    this.info.celular.countryCode = codePais;
                  }
              }
              if(this.info.celulares) {
                this.info.celulares.forEach( celularAditional => {
                  if (celularAditional.celular.e164Number && celularAditional.celular.number) {
                    celularAditional.celular.number = celularAditional.celular.number.replace(' ', '')
                    const codePais = celularAditional.celular.e164Number.replace(celularAditional.celular.number, '');
                    celularAditional.celular.countryCode = codePais;
                  }
                })
              }     
            }
      });
      this.menuHearder = this.frontendService.getMenu();
      this.frontendService.getMenuChanges().subscribe( res => {
        this.menuHearder = res;
      });
      this.loadAppearance();

  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    if (this.suscriberInfo) {
      this.suscriberInfo.unsubscribe();
    }
  }

  loadAppearance() {
    this.apariencia = this.appearanceService.getApariencia();
    this.appearanceService.getAparienciaChanges().subscribe( apariencia => {
      this.apariencia = apariencia;  
    });
  }

}
