import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Subject, Observable } from 'rxjs';
import { Suscripcion, UserInfo } from '../models';
import { FirestoreService } from './firestore.service';
import { environment } from '../../environments/environment.prod';
import { InteraccionService } from './interaccion.service';
import { WindowService } from './window.service';


import { Plugins } from '@capacitor/core';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';

import { GoogleAuthProvider } from "firebase/auth";


const { SignInWithApple } = Plugins;

// import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { isPlatform } from "@ionic/angular";

@Injectable({
  providedIn: 'root'
})
export class AutenticationService {

  // * variables david
  userDavid = null;
  // * variables david

  private uid: string;
  private uid$ = new Subject<string>();
  private isLogin = false;

  private islogin: boolean = null;
  private providerGoogle = new GoogleAuthProvider();


  constructor(private AuthFire: AngularFireAuth,
    private firestoreService: FirestoreService,
    private interaccionService: InteraccionService,
    private windowService: WindowService,
    private platform: Platform,
    private router: Router) {

    if (!this.windowService.isServer()) {
      this.uidSuscriber();
      this.AuthFire.getRedirectResult().then(async (res) => {
        // console.log('getRedirectResult() -> ', res);
        if (res.user) {
          const uid = await this.getPromiseUid();
          const datos: UserInfo = {
            email: res.user.email,
            password: 'google.com',
            nombre: res.user.displayName,
            suscrito: true,
            id: uid,
            photoUrl: res.user.photoURL,
            celular: { number: res.user.phoneNumber, e164Number: null, countryCode: null },
            rol: 'usuario'
          };
          this.guardarInfoUser(datos)
        }

      });
    }

    if (!isPlatform('capacitor')) {
      // GoogleAuth.initialize()
    }

  }

  authState() {
    return this.AuthFire.authState;

  }


  setTimeOutLogin(time: number) {
    setTimeout(async () => {
      if (!(await this.getUid()).length) {
        // console.log('login force en autenticationservice');
        this.loginForce();
      }
    }, time * 1000);
  }

  login(email: string, password: string) {
    return this.AuthFire.signInWithEmailAndPassword(email, password);
  }

  logout() {
    this.router.navigate(['/']);
    return this.AuthFire.signOut();
  }

  getUid(): Promise<string> {
    return new Promise(async (resolve) => {
      if (this.windowService.isServer()) {
        resolve('');
        return;
      }
      if (this.isLogin) {
        resolve(this.uid);
        return;
      } else {
        const user = await this.AuthFire.currentUser;
        if (user) {
          resolve(user.uid);
          return;
        } else {
          resolve('');
          return;
        }
      }
    });
  }

  getUidChanges(): Observable<string> {
    // this.uid$.next(this.uid);
    return this.uid$.asObservable();
  }

  async updateEmail(email: string) {
    const user = await this.AuthFire.currentUser;
    return user.updateEmail(email)
  }

  async updatePassword(newPassword: string) {
    const user = await this.AuthFire.currentUser;
    return user.updatePassword(newPassword);
  }

  private uidSuscriber() {
    this.AuthFire.authState.subscribe(res => {
      if (res) {
        this.uid = res.uid;
        console.log('login -> uid: ', this.uid);
        this.getSuscripcion().then(suscripcion => {
          // console.log('suscripcion -> ', suscripcion);
        });
        this.isLogin = true;
        this.islogin = true;
      } else {
        console.log('no login');
        this.uid = '';
        this.isLogin = false;
      }
      this.uid$.next(this.uid);
    });
  }

  loginForce(datosUser?: UserInfo): Promise<string | boolean> {

    return new Promise(async (resolve, reject) => {

      if (this.isLogin) {
        resolve(this.uid);
        return;
      }
      const id = this.firestoreService.createIdDoc().toLocaleLowerCase();
      let datos: UserInfo;
      if (datosUser) {
        datos = datosUser;
      } else {
        datos = {
          email: id + '@gmail.com',
          password: id,
          nombre: 'usuario',
          suscrito: false,
          id: '',
          rol: 'usuario'
        };
      }
      console.log('canLoginOrCreateUser');
      this.canLoginOrCreateUser(datos).then(async (res) => {
        if (res) {
          console.log('res can login or create -> ', res);
          // this.guardarInfoUser(datos);
          resolve(res);
          return;
        } else {
          console.log('falló canLoginOrCreateUser');
          resolve(res);

          // reject('falló canLoginOrCreateUser');
          return;
        }
      });
    });
  }

  private crearUsuario(data: UserInfo): Promise<string | boolean> {

    return new Promise(async (resolve) => {
      console.log('creo un usuario con email:', data.email, 'y password:', data.password, 'fin');
      this.AuthFire.createUserWithEmailAndPassword(data.email, data.password).catch(() => {
        console.log('usuario existente');
        resolve(false);
        return;

      }).then(async (res) => {
        console.log('crearUsuario then -> ', res);
        if (res) {
          const user = await this.AuthFire.currentUser;
          // console.log('crearUsuario ->', user);
          await user.updateProfile({ displayName: data.nombre });
          this.uid$.next(this.uid);
          data.id = user.uid;
          this.guardarInfoUser(data);
          resolve(data.id);
          return;
        } else {
          resolve(false);
          return;
        }
      });
    });
  }

  async updateProfileName(nombre: string) {
    console.log('update profile');
    const user = await this.AuthFire.currentUser;
    await user.updateProfile({ displayName: nombre });
  }

  async updatePhotoUrl(foto: string) {
    console.log('updatePhotoUrl ->', foto);
    const user = await this.AuthFire.currentUser;
    await user.updateProfile({ photoURL: foto }).then(res => {
      console.log('save photoUrl with success', res);
    });
  }

  private canLoginOrCreateUser(datos: UserInfo): Promise<string | boolean> {

    return new Promise(async (resolve, reject) => {

      this.login(datos.email, datos.password)
        .then(async () => {
          // console.log('exito al iniciar sesión');
          const user = await this.AuthFire.currentUser;
          resolve(user.uid);
          return;
        })
        .catch(() => {
          // sino funciona el inicio de sesion crea un nuevo usuario
          console.log('falló iniciar sesion, crea uno -> ', datos);
          this.crearUsuario(datos).then(async (res) => {
            // console.log('exito al crear usuario -> ', res);
            resolve(res);
            return;
          }).catch((error) => {
            console.log('no se pudo crear usuario por primera vez', datos);
            reject(error);
          });
        });
    });

  }

  private guardarInfoUser(datos: UserInfo) {
    const path = environment.pathUsuarios;
    if (datos.nombre !== 'usuario') {
      datos.suscrito = true;
    }
    datos.password = 'password';
    this.firestoreService.updateDocumentID(datos, path, datos.id).then(() => {
      console.log('guardarInfoUser update -> ', datos);
    })
      .catch(() => {
        datos.rol = 'usuario';
        this.firestoreService.createDocumentID(datos, path, datos.id).then(() => {
          console.log('guardarInfoUser created -> ', datos);
        })
      })
  }

  async getProfileNombre(): Promise<string> {
    const user = await this.AuthFire.currentUser;
    if (user) {
      return user.displayName;
    }
    return '';
  }

  private updatePasswordAndEmail(newEmail: string, nombre: string): Promise<boolean> {

    return new Promise(async (resolve) => {
      this.interaccionService.presentLoading();
      let user = await this.AuthFire.currentUser;
      const actualEmail = user.email;
      let array = actualEmail.split('@');
      const actualPassword = array[0];
      // console.log('actualEmail: ', actualEmail, 'actualPassword', actualPassword);
      array = newEmail.split('@');
      const newPassword = array[0];
      if (actualEmail === newEmail) {
        this.interaccionService.showToast('nombre cambiado con éxito');
        await user.updateProfile({ displayName: nombre });
        this.interaccionService.dismissLoading();
        resolve(true);
        return;
      }
      await this.login(actualEmail, actualPassword);
      user.updateEmail(newEmail).catch(async (_) => {
        await this.login(newEmail, newPassword);
        user = await this.AuthFire.currentUser;
        const dataUser: UserInfo = {
          email: newEmail,
          nombre,
          suscrito: true,
          id: user.uid,
          rol: 'usuario'
        };
        user.updateProfile({ displayName: dataUser.nombre });
        this.guardarInfoUser(dataUser);
        this.interaccionService.showToast('usuario previamente suscrito :)');
        this.interaccionService.dismissLoading();
        resolve(true);
        return;
      }).then(async (_) => {
        // console.log('newPassword: ', newPassword);
        await user.updatePassword(newPassword);
        const data: UserInfo = {
          email: newEmail,
          nombre,
          suscrito: true,
          id: user.uid,
          rol: 'usuario'
        };
        user.updateProfile({ displayName: data.nombre });
        await this.guardarInfoUser(data);
        this.interaccionService.showToast('suscrito con éxito');
        this.interaccionService.dismissLoading();
        resolve(true);
      });



    });
  }

  registrarUser(email: string, nombre: string): Promise<boolean> {

    return new Promise(async (resolve) => {
      if (!nombre.length) { nombre = 'Usuario'; }
      if (this.isLogin) {
        const update = await this.updatePasswordAndEmail(email, nombre);
        if (update) {
          resolve(true);
        } else { resolve(false); }
      } else {
        const array = email.split('@');
        const password = array[0];
        const datos: UserInfo = {
          email,
          password,
          nombre,
          suscrito: true,
          id: '',
          rol: 'usuario'
        };
        const login = await this.loginForce(datos);
        if (login) {
          resolve(true);
        } else { resolve(false); }
      }
    });
  }

  async isUserSuscrito(): Promise<boolean> {
    const user = await this.AuthFire.currentUser;
    if (user) {
      if (user.displayName !== 'usuario' && user.displayName !== null) {
        return true;
      }
    }
    return false;
  }

  async getSuscripcion(): Promise<Suscripcion> {
    const user = await this.AuthFire.currentUser;
    if (user) {
      const suscripcion: Suscripcion = {
        email: user.email,
        nombre: user.displayName,
        uid: user.uid,
        photoURL: user.photoURL ? user.photoURL : null
      };
      return suscripcion;
    }
    return null;
  }

  getPromiseUid(): Promise<string> {
    return new Promise(async (resolve) => {
      if (this.windowService.isServer()) {
        resolve('');
        return;
      }
      if (this.islogin === true) {
        resolve(this.uid);
        return;
      } else if (this.islogin === false) {
        resolve('');
        return;
      } else {
        const suscriber = this.AuthFire.authState.subscribe(res => {
          suscriber.unsubscribe();
          if (res) {
            resolve(res.uid);
            return;
          } else {
            resolve('');
            return;
          }

        });
      }
    });
  }

  // onLoginSuccess(accessToken: any, accessSecret: any) {
  onLoginSuccess(credential: any) {
    // this.interaccionService.presentLoading('ingresando...');
    // const credential = accessSecret ? GoogleAuthProvider
    //     .credential(accessToken, accessSecret) : GoogleAuthProvider
    //         .credential(accessToken);
    this.AuthFire.signInWithCredential(credential)
      .then(async (success) => {
        // console.log('success -> ', success);
        if (success.user) {
          const uid = await this.getPromiseUid();
          const datos: UserInfo = {
            email: success.user.email,
            password: 'google.com',
            nombre: success.user.displayName,
            suscrito: true,
            id: uid,
            photoUrl: success.user.photoURL,
            //  celular: {number: success.user.phoneNumber, e164Number: null, countryCode: null },
            //  rol: 'usuario'
          };
          this.guardarInfoUser(datos)
        }
        this.interaccionService.dismissLoading();
      }).catch(() => {
        this.interaccionService.dismissLoading();
      })

  }

  async loginWithGoogle() {
    // this.interaccionService.presentLoading('procesando...');
    // Plugins.GoogleAuth.signIn().then( (googleUser: any) => {
    //   this.interaccionService.dismissLoading();
    //   this.onLoginSuccess(googleUser.authentication.idToken, googleUser.authentication.accessToken);
    // }) .catch( () => {
    //   this.interaccionService.dismissLoading();
    // });
    this.AuthFire.signInWithPopup(this.providerGoogle).then((res) => {
      this.onLoginSuccess(res.credential)
      console.log('success loginWithPopupGoogle')
    }).catch(() => {
      this.interaccionService.dismissLoading();
      console.log('error loginWithPopupGoogle');
    });
  }

  async newLoginGoogleDavid() {
    // this.userDavid = await GoogleAuth.signIn();
    // console.log("🚀 ~ file: autentication.service.ts ~ line 476 ~ AutenticationService ~ newLoginGoogleDavid ~ userDavid", this.userDavid)

  }

  async refreshDavid() {
    // const authCode = await GoogleAuth.refresh();
    // console.log("🚀 ~ file: autentication.service.ts ~ line 483 ~ AutenticationService ~ refreshDavid ~ authCode", authCode)
  }

  async signOutDavid() {
    // await GoogleAuth.signOut();
    // this.userDavid = null;
  }


  loginWithFacebook() {
    this.interaccionService.presentLoading('redireccionando...')
    // this.AuthFire.signInWithRedirect(new firebase.auth.FacebookAuthProvider());
  }

  async getPhoteUrlProfile(): Promise<string> {
    return new Promise(async (resolve, reject) => {
      const user = await this.AuthFire.currentUser;
      if (user) {
        if (user.photoURL) {
          if (user.photoURL.length) {
            resolve(user.photoURL);
            return;
          }
        }
      }
      resolve('');
      return;
    });
  }


  // SING WITH APPLE
  async creatUserWithAppleID(responde: ResponseAppleLogin) {
    // this.interaccionService.presentLoading('ingresando...');
    // const provider = new firebase.auth.OAuthProvider('apple.com');
    // const credential = provider.credential({idToken: responde.identityToken});
    // console.log('After sing in -> ', credential);
    // this.AuthFire.signInWithCredential(credential)
    // .then((success) => {
    //     const datos: UserInfo = {
    //         email: responde.email,
    //         password: 'apple',
    //         nombre: null,
    //         suscrito: true,
    //         id: success.user.uid,
    //         rol: 'usuario'
    //     };
    //     if (success.user.photoURL) {
    //       datos.photoUrl = success.user.photoURL;
    //     }
    //     if (success.user.phoneNumber) {
    //       datos.celular = {number: success.user.phoneNumber, e164Number: null, countryCode: null }
    //     }
    //     if (responde.givenName) {
    //         datos.nombre =  responde.givenName + ' ' + responde.familyName;
    //         this.updateProfileName(datos.nombre)
    //     }
    //     this.guardarInfoUser(datos)
    //     console.log('success -> ', success);
    //     this.interaccionService.dismissLoading();
    // }).catch( () => {
    //     this.interaccionService.dismissLoading();
    // })
  }

  async signInWithApple() {
    this.interaccionService.presentLoading('procesando...');
    SignInWithApple.Authorize().then((res: any) => {
      console.log('signInWithApple() -> ', res.response)
      this.creatUserWithAppleID(res.response);
    }).catch((response: any) => {
      console.error('signInWithApple() -> ', response)
      this.interaccionService.dismissLoading();
    })
  }

  resetPassword(email: string) {
    return this.AuthFire.sendPasswordResetEmail(email);
  }












}


interface ResponseAppleLogin {
  email: string;
  identityToken: string;
  familyName: string;
  user: string;
  givenName: string;
  authorizationCode: string;
}
