import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sizebytes'
})
export class SizebytesPipe implements PipeTransform {

  transform(value: number): string {
    let size: string;
    if (value >= 1000000) {
        size = (value / 1000000).toFixed(1) + 'Mb';
        return size;
    }
    if (value >= 1000) {
      size = (value / 1000).toFixed(1) + 'Kb';
      return size;
    }
    size = value + 'Kb';
    return size;
  }

}
