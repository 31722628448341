export interface Campo {
    type?: campoType;
    // input?: InputType;
    name: string;
    label?: string;
    value?: any;
    // labelPosition?: LabelPosition;
    editable: boolean;
    subCampos?: Campo[];
    categorias?: CategoriaItem[];
    pathOutside?: string;
    loaded?: boolean;
    pathDoc?: string;
    validaterDoc?: any;
    pathColl?: string;
    selectCampo?: string;
    campoCollLabel?: string;
    arrayName?: string;
    selectCampos?: Campo[];
    maxlength?: number;
    minDisabled?: boolean; 
}

export interface CategoriasItem {
    categorias: CategoriaItem[];
 }
 
 export interface CategoriaItem {
    nombre: string;
    descripcion: string;
    id: string;
    imagen: ImageItem;
    subCategorias: CategoriaItem[];
    path?: string;
 }

 export interface ImageItem {
    url: string;
    thumbnail: string;
}


export interface ConfigCrudFast {
    estructura: Campo[];
    setVar: any;
    path: string;
    searchPath?: string;
    multiple: boolean;
    title: string;
    labelAll: string;
    id?: string;
    editPath?: string;
    viewPath?: string;
    categoriasPath?: string;
    functionValidator?: any;
    functionAsyncValidator?: any;
}

export interface SearchDoc {
    nombre: string;
    descripcion: string;
    id: string;
    enlacePermanente: string;
    show?: boolean;
    no_visible?: boolean;
}

export interface ConfigSearchCrud {
    path: string;
    searchPath?: string;
    editPath: string;
    viewPath: string;
    title: string;
}


export type LabelPosition = 'floating' | 'fixed' | 'stacked' | '';
// export type InputType = 'input' | 'checkbox' | 'textarea' | 'img' | 'datetime' | 'select' | 'html' | 'css' | 'thumbnail' | 'categorias' | 'outside';

export type campoType = 'checkbox' | 'textarea' | 'img' | 'datetime' | 'daterange' | 'select' | 'html' |
                        'css' | 'thumbnail' | 'categorias' | 'outside' | 'string' | 'number' |
                        'boolean' | 'Objeto' | 'array' | 'outside' | 'map' | 'horatime' |
                        'document' | 'collection' | 'pdf' | 'color' | 'phone' | 'galeria';

export const ImagenEstructura: Campo[] = [
    { name: 'url', type: 'img', label: 'Imagen', value: '', editable: true },
    { name: 'thumbnail', value: '', editable: false },
];

export const PdfEstructura: Campo[] = [
    { name: 'url', type: 'pdf', label: 'Archivo (máximo 10Mb)', value: '', editable: true },
    { name: 'nombre', type: 'string', label: 'Texto', value: '', editable: true },
    { name: 'path', value: '', editable: false },
];

export interface ImageItem {
    url: string;
    thumbnail: string;
}