import { Component, Input, OnInit, HostListener } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-css-editor',
  templateUrl: './css-editor.component.html',
  styleUrls: ['./css-editor.component.scss']
})
export class CssEditorComponent implements OnInit {

  @Input()  css = '';
  @Input() enlaceBefore = '/';
  // @Input()  banner: Banner;

  @HostListener('window:popstate', ['$event'])
  onPopState() {
        this.modalController.dismiss();
  }

  constructor(public modalController: ModalController) {

  }

  ngOnInit(): void {
      console.log('enlaceBefore -> ', this.enlaceBefore);
      // this.css = this.css.replace(/<style>/gi, '');
      // this.css = this.css.replace(/<\/style>/gi, '');
      if (this.enlaceBefore) {
        history.pushState(null, null, this.enlaceBefore);
      }
  
  }

}
