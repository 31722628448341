import { environment } from '../../environments/environment.prod';
import { Campo } from '../widgets/crudfast/models-crud';
// MODULO SERVICIOS
export interface Servicio {
    id: string;
    enlacePermanente: string;
    nombre: string;
    descripcion: string;
    imagen: any;
    imagenDestacada: string;
    detalles: string;
    contenido: DetalleServicioI;

    likes: number;
    comments: number;
    views: number;

    fecha: Date;
} 

export interface DetalleServicioI {
   banner: string;
   parrafos: {titulo: string, descripcion: string, imagen: string}[]
}

const DetalleServicioEstructura: Campo[] = [
  { name: 'banner', type: 'img', label: 'Añadir imagen principal', value: null, editable: true },
  { name: 'parrafos', type: 'array', label: 'Añadir parrafo', value: [], editable: true,
  subCampos: [
    { name: 'titulo', type: 'string', label: 'Título', value: '', editable: true },
    { name: 'descripcion', type: 'html',  label: 'Descripción', value: '', editable: true },
    { name: 'imagen', type: 'img',  label: 'Imagen ', value: '', editable: true },
  ]},
  
]

export const ImagenEstructura: Campo[] = [
    { name: 'url', type: 'img', label: 'Imagen', value: '', editable: true },
    { name: 'thumbnail', value: '', editable: false },
];

export const ServicioEstructura: Campo[] = [
    { name: 'id', type: 'string', value: '', editable: false },
    { name: 'nombre', type: 'string',  label: 'Nombre', value: '', editable: true }, 
    { name: 'enlacePermanente', type: 'string', value: '', editable: false },
    { name: 'descripcion', type: 'textarea',  label: 'Descripción corta', value: '', editable: true },
    { name: 'imagenDestacada', type: 'img', label: 'Añadir imágen', value: null, editable: true },
    { name: 'fecha', type: 'datetime',  label: 'fecha', value: new Date(), editable: false},

    { name: 'contenido', type: 'outside', label: 'Detalles', pathOutside: "contenido", 
      subCampos: DetalleServicioEstructura, value: null, loaded: false, editable: true},
 
    { name: 'likes', value: null, editable: false },
    { name: 'views', value: null, editable: false },
    { name: 'comments', value: null, editable: false },
  
    
    
  ];
  
  export const  CrudServicios = {
    icon: 'server-outline',
    label: 'Páginas Servicios',
    url: 'servicios',
    colorIcon: '#f8f9fa',
    colorBackgroud: 'linear-gradient(90deg, #f44336 0%, #ff5722 100%)',
    config: {
      title: 'Servicios',
      labelAll: 'Todos los Servicios',
      setVar: {},
      estructura: ServicioEstructura,
      path: 'Servicios/' + environment.uidAdmin + '/servicios/',
      multiple: true,
      editPath: '/pages/set/servicios/',
      viewPath: '/servicios/servicio/',
      functionValidator: null
    }
}


