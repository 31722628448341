import { Campo, ImageItem } from '../../widgets/crudfast/models-crud';
import { environment } from '../../../environments/environment.prod';
import { ImagenEstructura } from '../../servicios/models-servicios';

const PreguntaChatbotEstructura: Campo[] = [
    { name: 'id', type: 'string', value: null, editable: false },
    { name: 'nombre', type: 'string', label: 'Identificador:', value: '', editable: true },
    { name: 'preguntas', type: 'array', label: 'Añadir posible pregunta:', value: [], editable: true, 
      subCampos: [
        { name: 'nombre', type: 'textarea', label: 'Pregunta:', value: [], editable: true}      
    ] },
    { name: 'respuestas', type: 'array', label: 'Añadir respuesta:', value: [], editable: true, 
      subCampos: [
        { name: 'respuesta', type: 'html', label: 'Respuesta:', value: '', editable: true },
    ] },
];




export const  CrudPreguntasChatbot = {
    icon: 'chatbubble-ellipses',
    label: 'Ajustes de preguntas',
    url: 'preguntas',
    colorIcon: 'white',
    colorBackgroud: 'linear-gradient(90deg, #ffc107 0%, #ffe687 100%)',
    config: {
        title: 'Preguntas',
        labelAll: 'Todas las preguntas',
        setVar: {},
        estructura: PreguntaChatbotEstructura,
        path: 'Usuarios/' + environment.uidAdmin + '/chatbot/',
        multiple: true,
        editPath: '/chatbot/preguntas/',
        viewPath: null,
        functionValidator: () => {
                console.log('guardar preguntas de chatbot');            
        }
    }
}


const ParametrosChatbotEstructura: Campo[] = [
    { name: 'imagenBot', type: 'Objeto', label: 'Imagen del bot', value: {}, editable: true, subCampos: ImagenEstructura},
    { name: 'nombreBot', type: 'string', label: 'Nombre del bot:', value: '', editable: true },
    { name: 'descripcionBot', type: 'string', label: 'Descripción corta del bot:', value: '', editable: true }, 
    { name: 'colorMarco', type: 'color', label: 'Color del marco', value: null, editable: true },
    { name: 'colorContraste', type: 'color', label: 'Color de contraste', value: null, editable: true },  
    { name: 'iconoBot', type: 'select', label: 'Ícono chatbot flotante', value: 
        [{ nombre: 'primero' }, { nombre: 'segundo' }, { nombre: 'tercero' }], editable: true },
    { name: 'mensajeBienvenida', type: 'html', label: 'Mensaje de Bienvenida', value: '', editable: true },
    { name: 'mensajeFlotante', type: 'string', label: 'Saludo inicial flotante', value: '', editable: true },
    { name: 'respuestaDefualt', type: 'html', label: 'Respuesta por defecto', value: '', editable: true },
    { name: 'fondoChatBot', type: 'Objeto', label: 'Fondo del chatbot', value: {}, editable: true, subCampos: ImagenEstructura},   
 ];

export const  CrudParametrosChatbot = {
    icon: 'settings',
    label: 'Ajustes de parametros',
    url: 'parametros',
    colorIcon: 'white',
    colorBackgroud: 'linear-gradient(90deg, #198754 0%, #28a745 100%)',
    config: {
        title: 'Parámetros',
        labelAll: '',
        setVar: {},
        estructura: ParametrosChatbotEstructura,
        path: 'Usuarios/' + environment.uidAdmin + '/parametrosChatbot/',
        multiple: false,
        id: environment.uidAdmin,
        editPath: '/chatbot/parametros/',
        functionValidator: () => {
                console.log('guardar parametros del chatbot');            
        }
    }
}

export interface ChatbotPreguntaI {
    id: string;
    nombre: string;
    preguntas: {
        nombre: string;
    }[],
    respuestas: {
        respuesta: string;
    }[]
}

export interface ParametrosChatbotI {
    imagenBot: ImageItem;
    nombreBot: string;
    descripcionBot: string;
    colorMarco: string;
    colorContraste: string;
    iconoBot: 'primero' | 'segundo' | 'tercero' | string;
    mensajeBienvenida: string;
    mensajeFlotante: string;
    fondoChatBot: ImageItem;
    respuestaDefualt: string;
}

