import { Component, OnInit } from '@angular/core';
import { FrontendService } from '../../services/frontend.service';
import { Platform, ModalController, PopoverController } from '@ionic/angular';
import { Chat } from '../models-chat';
import { Subject } from 'rxjs';
import { MultichatComponent } from '../componentes/multichat/multichat.component';
import { InteraccionService } from '../../services/interaccion.service';
import { SendpedidowppComponent } from '../componentes/sendpedidowpp/sendpedidowpp.component';
import { ActivatedRoute } from '@angular/router';
import { AutenticationService } from '../../services/autentication.service';
import { environment } from '../../../environments/environment';
import { FirestoreService } from '../../services/firestore.service';

@Component({
  selector: 'app-chats',
  templateUrl: './chats.component.html',
  styleUrls: ['./chats.component.scss']
})
export class ChatsComponent implements OnInit {

  isMobile = false;

  chatSelect: Chat;
  chatSelect$ = new Subject<Chat>();
  sendPedidoWpp = false;
  IsOpenChat = false;

  constructor(private frontendService: FrontendService,
              private platform: Platform,
              private modalController: ModalController,
              private interaccionService: InteraccionService,
              private popoverController: PopoverController,
              private activateRoute: ActivatedRoute,
              private autenticationService: AutenticationService,
              private firestoreService: FirestoreService) {

        console.log('constructor -> pagina ->', this.activateRoute.snapshot.params);

        if (this.activateRoute.snapshot.params.sendwpp) {
              this.sendPedidoWpp = true;
        } else  {
          this.sendPedidoWpp = false;
        }

        if (this.activateRoute.snapshot.params.chatId) {
           this.loadChatId(this.activateRoute.snapshot.params.chatId);
        }
                
        this.frontendService.clearBanner();
     
        if (this.platform.width() <= 574) {
              this.isMobile = true;
        }

  }

  ngOnInit(): void {
      
  }

  async openChat(chat: Chat) {
    console.log('openChat en chats');
    if (this.platform.width() <= 574) {
        this.isMobile = true;
        if (this.IsOpenChat) { return; }
        this.IsOpenChat = true;
        const modalAdd  = await this.modalController.create({
          component: MultichatComponent,
          mode: 'ios',
          swipeToClose: true,
          cssClass: 'aviso',
          componentProps: {obsChat: this.chatSelect$.asObservable(), modal: true}
        });
        await modalAdd.present();
        if (this.sendPedidoWpp && environment.enableWhatsapp) {
          this.openSendPedidoWpp(chat);
        }
        this.chatSelect$.next(chat)
        await modalAdd.onWillDismiss();
        this.IsOpenChat = false;
    } else {
        this.isMobile = false;
        if (this.sendPedidoWpp && environment.enableWhatsapp) {
          this.openSendPedidoWpp(chat);
        }
        this.chatSelect$.next(chat)
    }
  }

  async openSendPedidoWpp(chat: Chat) {
    this.sendPedidoWpp = false;
    await this.interaccionService.presentLoading('cargando...');
    const popover = await this.popoverController.create({
      component: SendpedidowppComponent,
      cssClass: 'popoverSearch',
      mode: 'ios',
      componentProps: {chat, pedido: chat.mensaje}
    });
    this.interaccionService.dismissLoading();
    await popover.present();
  }

  async loadChatId(id: string) {
    await this.interaccionService.presentLoading('cargando...');
    const uid = environment.uidAdmin
    if (uid) {
      const path = environment.pathRoot + uid + '/chat/' + id; 
      this.firestoreService.getDocument<Chat>(path).then( res => {
            this.interaccionService.dismissLoading();
            if (res.exists) {
                const chat = res.data() as Chat;
                this.openChat(chat);
            }
      });
    } else {
      this.interaccionService.dismissLoading();  
    }
  }

  

}
