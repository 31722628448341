import { map } from 'rxjs/operators';
import { pipe } from 'rxjs';
import { customClaims } from '@angular/fire/compat/auth-guard';
import { Parametros } from '../../environments/parametros';
import { Campo } from '../widgets/crudfast/models-crud';

export type RolesUsers = 'admin' | 'editor' | 'asesor' | 'usuario';
export const Roles: RolesUsers[] = ['admin', 'editor', 'asesor', 'usuario'];
export type RolAction = 'add' | 'remove' | 'edit';
export interface UsuarioRol {
    rol: RolesUsers,
    type: RolAction,
    id: string,
    nombre?: string,
    password?: string,
    email?: string,
    repassword?: string;
}

export interface GestorUsuario {
    rol: RolesUsers,
    usuarios: UsuarioRol[]
}

export type ProviderUser = 'password' | 'google' | 'facebook.com' | 'google.com' | 'apple.com' | 'withoutPassword';

export interface ProviderI {
    name: ProviderUser,
    class: string;
    text: string;
    icon: string;
    colorIcon: string;
    action: any;
  }
  
export const Providers: ProviderI[] = [
    {
      name: 'withoutPassword',
      class: 'item-email',
      text: 'Configura una contraseña',
      icon: 'key',
      colorIcon: 'primary',
      action: null,
    },
    {
      name: 'password',
      class: 'item-email',
      text: 'Ingresado con credenciales',
      icon: 'mail',
      colorIcon: 'dark',
      action: null,
    },
    {
      name: 'google.com',
      class: 'item-google',
      text: 'Ingresado con cuenta de Google',
      icon: 'logo-google',
      colorIcon: 'primary',
      action: null,
    },
    {
      name: 'google',
      class: 'item-google',
      text: 'Ingresado con cuenta de Google',
      icon: 'logo-google',
      colorIcon: 'primary',
      action: null,
    }
]


export const FormInfoUser: {estructura: Campo[], setVar: any} = {
    estructura: [
      { name: 'celular', type: 'phone',  label: 'Celular:', value: {}, editable: true }],
    setVar: {}
  }

// const onlyAdmin = () => map( (user: any) => !!user && (user.uid === uidAdmin) );

export const isOnlyAdmin = () => pipe(customClaims, map(claims => (claims.rol === 'admin' || claims.user_id === Parametros.uidAdmin)));
export const isOnlyEditor = () => pipe(customClaims, map(claims => claims.rol === 'editor'));
export const isOnlyAsesor = () => pipe(customClaims, map(claims => claims.rol === 'asesor'));
export const isAdminOrEditor = () => pipe(customClaims, map(claims => (claims.rol === 'admin' || claims.user_id === Parametros.uidAdmin) || claims.rol === 'editor'));
export const isAdminOrAsesor = () => pipe(customClaims, map(claims => (claims.rol === 'admin' || claims.user_id === Parametros.uidAdmin) || claims.rol === 'asesor'));
export const isAdminOrEditorOrAsesor = () => pipe(customClaims, map(claims => (claims.rol === 'admin' || claims.user_id === Parametros.uidAdmin) || claims.rol === 'editor' || claims.rol === 'asesor'));