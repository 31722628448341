import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConfigPage } from '../../widgets/loadpage/models-loadpage';
import { environment } from '../../../environments/environment.prod';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { FirestoreService } from 'src/app/services/firestore.service';

import { isObservable, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-pagina',
  templateUrl: './pagina.component.html',
  styleUrls: ['./pagina.component.scss'],
})
export class PaginaComponent implements OnInit, OnDestroy {

  configPage: ConfigPage = {
    path: 'Usuarios/' + environment.uidAdmin + '/paginas/',
    editPath: '/pages/set/',
    slug: '/pages/'
  };

  enlace: string = '';
  page: any = null; 
  suscriberRoute: Subscription;

  info: any;

  constructor(private activateRoute: ActivatedRoute,
              private firestoreService: FirestoreService) {

                this.suscriberRoute = this.activateRoute.params.subscribe( params => {
                    if (params.enlace !== undefined) {
                          this.page = null;     
                          this.loadArticulo(params.enlace);   
                    } 
                }); 

            
              }

  ngOnInit() {
  }
  ngOnDestroy() {
    console.log('ngOnDestroy() PaginaComponent');
    if (this.suscriberRoute) {
      this.suscriberRoute.unsubscribe();
    }
  }



  loadedData(ev: any) {
    if (ev !== null) {
          this.page = ev;
          this.enlace = ev.enlacePermanente;
    }
  }

  loadArticulo(enlace: string) {
    const path = this.configPage.path;
    console.log('in loadArticulo');

    this.firestoreService.getCollectionQuery<any>(path, 'enlacePermanente', enlace).subscribe( res => { 
        if (res !== undefined) {
              if (res.length) {
                const items = res;
                this.page = items[0];
                console.log('loadedArticulo in pagina ');
                // console.log('this.items ->', items);
              }
            }
    });
  }







}
