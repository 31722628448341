import { Injectable } from '@angular/core';
import { WindowService } from './window.service';
import { Subject, Subscription } from 'rxjs';
import { FirestoreService } from './firestore.service';
import { UserInfo } from '../models';

@Injectable({
  providedIn: 'root'
})
export class UidlocalService {

  private uidLocal: string;
  private uidLocal$ = new Subject<string>();
  private infoLocal: any;
  private infoLocal$= new Subject<any>();
  private infoChat: UserInfo;
  private infoChat$= new Subject<UserInfo>();
  private suscriberInfoLocal: Subscription;
  private suscriberInfoChat: Subscription;

  constructor(private windowService: WindowService,
              private firestoreService: FirestoreService) { }

  getUidLocal() {
    return this.uidLocal;
  }

  setUidLocal(uid: string) {
      this.uidLocal = uid;
      this.uidLocal$.next(this.uidLocal);
      this.loadInfoLocal();
      this.loadInfoChat();
  }

  getUidLocalChanges() {
     this.windowService.setTimeOut(200).then( () => {
        this.uidLocal$.next(this.uidLocal);
     });
     return this.uidLocal$.asObservable();
  }

  private loadInfoChat() {
    if (this.suscriberInfoChat) {
      this.suscriberInfoChat.unsubscribe();
    }
    const path = 'Usuarios/' + this.uidLocal;
    this.suscriberInfoChat = this.firestoreService.getDocumentChanges<UserInfo>(path).subscribe( res => {
        if (res) {
            this.infoChat = res;
            this.infoChat$.next(this.infoChat);
        }
    }); 
  }

  getInfoChatChanges() {
    this.windowService.setTimeOut(200).then( () => {
        if (this.infoChat) {
          this.infoChat$.next(this.infoChat);
        }
    });
    return this.infoChat$.asObservable();
  }

  private loadInfoLocal() {
      if (this.suscriberInfoLocal) {
        this.suscriberInfoLocal.unsubscribe();
      }
      const path = 'Usuarios/' + this.uidLocal + '/info/' + this.uidLocal;
      this.suscriberInfoLocal = this.firestoreService.getDocumentChanges<any>(path).subscribe( res => {
          if (res) {
              this.infoLocal = res;
              this.infoLocal$.next(this.infoLocal);
          }
      }); 
  }

  getInfoLocalChanges() {
    this.windowService.setTimeOut(200).then( () => {
        if (this.infoLocal) {
          this.infoLocal$.next(this.infoLocal);
        }
    });
    return this.infoLocal$.asObservable();
  }



}
