import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { AutenticationService } from '../../../services/autentication.service';
import { InteraccionService } from '../../../services/interaccion.service';
import { UserInfo } from '../../../models';
import { Subscription } from 'rxjs';
import { ToastController, PopoverController, Platform } from '@ionic/angular';
import { Parametros } from 'src/environments/parametros';
import { Router } from '@angular/router';
// import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import * as firebase from 'firebase/compat/app';

@Component({
  selector: 'app-pop-login',
  templateUrl: './pop-login.component.html',
  styleUrls: ['./pop-login.component.scss']
})
export class PopLoginComponent implements OnInit, OnDestroy {


  // * variables david
  picture;
  name;

  // * variables david

  @Input() mensaje = '';
  @Input() loginOnlyProviders = false;
  enableEmailAndPassword = false;

  providers = {
    emailAndPassword: true,
    facebook: false,
    google: true,
    apple: false,
  }
  parametros = Parametros;


  login = false;
  user = {
    email: '',
    photoURL: '',
    displayName: '',
  }
  email: string;
  password: string;
  repassword: string;
  suscrito = false;
  nombre: string;

  year = (new Date()).getFullYear();

  suscriberInfo: Subscription;
  info: any = null;

  suscriberAuth: Subscription;

  isIOS = false;
  emailIsNew = false;
  cargando = true;
  resetPassword: boolean = false;

  constructor(private autenticationService: AutenticationService,
    private interaccionService: InteraccionService,
    public toastController: ToastController,
    public popoverController: PopoverController,
    private router: Router,
    private platform: Platform,
    // private googlePlus: GooglePlus, 
    private afAuth: AngularFireAuth) {


    this.suscriberAuth = this.autenticationService.authState().subscribe(res => {
      if (res) {
        this.login = true;
        this.user.displayName = res.displayName;
        this.user.email = res.email;
        if (res.photoURL) {
          this.user.photoURL = res.photoURL;
        }
        this.interaccionService.dismissLoading();
        if (this.user.displayName) {
          this.interaccionService.showToast('Sesión iniciado como: ' + this.user.displayName);
        } else {
          this.interaccionService.showToast('Sesión iniciado como: ' + this.user.email);
        }
        if (this.loginOnlyProviders) {
          this.popoverController.dismiss(true);
        }
      } else {
        this.login = false;
      }
      this.cargando = false;
      // console.log('pop login -> authState: ', res);
    });

    this.isIOS = this.platform.is('ios');

  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if (this.suscriberInfo) {
      this.suscriberInfo.unsubscribe();
    }
    if (this.suscriberAuth) {
      this.suscriberAuth.unsubscribe();
    }
  }

  suscribirse() { }

  logout() {
    this.autenticationService.logout().then(() => {
      this.interaccionService.showToast('Sesión cerrada');
      window.location.reload();
    });
  }

  loginWithGoogle() {
    this.autenticationService.loginWithGoogle();
  }

  newLoginWithGoogleDavid() {
    this.autenticationService.newLoginGoogleDavid();
  }

  newLoginDavidGooglePlus() {
    if (this.platform.is('android')) {
      // this.loginGoogleAndroidDavid();
    }
    else {
      // this.loginGoogleWebDavid();
    }
  }


  async loginGoogleAndroidDavid() {
    // const res = await this.googlePlus.login({
    //   'webClientId': '622369298288-cn9kjpc5rvvl843b3tn1se35foq8dput.apps.googleusercontent.com',
    //   'offline': true
    // });
    // const resConfirmed = await this.afAuth.signInWithCredential(firebase.default.auth.GoogleAuthProvider.credential(res.idToken));
    // const user = resConfirmed.user;
    // this.picture = user.photoURL;
    // this.name = user.displayName;
    // this.email = user.email;
  }


  async loginGoogleWebDavid() {
    const res = await this.afAuth.signInWithPopup(new firebase.default.auth.GoogleAuthProvider());
    const user = res.user;
    console.log(user);
    this.picture = user.photoURL;
    this.name = user.displayName;
    this.email = user.email;
  }

  loginWithFacebook() {
    this.autenticationService.loginWithFacebook();
  }

  async loginWithEmail() {
    const datos: UserInfo = {
      email: this.email,
      nombre: this.nombre,
      suscrito: false,
      id: '',
      password: this.password,
      rol: 'usuario'
    };
    if (!datos.password) {
      datos.password = this.email.split('@')[0];
      if (datos.password.length <= 5) {
        datos.password = datos.password + '12345';
      }
    }
    let exist = false;
    if (!this.emailIsNew) {
      await this.interaccionService.presentLoading('validando...');
      exist = await this.emailExist(datos);
      if (!exist) {
        this.emailIsNew = true;
      }
      this.interaccionService.dismissLoading();
    } else {
      if (this.repassword != this.password) {
        this.interaccionService.showToast('Las contraseñas no coinciden');
        return;
      }
      await this.interaccionService.presentLoading('iniciando...');
      const res = await this.autenticationService.loginForce(datos);
      if (!res) {
        this.mensaje = 'El Correo: ' + datos.email + ' está en uso, intenta ingresar con:'
        this.loginOnlyProviders = true;
      } else {
        await this.interaccionService.dismissLoading();
        await this.popoverController.dismiss(datos);
      }
      this.interaccionService.dismissLoading();
    }
  }

  emailExist(datos: UserInfo): Promise<boolean> {
    return new Promise(async (resolve) => {
      const res = await this.autenticationService.login(datos.email, datos.password).catch(err => {
        console.log('error -> ', err);
        if (err.code === 'auth/wrong-password') {
          this.mensaje = 'El Correo: ' + datos.email + ' está en uso, intenta ingresar con:'
          this.loginOnlyProviders = true;
        }
        resolve(false);
        return;
      });
      if (res) {
        resolve(true);
        return;
      }

    });
  }


  loginWithApple() {
    this.autenticationService.signInWithApple();
  }

  async loginWithEmailAndPassword() {
    this.interaccionService.presentLoading('Ingresando...')
    const resLogin = await this.autenticationService.login(this.email, this.password).catch(err => {
      this.interaccionService.showToast('Error, asegúrate de tener conexión e ingresar las credenciales correctamente');
      this.interaccionService.dismissLoading();
      return;
    })
    this.interaccionService.dismissLoading();
    if (resLogin) {
      this.interaccionService.showToast('Has iniciado sesión con exito');
      await this.popoverController.dismiss();
    } else {
      this.interaccionService.showToast('Error, asegúrate de tener conexión e ingresar bien las credenciales');
    }
  }

  goToPerfil() {
    this.popoverController.dismiss()
    this.router.navigate(['/user/miperfil']);
  }

  dismiss() {
    this.popoverController.dismiss()
  }

  async solicitarResetPassword() {
    await this.interaccionService.presentLoading('Solicitando...');
    await this.autenticationService.resetPassword(this.email);
    this.interaccionService.dismissLoading();
    await this.interaccionService.preguntaAlert('Aviso', 'Te hemos enviando un correo para reestablecer tu contraseña, revísalo');
    this.resetPassword = false;
  }

  test() {

  }

}



